import { forwardRef, RefObject, useEffect, useRef } from 'react';

import { TableToggleCommonProps } from 'react-table';

const IndeterminateCheckbox = forwardRef<
  HTMLInputElement,
  TableToggleCommonProps
>(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef<HTMLInputElement>(null);
  const resolvedRef = (ref || defaultRef) as RefObject<HTMLInputElement>;

  useEffect(() => {
    if (resolvedRef.current)
      resolvedRef.current.indeterminate = Boolean(indeterminate);
  }, [resolvedRef, indeterminate]);

  return <input type="checkbox" ref={resolvedRef} {...rest} />;
});

export default IndeterminateCheckbox;
