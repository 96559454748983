import { atom, selector } from 'recoil';

import { getMyInfo } from 'src/api';
import { User } from 'src/types/client/user';
import { ClientErrorCode, ClientError } from 'src/utils/clientError';
import { getModalityOptions } from 'src/utils/user';

export const myInfoState = atom<User>({
  key: 'myInfoState',
  default: selector({
    key: 'user',
    get: async () => {
      const myInfo = await getMyInfo();

      if (!myInfo.isAdmin) {
        throw new ClientError({
          code: ClientErrorCode.UNAUTHORIZED,
          message: 'Only administrator is available to login!',
        });
      }

      return myInfo;
    },
  }),
});

export const myModalityOptionState = selector({
  key: 'myModalityOptionState',
  get: ({ get }) => {
    const user = get(myInfoState);
    return getModalityOptions(user);
  },
});
