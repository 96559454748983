export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_SHOW_PAGE_COUNT = 5;

export const PROJECTS_PAGE_TITLE = 'Project List';
export const ASSET_TEMPLATES_PAGE_TITLE = 'Asset Template List';
export const MANAGE_ASSET_TEMPLATE_TITLE = 'Manage Asset Template';
export const COPY_COUNTS_OF_CROSS_JOB = {
  MIN: 2,
  MAX: 30,
};

export const DATE_FORMAT = 'YYYY/MM/DD';
