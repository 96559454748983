import DialogContent from '@mui/material/DialogContent';

import { User } from 'src/types/client/user';

import CommonDialog, { CommonDialogProps } from './CommonDialog';

interface MyAccountDialogProps extends CommonDialogProps {
  user: User;
}

export default function MyAccountDialog({
  open,
  onClose,
  user,
}: MyAccountDialogProps): JSX.Element {
  return (
    <CommonDialog onClose={onClose} title="My Account" open={open}>
      <DialogContent>
        <ul style={{ paddingLeft: '1rem' }}>
          <li>Username: {user.username}</li>
          <li>Name: {user.name}</li>
        </ul>
      </DialogContent>
    </CommonDialog>
  );
}
