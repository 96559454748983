import { ResponsiveBar } from '@nivo/bar';
import dayjs from 'dayjs';

import { DailyStatDataProps } from 'src/types/client/stats';

interface DailyAnnotationProgressProps {
  data: DailyStatDataProps[];
}

export default function StatsDailyProgress({
  data,
}: DailyAnnotationProgressProps): JSX.Element {
  return (
    <ResponsiveBar
      data={data}
      keys={['count']}
      indexBy="date"
      margin={{ top: 20, right: 20, bottom: 40, left: 50 }}
      padding={0.6}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={{ scheme: 'category10' }}
      theme={{ textColor: '#fff' }}
      defs={[
        {
          id: 'dots',
          type: 'patternDots',
          background: 'inherit',
          color: '#38bcb2',
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: 'lines',
          type: 'patternLines',
          background: 'inherit',
          color: '#eed312',
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      enableGridX={true}
      enableGridY={true}
      borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Date',
        legendPosition: 'middle',
        legendOffset: 32,
        format: (date: string) => dayjs(date).format('MM/DD'),
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Total count',
        legendPosition: 'middle',
        legendOffset: -40,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      tooltip={({ id, value, color, data }) => (
        <div
          style={{
            padding: 5,
            color,
            background: '#222222',
            borderRadius: 4,
          }}
        >
          <span>Date: {data.date}</span>
          <br />
          <strong>Total annotation: {value}</strong>
        </div>
      )}
    />
  );
}
