import { chromeDark, InspectorThemeDefinition } from 'react-inspector';
import { useRecoilValue } from 'recoil';

import ExpandableObjectInspector from 'src/components/ExpandableObjectInspector';
import FormBox from 'src/components/FormBox';
import { newProjectState } from 'src/states/newProject';

const InspectorOpt: Partial<InspectorThemeDefinition> = {
  BASE_FONT_SIZE: '1rem',
  TREENODE_FONT_SIZE: '1rem',
  TREENODE_PADDING_LEFT: '1rem',
  BASE_BACKGROUND_COLOR: 'transparent',
};

const Overview = (): JSX.Element => {
  const project = useRecoilValue(newProjectState.current);

  return (
    <FormBox>
      <ExpandableObjectInspector
        theme={{ ...chromeDark, ...InspectorOpt }}
        data={project}
        sortObjectKeys={true}
      />
    </FormBox>
  );
};

export default Overview;
