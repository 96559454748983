import { SectionTitle } from 'src/components';
import { Flexbox } from 'src/components/Alignments';

export default function Main(): JSX.Element {
  return (
    <Flexbox>
      <SectionTitle>Welcome to CTL Office</SectionTitle>
    </Flexbox>
  );
}
