import { useState, useEffect, useMemo, SyntheticEvent } from 'react';

import { useRecoilValue } from 'recoil';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { updateReviewers } from 'src/api/projects';
import MultiDropdownMenu from 'src/components/Input/MultiDropDownMenu';
import useAlertSnackbar from 'src/hooks/useAlertSnackbar';
import useCheckedParams from 'src/hooks/useCheckedParams';
import { userState } from 'src/states/user';
import { Modality } from 'src/types/api/data/image';
import { SelectOption } from 'src/types/client/ui';
import { Reviewer } from 'src/types/client/user';

import CommonDialog, { CommonDialogProps } from './CommonDialog';

const DropdownContent = styled('div')`
  margin: 16px 0;
`;

interface Props extends CommonDialogProps {
  projectId: string;
  reviewer: Reviewer;
  onUpdate: () => void;
}

export default function UpdateAnnotatorDialog({
  open,
  onClose,
  onUpdate,
  projectId,
  reviewer,
}: Props): JSX.Element {
  const { openAlertSnackbar } = useAlertSnackbar();
  const { modalityLabel } = useCheckedParams<{
    modalityLabel: Modality;
  }>(['modalityLabel']);

  const optionList = useRecoilValue(
    userState.annotatorOptionList({
      projectId,
      modality: modalityLabel,
    })
  );
  const options = useMemo(
    () => optionList.filter(opt => opt.value !== reviewer.id),
    [optionList, reviewer.id]
  );
  const [selectedCandidates, setSelectedCandidates] = useState<SelectOption[]>(
    []
  );

  useEffect(() => {
    const oldAssociates = reviewer.associates.map(assoc => {
      const opt = options.find(opt => opt.value === assoc.id);
      return { value: assoc.id, label: opt?.label || 'loading...' };
    });
    setSelectedCandidates(oldAssociates);
  }, [options, reviewer.associates]);

  const handleSelectAnnotator = (
    event: SyntheticEvent,
    value: SelectOption[] | null
  ) => {
    setSelectedCandidates(value || []);
  };

  const handleClickAddAnnotators = async () => {
    try {
      await updateReviewers(projectId, modalityLabel, [
        {
          id: reviewer.id,
          associates: selectedCandidates.map(user => user.value),
        },
      ]);
      openAlertSnackbar({
        severity: 'success',
        description: 'Successfully assigned new annotators',
      });
    } catch {
      openAlertSnackbar({
        severity: 'error',
        description: 'Failed to assign new annotators',
      });
    }
    onUpdate();
  };

  return (
    <CommonDialog
      onClose={onClose}
      title={`Update Annotators of ${reviewer.username}`}
      open={open}
      fullWidth
    >
      <DialogContent>
        <DropdownContent>
          <Typography paragraph={true} variant="subtitle1">
            Update Annotators
          </Typography>
          <MultiDropdownMenu
            label="Select users"
            options={options}
            onChange={handleSelectAnnotator}
            value={selectedCandidates}
          />
        </DropdownContent>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleClickAddAnnotators}
          disabled={options.length === 0}
        >
          Update
        </Button>
      </DialogActions>
    </CommonDialog>
  );
}
