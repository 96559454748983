import { JobType } from 'src/types/api/data/job';
import { SelectOption } from 'src/types/client/ui';
import { Tuple } from 'src/utils/typeHelpers';

export const jobTypes: Tuple<SelectOption<JobType>, 4> = [
  { label: 'Annotation', value: 'annotation' },
  { label: 'Corroboration', value: 'corroboration' },
  { label: 'Cross', value: 'cross' },
  { label: 'Intra', value: 'intra', disabled: true },
];
