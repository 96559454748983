import { atom, selectorFamily, useSetRecoilState } from 'recoil';

import { getAssetTemplate, getAssetTemplates } from 'src/api/assetTemplates';
import { Modality } from 'src/types/api/data/image';

const refreshAssetTemplatesList = atom({
  key: 'assetTemplates/refreshAssetTemplatesList',
  default: 0,
});

export function useRefreshAssetTemplatesList(): () => void {
  const setRefreshState = useSetRecoilState(refreshAssetTemplatesList);
  return () => {
    setRefreshState(v => v + 1);
  };
}

const list = selectorFamily({
  key: 'assetTemplate/list',
  get:
    (modality: Modality) =>
    ({ get }) => {
      get(refreshAssetTemplatesList);
      return getAssetTemplates(modality);
    },
});

type AssetTemplateProps = {
  assetTemplateId: string;
  modality: Modality;
};

const current = selectorFamily({
  key: 'assetTemplate/current',
  get:
    ({ assetTemplateId, modality }: AssetTemplateProps) =>
    () =>
      getAssetTemplate(assetTemplateId, modality),
});

export const assetTemplateState = Object.freeze({
  list,
  current,
});
