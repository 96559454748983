import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { styled } from '@mui/material/styles';

import { AlertSnackbarState } from 'src/types/client/ui';

const StyledAlert = styled(Alert)`
  font-size: 1rem;
`;

interface Props extends AlertSnackbarState {
  onDismiss: () => void;
}

export default function AlertSnackbar({
  visibility,
  severity,
  description,
  onDismiss,
}: Props): JSX.Element {
  return (
    <Snackbar
      open={visibility}
      style={{
        zIndex: 10000,
      }}
      autoHideDuration={3000}
      onClose={onDismiss}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <StyledAlert severity={severity} onClose={onDismiss}>
        {description}
      </StyledAlert>
    </Snackbar>
  );
}
