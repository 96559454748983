import { Dispatch, SetStateAction, SyntheticEvent } from 'react';

import DropdownMenu from 'src/components/Input/DropdownMenu';
import { JobState, JOB_STATES } from 'src/types/api/data/job';
import { SelectOption } from 'src/types/client/ui';

export type JobStateForOption = 'all' | JobState;

export type JobStateOption = SelectOption<JobStateForOption>;

export const DEFAULT_JOB_STATE_OPTION: JobStateOption = {
  value: 'all',
  label: 'All job states',
};

const STATE_OPTIONS: JobStateOption[] = [
  DEFAULT_JOB_STATE_OPTION,
  ...JOB_STATES.map(type => ({
    value: type,
    label: type,
  })),
];

interface Props {
  state: JobStateOption;
  setState: Dispatch<SetStateAction<JobStateOption>>;
  onChange: () => void;
}

const JobStateFilter = ({ state, setState, onChange }: Props): JSX.Element => {
  const handleChange = (event: SyntheticEvent, opt: JobStateOption | null) => {
    if (opt) {
      setState(opt);
    } else {
      setState(DEFAULT_JOB_STATE_OPTION);
    }
    onChange();
  };

  return (
    <DropdownMenu<JobStateForOption>
      label="Select an job state"
      options={STATE_OPTIONS}
      onChange={handleChange}
      value={state}
    />
  );
};

export default JobStateFilter;
