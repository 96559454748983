// install (please make sure versions match peerDependencies)
import { ResponsiveLine } from '@nivo/line';

import { DailyStatsByUserProps } from 'src/types/client/stats';

interface DailyAnnotationProgressProps {
  data: DailyStatsByUserProps[];
}

export default function StatsDailyByUsers({
  data,
}: DailyAnnotationProgressProps): JSX.Element {
  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        reverse: false,
      }}
      theme={{ textColor: '#fff', crosshair: { line: { stroke: '#fff' } } }}
      yFormat=" >-.2f"
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Dates',
        legendOffset: 36,
        legendPosition: 'middle',
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Total Count',
        legendOffset: -40,
        legendPosition: 'middle',
      }}
      pointSize={6}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
      useMesh={true}
      enableSlices="x"
      sliceTooltip={({ slice }) => {
        return (
          <div
            style={{
              background: '#2e394f',
              padding: '9px 12px',
              borderRadius: 4,
              border: '1px solid #000',
              color: '#000',
            }}
          >
            {slice.points.map(point => (
              <div
                key={point.id}
                style={{
                  color: point.serieColor,
                  padding: '3px 0',
                }}
              >
                <>
                  <strong>{point.serieId}</strong> [{point.data.y}]
                </>
              </div>
            ))}
          </div>
        );
      }}
    />
  );
}
