import { CSSProperties, ReactNode } from 'react';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

interface Props {
  children: ReactNode;
  style?: CSSProperties;
}

const BlockQuote = ({ children, style }: Props): JSX.Element => (
  <Container style={style}>{children}</Container>
);
export default BlockQuote;

const Container = styled(Paper)(({ theme }) => ({
  margin: '1rem 0',
  padding: '0.75rem 1rem',
  color: 'rgba(255, 255, 255, 0.8)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  backgroundColor: theme.custom.bgLight,
  backgroundImage: 'none',
  borderRadius: 0,
}));
