import { ReactNode } from 'react';

import {
  createTheme,
  Theme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Theme {
    custom: {
      bg: string;
      bgDark: string;
      bgLight: string;
      disabled: string;
      button: string;
      buttonActive: string;
      text: string;
      textActive: string;
      primary: string;
      primaryActive: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    custom?: {
      bg?: string;
      bgDark?: string;
      bgLight?: string;
      disabled?: string;
      button?: string;
      buttonActive?: string;
      text?: string;
      textActive?: string;
      primary?: string;
      primaryActive?: string;
    };
  }
}

const baseTheme: Theme = createTheme({
  palette: {
    primary: {
      light: '#26bdde',
      main: '#00a4c8',
      dark: '#00a4c8',
      contrastText: '#fff',
    },
    mode: 'dark',
    background: {
      paper: '#2e394f',
    },
    error: {
      main: '#f05543',
    },
  },
  custom: {
    bg: '#232d41',
    bgDark: '#0f1b30',
    bgLight: '#2e394f',
    disabled: '#8694b1',
    button: '#42506b',
    buttonActive: '#546482',
    text: '#8694b1',
    textActive: '#fff',
    primary: '#00a4c8',
    primaryActive: '#26bdde',
  },
});

const primaryMuiTheme: Theme = createTheme(baseTheme, {
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 12,
          lineHeight: 1.5,
          border: `1px solid rgba(0,0,0,.25)`,
          backgroundColor: '#000',
          backgroundImage:
            'linear-gradient(to right,  var(--ctl-background-color-lighter) 0%, var(--ctl-background-color-lighter) 100%)',
        },
      },
      defaultProps: {
        disableInteractive: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          color: baseTheme.custom.textActive,
          backgroundColor: baseTheme.custom.button,
          '&:hover': {
            backgroundColor: baseTheme.custom.buttonActive,
          },
        },
        containedPrimary: {
          color: baseTheme.custom.textActive,
          backgroundColor: baseTheme.custom.primary,
          '&:hover': {
            backgroundColor: baseTheme.custom.primaryActive,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: baseTheme.palette.error.main,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundImage: 'none',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          overflowY: 'visible',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: baseTheme.custom.textActive,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          '& .MuiAlert-message ul': {
            padding: 0,
            paddingLeft: baseTheme.spacing(2),
            margin: 0,
          },
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontWeight: 'bolder',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          maxWidth: '640px',
          minWidth: '450px',
          padding: `${baseTheme.spacing(4)} ${baseTheme.spacing(3)}`,
          backgroundColor: baseTheme.custom.bg,
          backgroundImage: 'none',
          color: baseTheme.custom.text,
        },
      },
    },
  },
});

export function ThemeProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  return (
    <MuiThemeProvider theme={primaryMuiTheme}>{children}</MuiThemeProvider>
  );
}
