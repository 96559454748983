import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import queryString from 'query-string';

import { HOSTS } from 'src/configs/hosts';
import { ClientError } from 'src/utils/clientError';

import { ClientErrorCode } from './clientError';
import cookieHelper from './cookieHelper';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const paramsSerializer = (params: any): string => {
  return queryString.stringify(
    { ...params },
    {
      skipNull: true,
      skipEmptyString: true,
    }
  );
};

axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.params = {};

export const dataApi = axios.create({
  baseURL: HOSTS.CTL_DATA_SERVER,
  paramsSerializer,
});

export const authApi = axios.create({
  baseURL: HOSTS.CTL_AUTH_SERVER,
  paramsSerializer,
});

export const imageApi = axios.create({
  baseURL: HOSTS.CTL_IMAGE_SERVER,
  paramsSerializer,
});

const handleRequest = <T>(config: InternalAxiosRequestConfig<T>) => {
  const accessToken = cookieHelper.getAccessToken();
  if (config.headers && accessToken)
    config.headers.Authorization = `Bearer ${accessToken}`;
  return config;
};

const handleRequestError = (error: AxiosError) => {
  return Promise.reject(error);
};

const handleResponse = (response: AxiosResponse) => {
  if (response.status === 204)
    return {
      ...response,
      data: null, // Set the response data to null for 204 No Content, we need it for enqueue logic
    };
  return response;
};

const handleResponseError = (error: AxiosError) => {
  try {
    if (!error.response || !error.response.status) {
      throw new ClientError({
        code: ClientErrorCode.NETWORK_FAILURE,
        originalError: error,
      });
    }

    if (error.response.status === 401) {
      throw new ClientError({
        code: ClientErrorCode.UNAUTHENTICATED,
        originalError: error,
      });
    }

    if (error.response.status === 403) {
      throw new ClientError({
        code: ClientErrorCode.UNAUTHORIZED,
        originalError: error,
      });
    }

    throw new ClientError({
      code: ClientErrorCode.UNEXPECTED,
      originalError: error,
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

authApi.interceptors.request.use(handleRequest, handleRequestError);
authApi.interceptors.response.use(handleResponse, handleResponseError);
dataApi.interceptors.request.use(handleRequest, handleRequestError);
dataApi.interceptors.response.use(handleResponse, handleResponseError);
imageApi.interceptors.request.use(handleRequest, handleRequestError);
imageApi.interceptors.response.use(handleResponse, handleResponseError);
