import { SyntheticEvent, useEffect, useRef } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { SxProps, Theme } from '@mui/material/styles';

import { SelectOption } from 'src/types/client/ui';

export interface DropdownProps<T extends string> {
  label?: string;
  options: Readonly<SelectOption<T>[]>;
  onChange: (event: SyntheticEvent, value: SelectOption<T> | null) => void;
  value?: SelectOption<T> | null;
  disabled?: boolean;
  loading?: boolean;
  openOptionsOnMounted?: boolean;
  sx?: SxProps<Theme>;
}

const DropdownMenu = <T extends string>({
  label,
  disabled,
  loading,
  options,
  onChange,
  value,
  openOptionsOnMounted,
  sx,
}: DropdownProps<T>): JSX.Element => {
  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    const focusWhenMounted = () => {
      if (!openOptionsOnMounted || !ref.current) {
        return;
      }
      ref.current.focus();
    };

    focusWhenMounted();
  }, [openOptionsOnMounted]);

  return (
    <Autocomplete
      openOnFocus={!!openOptionsOnMounted}
      size="small"
      disabled={disabled}
      loading={loading}
      options={options}
      getOptionLabel={option => option.label}
      getOptionDisabled={option => option.disabled ?? false}
      value={value || null}
      onChange={onChange}
      renderInput={params => (
        <TextField inputRef={ref} {...params} label={label} />
      )}
      sx={{ minWidth: '12rem', ...sx }}
    />
  );
};

export default DropdownMenu;
