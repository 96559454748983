import { PropsWithChildren } from 'react';

import { styled } from '@mui/material/styles';

interface OwnProps {
  text: string;
  $isActivated?: boolean;
}

const ListItemContent = ({
  children,
  text,
  $isActivated,
}: PropsWithChildren<OwnProps>): JSX.Element => {
  return (
    <Content $isActivated={$isActivated}>
      <Name>{text}</Name>
      <ButtonWrap>{children}</ButtonWrap>
    </Content>
  );
};

export default ListItemContent;

export const Content = styled('div')<{ $isActivated?: boolean }>(
  ({ theme, $isActivated }) => `
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  background-color: ${$isActivated ? theme.custom.primary : 'transparent'};
`
);

const Name = styled('div')(
  ({ theme }) => `
  padding: ${theme.spacing(1.5)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
);

export const ButtonWrap = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`;
