import { styled } from '@mui/material/styles';

export const DndLayout = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
`;

export const DndLayoutContent = styled('div')(
  ({ theme }) => `
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: ${theme.spacing(0.5)};
  background-color: ${theme.custom.bgLight};
  max-height: 600px;
`
);

export const DndLayoutContentHeader = styled('div')(
  ({ theme }) => `
  display: flex;
  gap: ${theme.spacing(1)};
  padding: ${theme.spacing(3)};
`
);

export const DndLayoutContentBody = styled('div')<{ $gutterTop?: boolean }>(
  ({ theme, $gutterTop = false }) => `
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1)};
  overflow-y: auto;
  padding: ${theme.spacing(3)};
  padding-top: ${$gutterTop ? theme.spacing(3) : 0};
`
);
