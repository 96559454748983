import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import cookieHelper from 'src/utils/cookieHelper';

/**
 * THIS IS COMMENT IS TEMP AND WILL REMOVED AFTER REVIEW (BEFORE MERGE)
 * There was an issue on handling token expiration case, it was not handling expiration
 * when user stays on the same route and just refreshes, before it was only handling
 * when route changes with history hook
 *
 * TEST:
 * Just comment newly added condition and set expired token on the browser and see the result
 * but don't refresh until you set expired token on browser and you will see the error screen
 *
 * This is expired token sample:
 * eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2MzAzNzM2MDMsImlhdCI6MTYzMDQ2MDAwMywiX2lkIjoiTm9uZSIsInVzZXJuYW1lIjoiIiwibmFtZSI6IiIsImlzX2FkbWluIjpmYWxzZSwicGVybWl0dGVkRGF0YWJhc2VzIjpbXSwiZGVmYXVsdERhdGFiYXNlIjoiIn0.P5mUZjsSs7_C-6Js7_X_PQeweEcZRoI8FGXUKLbVdXU
 *
 */

const useTokenValidator = (): void => {
  const location = useLocation();

  const validateToken = () => {
    if (cookieHelper.isAccessTokenExpired()) {
      cookieHelper.removeAccessToken();
    }
  };

  // we need this when route changes or browser refreshes
  useEffect(() => {
    validateToken();
  }, [location.pathname]);
};

export default useTokenValidator;
