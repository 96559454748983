import { styled } from '@mui/material/styles';

const FormListItemBase = styled('div')<{
  $isDragging?: boolean;
  $isDraggable?: boolean;
  $isDisabled?: boolean;
  $isValid?: boolean;
}>(
  ({
    theme,
    $isDragging = false,
    $isDraggable = false,
    $isDisabled = false,
    $isValid = true,
  }) => `
  flex: 1;
  overflow: hidden;
  background-color: ${theme.custom.bgDark};
  border-radius: ${theme.spacing(0.5)};
  border-width: 1px;
  border-style: solid;
  border-color: ${$isValid ? theme.custom.bgDark : theme.palette.error.dark};
  color: ${theme.custom.textActive};
  transition: background-color 0.2s ease, opacity 0.2s ease, border-color 0.2s ease;
  cursor: ${$isDragging ? 'grabbing' : $isDraggable ? 'grab' : 'default'};
  opacity: ${$isDragging || $isDisabled ? 0.4 : 1};
`
);

export default FormListItemBase;
