import { useState } from 'react';

import { ClaimAssetValidationSchema } from '@lunit-io/ctl-api-interface';
import { useSetRecoilState } from 'recoil';

import { newProjectState } from 'src/states/newProject';
import { ASSET_VALIDATION_ITEM_TYPE } from 'src/types/client/asset';
import { INITIAL_ASSET_VALIDATION } from 'src/utils/assetValidationTemplates';

import AssetValidationItem from './AssetValidationItem';
import UpdateAssetValidationDialog from './UpdateAssetValidationDialog';

const TemplateList = (): JSX.Element => {
  const setClaim = useSetRecoilState(newProjectState.claim);

  const [updatingItem, setUpdatingItem] =
    useState<ClaimAssetValidationSchema>();

  const handleOpenDialog = () => {
    setUpdatingItem(INITIAL_ASSET_VALIDATION);
  };

  const handleCloseDialog = () => {
    setUpdatingItem(undefined);
  };

  const handleConfirm = (newItem: ClaimAssetValidationSchema) => {
    setClaim(prev => ({
      ...prev,
      assetValidations: [...(prev.assetValidations || []), newItem],
    }));
  };

  return (
    <>
      <AssetValidationItem
        type={ASSET_VALIDATION_ITEM_TYPE.TEMPLATE}
        item={INITIAL_ASSET_VALIDATION}
        onAdd={handleOpenDialog}
      />
      <UpdateAssetValidationDialog
        item={updatingItem}
        onClose={handleCloseDialog}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default TemplateList;
