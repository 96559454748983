import { ChangeEvent } from 'react';

import { useRecoilState } from 'recoil';

import Add from '@mui/icons-material/Add';
import Close from '@mui/icons-material/Close';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import FormBox from 'src/components/FormBox';
import AlertPanel from 'src/pages/NewProject/AlertPanel';
import { newProjectState } from 'src/states/newProject';

const FindingFields = (): JSX.Element => {
  const [{ findingFields }, setClaim] = useRecoilState(newProjectState.claim);

  const getHandleChange =
    (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
      setClaim(prev => {
        const newFindingFields = [...findingFields];
        newFindingFields[index] = event.target.value;
        return {
          ...prev,
          findingFields: newFindingFields,
        };
      });
    };

  const getHandleRemove = (targetIndex: number) => () => {
    setClaim(prev => ({
      ...prev,
      findingFields: prev.findingFields.filter(
        (item, index) => index !== targetIndex
      ),
    }));
  };

  const handleAdd = () => {
    setClaim(prev => ({
      ...prev,
      findingFields: [...prev.findingFields, ''],
    }));
  };

  return (
    <>
      <AlertPanel isValid={true}>
        Finding fields are optional. Please check the warning below for setting
        the finding fields.
      </AlertPanel>

      <AlertPanel severity="info">
        <AlertTitle>Be careful!</AlertTitle>
        Researchers use the items in findingFields to search for readings that
        could have some type of label, even if that label didn’t appear in a
        specific reading. Make sure to check that it is spelled correctly. Check
        with research to ensure accuracy.
      </AlertPanel>

      <FormBox>
        {findingFields.map((findingField, index) => (
          <Row key={index}>
            <FormControl fullWidth size="small">
              <TextField
                value={findingField}
                onChange={getHandleChange(index)}
                size="small"
              />
            </FormControl>
            <Tooltip title="Remove this item" placement="right">
              <IconButton
                aria-label="remove"
                onClick={getHandleRemove(index)}
                size="small"
              >
                <Close />
              </IconButton>
            </Tooltip>
          </Row>
        ))}
        <Button
          startIcon={<Add />}
          size="large"
          onClick={handleAdd}
          variant="contained"
        >
          Add new item
        </Button>
      </FormBox>
    </>
  );
};

export default FindingFields;

const Row = styled('div')(
  ({ theme }) => `
  display: flex;
  gap: ${theme.spacing(1)};
`
);
