import { useRecoilValue } from 'recoil';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { confirmProject } from 'src/api/projects';
import useAlertSnackbar from 'src/hooks/useAlertSnackbar';
import useCheckedParams from 'src/hooks/useCheckedParams';
import useConfirmModal from 'src/hooks/useConfirmModal';
import { projectState, useRefreshProject } from 'src/states/project';
import { Modality } from 'src/types/api/data/image';

const ConfirmProjectButton = (): JSX.Element => {
  const { modalityLabel, projectId } = useCheckedParams<{
    modalityLabel: Modality;
    projectId: string;
  }>(['modalityLabel', 'projectId']);

  const { getConfirmation } = useConfirmModal();
  const { openAlertSnackbar } = useAlertSnackbar();
  const refreshProject = useRefreshProject();

  const project = useRecoilValue(
    projectState.project({ projectId, modality: modalityLabel })
  );

  const onClickConfirmProjectButton = async () => {
    let skipIncompleteJobs = false;
    const isComplete =
      project.jobStats.annotation.completedStage ===
      project.jobStats.annotation.total;

    const confirmation = await getConfirmation({
      title: 'Project Confirmation',
      description: `WARNING: After confirm the project, all content in the project cannot be edited. 
        Are you sure to confirm the project ${project.name}?`,
      children: !isComplete && (
        <FormControlLabel
          control={
            <Checkbox
              onChange={e => {
                skipIncompleteJobs = e.target.checked;
              }}
            />
          }
          label="Skip incomplete jobs"
        />
      ),
    });

    if (confirmation === true) {
      const succeedConfirmProject = await confirmProject(
        projectId,
        modalityLabel,
        skipIncompleteJobs
      );
      if (succeedConfirmProject === true) {
        openAlertSnackbar({
          severity: 'success',
          description: 'Successfully confirm the project',
        });

        // Refresh the project status
        refreshProject();
      } else {
        openAlertSnackbar({
          severity: 'error',
          description: 'Failed to confirm the project',
        });
      }
    }
  };

  const disableProjectConfirmation =
    project.confirmed || // PROJECT_ALREADY_CONFIRMED
    project.jobStats.annotation.total === 0 || // EMPTY_PROJECT
    project.jobStats.annotation.completedStage === 0; // NO_READING_IN_PROJECT

  return (
    <Button
      color="secondary"
      variant="contained"
      onClick={onClickConfirmProjectButton}
      disabled={disableProjectConfirmation}
      style={{ width: 200 }}
    >
      Confirm project
    </Button>
  );
};

export default ConfirmProjectButton;
