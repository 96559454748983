import { useMemo } from 'react';

import { useRecoilState } from 'recoil';

import FormControl from '@mui/material/FormControl';

import FormBox from 'src/components/FormBox';
import MultiDropdownMenu, {
  MultiDropdownProps,
} from 'src/components/Input/MultiDropDownMenu';
import { newProjectState } from 'src/states/newProject';
import { SelectOption } from 'src/types/client/ui';

const OPTIONS: SelectOption<string>[] = [
  { value: 'Clinical Report', label: 'Clinical Report' },
];

const InvisibleComponents = (): JSX.Element => {
  const [claim, setClaim] = useRecoilState(newProjectState.claim);

  const invisibleComponents = useMemo<SelectOption<string>[]>(() => {
    return (claim.invisibleComponents || []).map(item => ({
      value: item,
      label: item,
    }));
  }, [claim.invisibleComponents]);

  const handleChange: MultiDropdownProps['onChange'] = (e, options) => {
    const invisibleComponents: string[] = (options || []).map(
      ({ value }) => value
    );

    setClaim(prev => ({
      ...prev,
      invisibleComponents,
    }));
  };

  return (
    <FormBox title="Invisible Components">
      <FormControl>
        <MultiDropdownMenu
          label="Select the components to not to display"
          options={OPTIONS}
          onChange={handleChange}
          value={invisibleComponents}
          testId="newProjectInput-invisibleComponents"
        />
      </FormControl>
    </FormBox>
  );
};

export default InvisibleComponents;
