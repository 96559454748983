import { atom, selectorFamily, useSetRecoilState } from 'recoil';

import { getReducedCases } from 'src/api';
import {
  getCase,
  getQueryBuilderOptions,
  ReducedCasesProps,
} from 'src/api/case';
import { Modality } from 'src/types/api/data/image';

const refresh = atom({
  key: 'case/refresh',
  default: 0,
});

export function useRefreshJobList(): () => void {
  const setRefreshState = useSetRecoilState(refresh);
  return () => {
    setRefreshState(v => v + 1);
  };
}

type getCaseProps = {
  caseId?: string;
  modality?: string;
};

const caseSchema = selectorFamily({
  key: 'case/case',
  get:
    ({ caseId, modality }: getCaseProps) =>
    async ({ get }) => {
      get(refresh); //subscribe refresh state
      if (caseId && modality) {
        return getCase({ caseId, modality });
      }
      return undefined;
    },
});

const reducedCaseList = selectorFamily({
  key: 'case/reducedCaseList',
  get: (props: ReducedCasesProps) => async () => {
    return getReducedCases(props);
  },
});

const caseIdList = selectorFamily({
  key: 'case/caseIdList',
  get: (props: ReducedCasesProps) => async () => {
    return getReducedCases({ ...props, fields: ['id'] });
  },
});

const queryBuilderOptions = selectorFamily({
  key: 'case/queryBuilderOptions',
  get: (modality?: string) => async () => {
    return getQueryBuilderOptions(modality || Modality.CXR);
  },
});

export const caseState = Object.freeze({
  caseSchema,
  reducedCaseList,
  queryBuilderOptions,
  caseIdList,
});
