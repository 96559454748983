export const getEllipsis = (
  string: string
): { string: string; hasShorten: boolean } => {
  const MAX_LENGTH = 40;
  if (string.length <= MAX_LENGTH) {
    return { string, hasShorten: false };
  }
  return {
    string: `${string.substr(0, MAX_LENGTH).trim()}...`,
    hasShorten: true,
  };
};

const startPosition = 2;
const defaultLength = 9;

export const getUniqueId = (length?: number): string =>
  (Math.random() + 1)
    .toString(36)
    .substring(startPosition, length ? length + startPosition : defaultLength);
