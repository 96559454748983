import {
  useMemo,
  useCallback,
  ChangeEvent,
  SyntheticEvent,
  useState,
} from 'react';

import { debounce } from 'lodash';
import { useRecoilValueLoadable, Loadable } from 'recoil';

import LoadingButton from '@mui/lab/LoadingButton';
import Checkbox from '@mui/material/Checkbox';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import { addCasesWithQuery } from 'src/api/projects';
import { CenterAlign } from 'src/components/Alignments';
import CaseSearchQueryForm from 'src/components/Form/CaseSearchQueryForm';
import DropdownMenu from 'src/components/Input/DropdownMenu';
import Spinner from 'src/components/Spinner';
import StyledPagination from 'src/components/StyledPagination';
import useAlertSnackbar from 'src/hooks/useAlertSnackbar';
import { caseState } from 'src/states/case';
import { PaginationConfig } from 'src/types/api/common';
import {
  CaseSearchQueryConfig,
  ReducedCaseReadSchema,
  ReducedCaseListSchema,
  UseCaseDataReturn,
} from 'src/types/api/data/case';
import { Modality } from 'src/types/api/data/image';
import { JobType } from 'src/types/api/data/job';
import { jobTypes } from 'src/types/client/job';
import { SelectOption } from 'src/types/client/ui';
import {
  COPY_COUNTS_OF_CROSS_JOB,
  DEFAULT_PAGE_SIZE,
} from 'src/utils/constants';

import CommonDialog, { CommonDialogProps } from './CommonDialog';

interface AddCaseDialogProps extends CommonDialogProps {
  projectId: string;
  modality: Modality;
  onUpdate: () => void;
}

const DEFAULT_CASES_DATA: ReducedCaseListSchema = {
  cases: [],
  countTotalPages: 1,
  countTotalObjects: 0,
  currentPage: 1,
  countPerPage: DEFAULT_PAGE_SIZE,
};

const useRecoilCaseDataLoadable = (
  searchConfig: CaseSearchQueryConfig,
  { page, pageSize }: PaginationConfig
): Loadable<ReducedCaseListSchema> => {
  return useRecoilValueLoadable(
    caseState.caseIdList({
      ...searchConfig,
      page,
      pageSize,
    })
  );
};

const useCaseData = (
  searchConfig: CaseSearchQueryConfig,
  paginationConfig: PaginationConfig
): UseCaseDataReturn => {
  const { state, contents } = useRecoilCaseDataLoadable(
    searchConfig,
    paginationConfig
  );

  const isLoading = state === 'loading';
  const casesData = state === 'hasValue' ? contents : DEFAULT_CASES_DATA;

  return { isLoading, ...casesData };
};

export default function AddCaseDialog({
  open,
  onClose,
  onUpdate,
  projectId,
  modality,
}: AddCaseDialogProps): JSX.Element {
  const { openAlertSnackbar } = useAlertSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAllChecked, setIsAllChecked] = useState<boolean>(false);

  // Pagination
  const [page, setPage] = useState<number>(1);
  const [searchConfig, setSearchConfig] = useState<CaseSearchQueryConfig>({
    modality,
    readingType: '',
  });
  const [jobType, setJobType] = useState<SelectOption<JobType>>(jobTypes[0]);
  const [copyCounts, setCopyCounts] = useState<string>('2');
  const isCrossJob = jobType.value === 'cross';

  const {
    isLoading: isSearching,
    cases,
    countTotalPages,
    countTotalObjects,
  } = useCaseData(searchConfig, { page });

  const { cases: allCases } = useCaseData(searchConfig, {
    pageSize:
      countTotalObjects && isAllChecked ? countTotalObjects : DEFAULT_PAGE_SIZE,
  });

  const formValidation = useMemo(() => {
    const isValidCopyCounts = (() => {
      if (!isCrossJob) {
        return true;
      }
      const number = Number(copyCounts);
      if (Number.isNaN(number)) {
        return false;
      }
      return (
        number >= COPY_COUNTS_OF_CROSS_JOB.MIN &&
        number <= COPY_COUNTS_OF_CROSS_JOB.MAX
      );
    })();

    const isValid = cases.length !== 0 && isValidCopyCounts;

    return {
      isValid,
      isValidCopyCounts,
    };
  }, [cases.length, copyCounts, isCrossJob]);

  const handleClickAllCheck = useCallback(() => {
    setIsAllChecked(prev => !prev);
  }, []);

  const handleCloseAddCaseDialog = () => {
    if (isLoading) return;
    onClose?.();
  };

  const handleChangePage = (event: ChangeEvent<unknown>, page: number) => {
    handleClickSearch(searchConfig);
    setPage(page);
  };

  const handleClickSearch = async (config: CaseSearchQueryConfig) => {
    setPage(1);
    setSearchConfig(config);
  };

  const handleClickAddCase = debounce(async () => {
    setIsLoading(true);
    const caseList = isAllChecked ? allCases : cases;
    try {
      await addCasesWithQuery({
        projectId: projectId,
        type: jobType.value,
        numOfCrossJobs: isCrossJob ? Number(copyCounts) : undefined,
        caseIds: caseList.map(c => c.id),
        modality: searchConfig.modality,
      });
      setIsAllChecked(false);
      onUpdate();
    } catch {
      openAlertSnackbar({
        severity: 'error',
        description: 'Failed to add cases.',
      });
    }
    setIsLoading(false);
  }, 500);

  const handleClickJobType = (
    event: SyntheticEvent,
    option: SelectOption<JobType> | null
  ) => {
    setJobType(option || jobTypes[0]);
  };

  const handleChangeCopyCounts = (event: ChangeEvent<HTMLInputElement>) => {
    setCopyCounts(event.target.value);
  };

  return (
    <CommonDialog
      open={open}
      onClose={handleCloseAddCaseDialog}
      title="Add Cases into the project"
      maxWidth="md"
    >
      <DialogContent>
        <CaseSearchQueryForm
          onSubmit={handleClickSearch}
          isSearching={isSearching}
          modality={modality}
        />

        <SearchResult style={{ marginTop: '1rem' }}>
          <CasesWrapper>
            <Cases>
              {isSearching && (
                <CenterAlign style={{ width: '100%', height: '100%' }}>
                  <Spinner size={60} />
                </CenterAlign>
              )}
              {!isSearching &&
                cases.length > 0 &&
                cases.map(
                  (caseSchema: ReducedCaseReadSchema, index: number) => (
                    <Case isFirst={index === 0} key={caseSchema.id}>
                      {caseSchema.id}
                    </Case>
                  )
                )}
              {!isSearching && cases.length === 0 && (
                <CenterAlign style={{ width: '100%', height: '100%' }}>
                  No cases
                </CenterAlign>
              )}
            </Cases>
          </CasesWrapper>
          <CenterAlign>
            <StyledPagination
              page={page}
              count={countTotalPages || 1}
              onChange={handleChangePage}
              size="small"
              shape="rounded"
            />
          </CenterAlign>
        </SearchResult>
      </DialogContent>
      <Divider />
      <DialogContent sx={{ display: 'flex', gap: 1.5 }}>
        <DropdownMenu
          options={jobTypes}
          label="Select a job type"
          onChange={handleClickJobType}
          value={jobType}
          disabled={isLoading}
          loading={isLoading}
          sx={{ flex: 3 }}
        />
        <Tooltip
          title={isCrossJob ? '' : 'Required only for the cross job'}
          followCursor={true}
        >
          <TextField
            size="small"
            sx={{ flex: 1 }}
            label="Number of copies"
            type="number"
            onFocus={event => event.target.select()}
            onChange={handleChangeCopyCounts}
            value={copyCounts}
            disabled={!isCrossJob}
            error={!formValidation.isValidCopyCounts}
            helperText={
              <>
                {formValidation.isValidCopyCounts ||
                  `It must be between ${COPY_COUNTS_OF_CROSS_JOB.MIN} and ${COPY_COUNTS_OF_CROSS_JOB.MAX}`}
              </>
            }
          />
        </Tooltip>
      </DialogContent>
      <DialogActions>
        <Tooltip title="Add all cases at once">
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleClickAllCheck}
                disabled={countTotalPages === 1}
              />
            }
            label="Select All Cases"
          />
        </Tooltip>
        <LoadingButton
          disabled={!formValidation.isValid || isLoading}
          loading={isLoading}
          loadingPosition="center"
          onClick={handleClickAddCase}
        >
          Add
        </LoadingButton>
      </DialogActions>
    </CommonDialog>
  );
}

const CasesWrapper = styled('div')`
  overflow: hidden;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.25);
`;

const Cases = styled('div')`
  height: 260px;
  overflow-y: auto;
`;

const Case = styled('div')<{ isFirst: boolean }>(
  ({ isFirst }) => `
  padding: 4px 8px;
  border-top-style: solid;
  border-top-color: rgba(255, 255, 255, 0.1);
  border-top-width: ${isFirst ? 0 : `1px`};
`
);

const SearchResult = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
