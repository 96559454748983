import { useCallback, useState } from 'react';

import update from 'immutability-helper';
import { useRecoilValue } from 'recoil';

import { newProjectState } from 'src/states/newProject';
import {
  ClientClaimAssetCreateSchema,
  HandleChangeClaimAssets,
} from 'src/types/client/asset';

import ConfiguredListItem from './ConfiguredListItem';
import InspectClaimAssetDrawer from './InspectClaimAssetDrawer';

interface Props {
  items: ClientClaimAssetCreateSchema[];
  setItems: HandleChangeClaimAssets;
}

const ConfiguredList = ({ items, setItems }: Props): JSX.Element => {
  const { invalidAssetIds } = useRecoilValue(newProjectState.isValidAssets);

  const [inspectingAsset, setInspectingAsset] =
    useState<ClientClaimAssetCreateSchema>();

  const handleMove = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setItems(prev => {
        const newItem = prev[dragIndex];

        if (!newItem) {
          return prev;
        }

        return update(prev, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, { ...newItem }],
          ],
        });
      });
    },
    [setItems]
  );

  const handleDelete = useCallback(
    (clickedItem: ClientClaimAssetCreateSchema) => {
      setItems(prev => {
        const isChild = !!clickedItem.parent;

        if (!isChild) {
          return prev.filter(({ id }) => id !== clickedItem.id);
        }

        return prev.map(asset => {
          if (asset.id === clickedItem.parent?.id) {
            return {
              ...asset,
              children: asset.children?.filter(
                child => child.id !== clickedItem.id
              ),
            };
          }
          return asset;
        });
      });
    },
    [setItems]
  );

  const handleSetInspectingAsset = useCallback(
    (clickedItem: ClientClaimAssetCreateSchema) => {
      setInspectingAsset(clickedItem);
    },
    []
  );

  const handleResetInspectingAsset = useCallback(() => {
    setInspectingAsset(undefined);
  }, []);

  return (
    <>
      {items.map(
        (parentItem, index) =>
          parentItem && (
            <ConfiguredListItem
              key={parentItem.id}
              index={index}
              data={parentItem}
              isValid={!invalidAssetIds.includes(parentItem.id)}
              onMove={handleMove}
              onInspect={handleSetInspectingAsset}
              onDelete={handleDelete}
            >
              {parentItem.children &&
                parentItem.children.length !== 0 &&
                parentItem.children.map((childrenItem, index) => (
                  <ConfiguredListItem
                    key={childrenItem.id}
                    index={index}
                    isValid={!invalidAssetIds.includes(childrenItem.id)}
                    data={childrenItem}
                    onInspect={handleSetInspectingAsset}
                    onDelete={handleDelete}
                  />
                ))}
            </ConfiguredListItem>
          )
      )}
      <InspectClaimAssetDrawer
        asset={inspectingAsset}
        onClose={handleResetInspectingAsset}
      />
    </>
  );
};

export default ConfiguredList;
