import { ResponsivePie, PieCustomLayerProps } from '@nivo/pie';

import { PieChartDataProps } from 'src/types/client/stats';

interface TotalProgressPieProps {
  data: PieChartDataProps[];
}

export default function TotalProgressPie({
  data,
}: TotalProgressPieProps): JSX.Element {
  return (
    <ResponsivePie
      data={data}
      margin={{ top: 20, bottom: 120 }}
      innerRadius={0.7}
      padAngle={1}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor="black"
      colors={{ scheme: 'category10' }}
      layers={['arcs', 'legends', CenteredMetric]}
      tooltip={({ datum: { id, value, color } }) => (
        <div
          style={{
            padding: 5,
            borderRadius: 4,
            color,
            background: '#222222',
          }}
        >
          <strong>
            {id}: {value}
          </strong>
        </div>
      )}
      theme={{
        tooltip: {
          container: {
            background: '#333',
          },
        },
      }}
      defs={pieChartDefs}
      fill={pieChartFill}
      legends={[
        {
          anchor: 'bottom',
          direction: 'row',
          justify: false,
          translateX: 0,
          translateY: 60,
          itemsSpacing: 0,
          itemWidth: 80,
          itemHeight: 10,
          itemTextColor: '#fff',
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          symbolSize: 12,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#ccc',
              },
            },
          ],
        },
      ]}
    />
  );
}

const pieChartDefs = [
  {
    id: 'dots',
    type: 'patternDots',
    background: 'inherit',
    color: 'rgba(255, 255, 255, 0.2)',
    size: 2,
    padding: 1,
  },
];

// TODO: investigate more this API
const pieChartFill = [
  {
    match: {
      id: 'completed',
    },
    id: 'dots',
  },
  {
    match: {
      id: 'assigned',
    },
    id: 'dots',
  },
  {
    match: {
      id: 'queued',
    },
    id: 'dots',
  },
];

const CenteredMetric = ({
  dataWithArc,
  centerX,
  centerY,
}: PieCustomLayerProps<PieChartDataProps>) => {
  let total = 0;
  dataWithArc.forEach(datum => {
    total += datum.value;
  });
  const completed = dataWithArc.find(datum => datum.label === 'completed') || {
    value: 0,
  };

  const showText = (): string => {
    const overall = total ? (completed.value / total) * 100 : 0;
    const decimals = overall > 1 ? 1 : 2;

    return `${overall.toFixed(decimals)} % complete`;
  };

  return (
    <g>
      <text
        x={centerX}
        y={centerY - 30}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: '18px',
          fontWeight: 600,
          fill: '#fff',
        }}
      >
        Total
      </text>
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: '30px',
          fontWeight: 600,
          fill: '#fff',
        }}
      >
        {total}
      </text>
      <text
        x={centerX}
        y={centerY + 30}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: '14px',
          fontWeight: 600,
          fill: '#2ca02c',
        }}
      >
        {showText()}
      </text>
    </g>
  );
};
