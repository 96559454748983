import { useDrop } from 'react-dnd';
import { useRecoilValue } from 'recoil';

import Add from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import DropPanel from 'src/pages/NewProject//DropPanel';
import AlertPanel from 'src/pages/NewProject/AlertPanel';
import {
  DndLayout,
  DndLayoutContent,
  DndLayoutContentBody,
  DndLayoutContentHeader,
} from 'src/pages/NewProject/DndLayout';
import { newProjectState } from 'src/states/newProject';
import {
  ASSET_VALIDATION_DROP_TYPE,
  ASSET_VALIDATION_ITEM_TYPE,
  AssetValidationDropResult,
} from 'src/types/client/asset';

import ConfiguredList from './ConfiguredList';
import TemplateList from './TemplateList';

const AssetValidations = (): JSX.Element => {
  const [{ isOver }, dropForAdding] = useDrop(() => ({
    accept: ASSET_VALIDATION_ITEM_TYPE.TEMPLATE,
    drop: (): AssetValidationDropResult => {
      return {
        type: ASSET_VALIDATION_DROP_TYPE.ADDING,
      };
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
  }));

  const isValidAssets = useRecoilValue(newProjectState.isValidAssets);
  const isValidAssetValidations = useRecoilValue(
    newProjectState.isValidAssetValidations
  );

  const isValid = isValidAssets.isValid && isValidAssetValidations.isValid;

  return (
    <>
      <AlertPanel isValid={isValid}>
        <ul>
          <li>The "Assets" step must be validated.</li>
          <li>If asset validations are added, they must be valid.</li>
        </ul>
      </AlertPanel>

      <Container>
        <DndLayout>
          <Typography variant="subtitle1" gutterBottom>
            Asset Validation Templates
          </Typography>
          <DndLayoutContent>
            <DndLayoutContentBody $gutterTop>
              <TemplateList />
            </DndLayoutContentBody>
          </DndLayoutContent>
        </DndLayout>
        <DndLayout>
          <Typography variant="subtitle1" gutterBottom>
            Configured Asset Validations
          </Typography>
          <DndLayoutContent>
            <DndLayoutContentHeader>
              <DropPanel ref={dropForAdding} $isHover={isOver}>
                <Add /> Add
              </DropPanel>
            </DndLayoutContentHeader>
            <DndLayoutContentBody>
              <ConfiguredList />
            </DndLayoutContentBody>
          </DndLayoutContent>
        </DndLayout>
      </Container>
    </>
  );
};

export default AssetValidations;

const Container = styled('div')`
  display: flex;
  gap: 1rem;
`;
