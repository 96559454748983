import { useMemo, useState } from 'react';

import { Dayjs } from 'dayjs';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { Flexbox } from 'src/components/Alignments';
import DateRangePicker from 'src/components/DateRangePicker';
import FilterPanel from 'src/components/FilterPanel';
import { dailyStatsByUserState, dailyStatsState } from 'src/states/dailyStats';
import { projectTotalStats } from 'src/states/stats';
import { Modality } from 'src/types/api/data/image';
import { DailyStatsProps } from 'src/types/client/stats';
import { isValidDate, oneMonthAgo, today } from 'src/utils/date';

import StatsDailyByUsers from './StatsDailyByUsers';
import StatsDailyProgress from './StatsDailyProgress';
import TotalProgressPie from './TotalProgressPie';

const StyledGrid = styled(Grid)(() => ({
  flexGrow: 1,
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
}));

const TotalProgress = styled('div')`
  width: 100%;
  height: 400px;
  border-radius: 5px;
`;

interface StatsTabPageProps {
  projectId: string;
  modality: Modality;
}

const DEFAULT_END_DATE = today;
const DEFAULT_START_DATE = oneMonthAgo;

export default function StatsTabPage({
  projectId,
  modality,
}: StatsTabPageProps): JSX.Element {
  const [startDate, setStartDate] = useState<Dayjs | null>(DEFAULT_START_DATE);
  const [endDate, setEndDate] = useState<Dayjs | null>(DEFAULT_END_DATE);
  const totalStats = useRecoilValue(projectTotalStats);

  const params = useMemo<DailyStatsProps>(
    () => ({
      projectId,
      modality,
      type: 'total',
      dateFrom:
        startDate && isValidDate(startDate) ? startDate.toISOString() : '',
      dateTo: endDate && isValidDate(endDate) ? endDate.toISOString() : '',
    }),
    [projectId, modality, startDate, endDate]
  );

  const dailyStats = useRecoilValueLoadable(dailyStatsState(params));

  const dailyStatsByUser = useRecoilValueLoadable(
    dailyStatsByUserState(params)
  );

  const handleStartDateChange = (date: Dayjs | null) => {
    if (date) setStartDate(date);
  };
  const handleEndDateChange = (date: Dayjs | null) => {
    if (date) setEndDate(date);
  };

  const resetStats = () => {
    setStartDate(oneMonthAgo);
    setEndDate(today);
  };

  return (
    <>
      {totalStats.total === 0 ? (
        <StyledGrid container spacing={2}>
          <Grid item xs={12}>
            <Flexbox $justify="center" $align="center" style={{ height: 400 }}>
              <Typography variant="h5">
                There is no stats available for the project
              </Typography>
            </Flexbox>
          </Grid>
        </StyledGrid>
      ) : (
        <StyledGrid container spacing={2}>
          <Grid item xs={4}>
            <StyledPaper>
              <TotalProgress>
                <Typography variant="h6">Overall Progress</Typography>
                <TotalProgressPie data={totalStats.stats} />
              </TotalProgress>
            </StyledPaper>
          </Grid>

          <Grid item xs={8}>
            <StyledPaper style={{ height: '100%' }}>
              <Typography variant="h6">Daily Progress</Typography>
              <FilterPanel
                style={{ justifyContent: 'center', marginTop: '2rem' }}
              >
                <DateRangePicker
                  dateFrom={startDate}
                  dateTo={endDate}
                  onStartDateChange={handleStartDateChange}
                  onEndDateChange={handleEndDateChange}
                />
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={resetStats}
                  disabled={dailyStats.state === 'loading'}
                >
                  Reset stats
                </Button>
              </FilterPanel>
              <div style={{ height: 300 }}>
                <StatsDailyProgress
                  data={
                    dailyStats.state === 'hasValue' ? dailyStats.contents : []
                  }
                />
              </div>
            </StyledPaper>
          </Grid>

          <Grid item xs={12}>
            <StyledPaper>
              <Typography variant="h6">Daily Progress by Users</Typography>
              <div style={{ width: '100%', height: 400 }}>
                <StatsDailyByUsers
                  data={
                    dailyStatsByUser.state === 'hasValue'
                      ? dailyStatsByUser.contents
                      : []
                  }
                />
              </div>
            </StyledPaper>
          </Grid>
        </StyledGrid>
      )}
    </>
  );
}
