import { styled } from '@mui/material/styles';

const DropPanel = styled('div')<{
  $isHover?: boolean;
  $color?: 'default' | 'error';
  $disabled?: boolean;
}>(({ theme, $isHover, $color = 'default', $disabled = false }) => {
  const color = (() => {
    switch ($color) {
      case 'error': {
        return $isHover ? theme.custom.textActive : theme.custom.text;
      }
      case 'default':
      default: {
        return $isHover ? theme.custom.textActive : theme.custom.text;
      }
    }
  })();

  const backgroundColor = (() => {
    switch ($color) {
      case 'error': {
        return $isHover ? theme.palette.error.main : theme.custom.button;
      }
      case 'default':
      default: {
        return $isHover ? theme.custom.primary : theme.custom.button;
      }
    }
  })();

  return `
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${theme.spacing(2)} ${theme.spacing(1)};
    border-radius: ${theme.spacing(0.5)};
    font-weight: bolder;
    transition: color 0.2s ease, background-color 0.2s ease, flex 0.2s ease, opacity 0.2s ease;
    color: ${color};
    background-color: ${backgroundColor};
    opacity: ${$disabled ? 0.5 : 1};
  `;
});

export default DropPanel;
