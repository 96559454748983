import { PropsWithChildren, useMemo } from 'react';

import Alert, { AlertProps } from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

interface Props {
  isValid?: boolean;
  severity?: AlertProps['severity'];
}

const AlertPanel = ({
  isValid,
  severity,
  children,
}: PropsWithChildren<Props>): JSX.Element => {
  const parsedSeverity = useMemo(() => {
    if (isValid !== undefined) {
      return isValid ? 'success' : 'error';
    }
    return severity ?? 'info';
  }, [isValid, severity]);

  return (
    <Alert
      severity={parsedSeverity}
      variant="filled"
      data-test-id="newProject-validationAlert"
    >
      {isValid !== undefined && (
        <AlertTitle>
          {isValid ? 'This step is valid.' : 'This step is invalid.'}
        </AlertTitle>
      )}
      {children}
    </Alert>
  );
};

export default AlertPanel;
