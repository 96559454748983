import { useState, useEffect } from 'react';

import {
  installWADOImageLoader,
  installWebImageLoader,
} from '@lunit/insight-viewer';

import useScript from './useScript';

const useImageLoaderScripts = (): void => {
  const [hasImageLoaderLoaded, setHasImageLoaderLoaded] =
    useState<boolean>(false);
  const cornerstoneStatus = useScript('/cornerstone.js');
  const cornerstoneWebImageLoaderStatus = useScript(
    '/cornerstoneWebImageLoader.js'
  );
  const cornerstoneWADOImageLoaderStatus = useScript(
    '/cornerstoneWADOImageLoader.js'
  );
  const dicomParserStatus = useScript('/dicomParser.js');

  useEffect(() => {
    if (hasImageLoaderLoaded) {
      return;
    }
    if (
      cornerstoneStatus === 'ready' &&
      cornerstoneWebImageLoaderStatus === 'ready' &&
      dicomParserStatus === 'ready' &&
      cornerstoneWADOImageLoaderStatus === 'ready'
    ) {
      installWADOImageLoader();
      installWebImageLoader();
      setHasImageLoaderLoaded(true);
    }
  }, [
    hasImageLoaderLoaded,
    cornerstoneStatus,
    cornerstoneWebImageLoaderStatus,
    dicomParserStatus,
    cornerstoneWADOImageLoaderStatus,
  ]);
};

export default useImageLoaderScripts;
