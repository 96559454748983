import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
} from 'react';

import {
  ClaimCategorySchema,
  SelectorAttributes,
} from '@lunit-io/ctl-api-interface';

import Add from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

import FormBox from 'src/components/FormBox';
import {
  AssetTemplateFormSelectorSchema,
  DEFAULT_ASSET_CATEGORY,
} from 'src/types/client/asset';
import AssetValidationUtils from 'src/utils/assetValidation';

import AssetCategoryRow from './AssetCategoryRow';

interface Props {
  asset: AssetTemplateFormSelectorSchema;
  setAsset: Dispatch<SetStateAction<AssetTemplateFormSelectorSchema>>;
  readonly: boolean;
}

const { getCategories } = AssetValidationUtils;

const SelectorAttributesBuilder = ({
  asset,
  setAsset,
  readonly,
}: Props): JSX.Element => {
  const handleSelectorAttributesChange =
    (key: keyof SelectorAttributes) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      setAsset(prev => {
        if (prev.form !== 'selector') {
          return prev;
        }
        return {
          ...prev,
          formAttributes: {
            ...prev.formAttributes,
            [key]: event.target.checked,
          },
        };
      });
    };

  const handleAddCategory = () => {
    setAsset(prev => {
      if (prev.form !== 'selector') {
        return prev;
      }
      return {
        ...prev,
        formAttributes: {
          ...prev.formAttributes,
          categories: [...getCategories(prev), DEFAULT_ASSET_CATEGORY],
        },
      };
    });
  };

  const getRemoveCategory = useCallback(
    (index: number) => () => {
      setAsset(prev => {
        if (prev.form !== 'selector') {
          return prev;
        }

        const prevCategories = getCategories(prev);
        const categories = [
          ...prevCategories.slice(0, index),
          ...prevCategories.slice(index + 1),
        ];

        return {
          ...prev,
          formAttributes: {
            ...prev.formAttributes,
            categories,
          },
        };
      });
    },
    [setAsset]
  );

  const getUpdateCategories = useCallback(
    (index: number) => (key: keyof ClaimCategorySchema, newValue: string) => {
      setAsset(prev => {
        if (prev.form !== 'selector') {
          return prev;
        }

        const prevCategories = getCategories(prev);

        return {
          ...prev,
          formAttributes: {
            ...prev.formAttributes,
            categories: prevCategories.map((category, i) => {
              if (i !== index) {
                return category;
              }
              return {
                ...category,
                [key]: newValue,
              };
            }),
          },
        };
      });
    },
    [setAsset]
  );

  const isCategoryDeletable = useMemo(
    () => getCategories(asset).length > 1,
    [asset]
  );

  return (
    <>
      <FormBox title="Form Attributes">
        <FormGroup
          sx={{
            flex: 1,
            gap: 2,
            flexDirection: 'row',
            margin: '-0.5rem 0',
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={!!asset.formAttributes?.allowMultiSelect}
                onChange={handleSelectorAttributesChange('allowMultiSelect')}
                disabled={readonly}
              />
            }
            label="Allow multi select"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={!!asset.formAttributes?.allowEmpty}
                onChange={handleSelectorAttributesChange('allowEmpty')}
                disabled={readonly}
              />
            }
            label="Allow empty"
          />
        </FormGroup>
      </FormBox>

      <FormBox title="Form Attributes &gt; Categories">
        {getCategories(asset).map((rowData, index) => (
          <AssetCategoryRow
            key={index}
            category={rowData}
            isDeletable={isCategoryDeletable}
            removeCategory={getRemoveCategory(index)}
            onChange={getUpdateCategories(index)}
            readonly={readonly}
          />
        ))}
        {!readonly && (
          <Button variant="outlined" onClick={handleAddCategory}>
            <Add />
          </Button>
        )}
      </FormBox>
    </>
  );
};

export default SelectorAttributesBuilder;
