import { PropsWithChildren } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

interface Props extends Pick<DialogProps, 'maxWidth' | 'fullWidth'> {
  open: boolean;
  title: string;
  onClose?: () => void;
}

export type CommonDialogProps = Omit<Props, 'title'>;

const CommonDialog = ({
  open,
  title,
  onClose,
  children,
  maxWidth,
  fullWidth,
}: PropsWithChildren<Props>): JSX.Element => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      transitionDuration={0}
    >
      <StyledDialogTitle>
        {title}
        {onClose ? (
          <IconButton aria-label="close" onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        ) : null}
      </StyledDialogTitle>
      {children}
    </Dialog>
  );
};

export default CommonDialog;

const StyledDialogTitle = styled(DialogTitle)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
