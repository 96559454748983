import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import { ConfirmModalState } from 'src/types/client/ui';

import CommonDialog from './CommonDialog';

interface Props extends ConfirmModalState {
  onConfirm: () => void;
  onCancel: () => void;
  confirmBtnTestId?: string;
}

const ConfirmModal = ({
  visibility,
  title,
  description,
  onConfirm,
  onCancel,
  confirmBtnTestId,
  children,
}: Props): JSX.Element => {
  return (
    <CommonDialog open={Boolean(visibility)} onClose={onCancel} title={title}>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{ whiteSpace: 'pre-line' }}
        >
          {description}
        </DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          color="error"
          onClick={onConfirm}
          autoFocus
          data-test-id={confirmBtnTestId}
        >
          Confirm
        </Button>
      </DialogActions>
    </CommonDialog>
  );
};

export default ConfirmModal;
