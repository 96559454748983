import dayjs from 'dayjs';
import { selectorFamily } from 'recoil';

import { getDailyJobStats } from 'src/api/stats';
import {
  ProjectDailyJobStats,
  DailyStatDataProps,
  DailyStatsByUserProps,
  DailyStatsProps,
  DailyStatsPerUser,
  UserDailyStat,
} from 'src/types/client/stats';
import { DATE_FORMAT } from 'src/utils/constants';

import { userState } from './user';

export const projectDailyStatsQuery = selectorFamily<
  ProjectDailyJobStats,
  DailyStatsProps
>({
  key: 'projectDailyStatsQuery',
  get: params => async () => {
    const dailyStats = await getDailyJobStats(params);

    return dailyStats;
  },
});

export const dailyStatsState = selectorFamily<
  DailyStatDataProps[],
  DailyStatsProps
>({
  key: 'dailyStatsState',
  get:
    params =>
    ({ get }) => {
      const projectDailyStats = get(projectDailyStatsQuery(params));
      const cloned = [...projectDailyStats.project];
      return cloned
        .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
        .map(row => ({
          date: dayjs(new Date(row.date)).format(DATE_FORMAT),
          count: row.count,
        }));
    },
});

// x is date of annotation, y is count of annotation in this day
// we need to use {x,y} pair as it is required format by charts
type AxisPoint = { x: string; y: number };
type ChartDailyStats = { id: string; data: AxisPoint[] };

const compareByTimestamp = (dateA: string, dateB: string) =>
  new Date(dateA).getTime() - new Date(dateB).getTime();

// native sort is in-place which means mutable
// so cloning helps not to change original array
function sort(data: UserDailyStat[]): UserDailyStat[] {
  const cloned = [...data];
  return cloned.sort((a, b) => compareByTimestamp(a.date, b.date));
}

const populateMissingPoints = (dailyUserStats: DailyStatsPerUser[]) => {
  const allDates = dailyUserStats.reduce(
    (acc: string[], curr) =>
      acc.concat(curr.data.map(perDayStat => perDayStat.date)),
    []
  );

  const uniqueDates = Array.from(new Set(allDates));

  const clonedUserStats: DailyStatsPerUser[] = JSON.parse(
    JSON.stringify(dailyUserStats)
  );

  for (let i = 0; i < clonedUserStats.length; i += 1) {
    for (let j = 0; j < uniqueDates.length; j += 1) {
      const rawDataStats = clonedUserStats[i]?.data;

      if (rawDataStats?.findIndex(raw => raw.date === uniqueDates[j]) === -1) {
        clonedUserStats[i]?.data.push({
          date: uniqueDates[j] as string,
          count: 0,
        });
      }
    }
  }

  return clonedUserStats;
};

const formatIntoChartData = (
  dailyStats: DailyStatsPerUser[]
): ChartDailyStats[] => {
  return dailyStats.map(dailyStat => ({
    id: dailyStat.id,
    data: dailyStat.data.map(stat => ({
      x: dayjs(new Date(stat.date)).format(DATE_FORMAT),
      y: stat.count,
    })),
  }));
};

export const dailyStatsByUserState = selectorFamily<
  DailyStatsByUserProps[],
  DailyStatsProps
>({
  key: 'dailyStatsByUserState',
  get:
    params =>
    ({ get }) => {
      const projectDailyStats = get(projectDailyStatsQuery(params));

      const { perUser } = projectDailyStats;
      const userList = get(userState.userList);

      const dailyStatsByUser = perUser.map(user => ({
        id: userList.find(u => u.id === user.id)?.username || 'noname',
        data: user.data,
      }));

      // filling each missing date with 0 count in order to show properly these points in UI
      const populatedDailyStats = populateMissingPoints(dailyStatsByUser);

      // sort original stats in order to show them in sorted fashion in charts
      const sortedDailyStats = populatedDailyStats.map(statsPoint => ({
        ...statsPoint,
        data: sort(statsPoint.data),
      }));

      // formatting dates into more concise form
      return formatIntoChartData(sortedDailyStats);
    },
});
