import { atom, selectorFamily, useSetRecoilState } from 'recoil';

import { getJobs } from 'src/api';
import { getJobsProps } from 'src/api/jobs';

const refresh = atom({
  key: 'job/refresh',
  default: 0,
});

export function useRefreshJobList(): () => void {
  const setRefreshState = useSetRecoilState(refresh);
  return () => {
    setRefreshState(v => v + 1);
  };
}

const jobList = selectorFamily({
  key: 'job/jobList',
  get:
    (props: getJobsProps) =>
    async ({ get }) => {
      get(refresh); //subscribe refresh state
      return getJobs(props);
    },
});

const jobUserOption = atom({
  key: 'job/jobUserOption',
  default: { value: 'all', label: 'All users' },
});

export const jobState = Object.freeze({
  jobList,
  jobUserOption,
});
