import { SyntheticEvent, useEffect, useRef } from 'react';

import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { SxProps, Theme } from '@mui/material/styles';

import { SelectOption } from 'src/types/client/ui';

export interface MultiDropdownProps {
  label?: string;
  options: SelectOption[];
  onChange: (
    event: SyntheticEvent,
    value: SelectOption[] | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<SelectOption>
  ) => void;
  value?: SelectOption[];
  disabled?: boolean;
  loading?: boolean;
  testId?: string;
  openOptionsOnMounted?: boolean;
  sx?: SxProps<Theme>;
}

const MultiDropdownMenu = ({
  options,
  onChange,
  label,
  value,
  disabled,
  loading,
  testId,
  openOptionsOnMounted,
  sx,
}: MultiDropdownProps): JSX.Element => {
  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    const focusWhenMounted = () => {
      if (!openOptionsOnMounted || !ref.current) {
        return;
      }
      ref.current.focus();
    };

    focusWhenMounted();
  }, [openOptionsOnMounted]);

  return (
    <Autocomplete
      openOnFocus
      multiple
      disableCloseOnSelect
      size="small"
      disabled={disabled}
      loading={loading}
      options={options}
      getOptionLabel={option => option.label}
      getOptionDisabled={option => option.disabled ?? false}
      value={value}
      onChange={onChange}
      renderInput={params => (
        <TextField
          inputRef={ref}
          {...params}
          label={label}
          data-test-id={testId}
        />
      )}
      sx={{ minWidth: '12rem', ...sx }}
    />
  );
};

export default MultiDropdownMenu;
