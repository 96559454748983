/* eslint-disable @typescript-eslint/no-explicit-any */

export enum ClientErrorCode {
  NETWORK_FAILURE = 'NETWORK_FAILURE',
  UNEXPECTED = 'UNEXPECTED',
  UNAUTHORIZED = 'UNAUTHORIZED',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  BAD_REQUEST = 'BAD_REQUEST',
  INVALID_IMAGE = 'INVALID_IMAGE',
}

const MESSAGES = {
  [ClientErrorCode.BAD_REQUEST]:
    'Unexpected error happen.\nPlease ask to administrator.',
  [ClientErrorCode.UNAUTHORIZED]: `You don't have permission on it.`,
  [ClientErrorCode.UNEXPECTED]: 'Unexpected error happen.\nPlease retry it.',
  [ClientErrorCode.UNAUTHENTICATED]:
    'Your authentication seems expired. Please try login again.',
  [ClientErrorCode.NETWORK_FAILURE]:
    'Fail to reach server. Please check your network connection.',
  [ClientErrorCode.INVALID_IMAGE]: 'Failed to load the image.',
} as const;

export const ClientErrorUtil = (() => {
  const getMessage = (code: ClientErrorCode): string =>
    MESSAGES[code] || MESSAGES[ClientErrorCode.UNEXPECTED];

  return Object.freeze({
    getMessage,
  });
})();

interface ConstructorParams {
  code: ClientErrorCode;
  originalError?: any;
  message?: string;
}

export class ClientError extends Error {
  public code: ClientErrorCode;
  public message: string;
  public originalError?: any;

  constructor({ code, message, originalError }: ConstructorParams) {
    super();
    this.code = code;
    this.message = message || ClientErrorUtil.getMessage(code);
    this.originalError = originalError;
    Object.setPrototypeOf(this, ClientError.prototype);
  }
}

export const throwUnexpectedWrongModalityError = (
  modality: string
): ClientError => {
  return new ClientError({
    code: ClientErrorCode.UNEXPECTED,
    message: `selected modality does not match with the database name : ${modality}`,
  });
};
