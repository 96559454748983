import { MouseEvent } from 'react';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';

import { SelectOption } from 'src/types/client/ui';

type Props<V extends string> = {
  options: readonly SelectOption[];
  value: SelectOption<V> | null;
  onChangeLabel: (label: SelectOption<V>) => void;
  fullWidth?: boolean;
};

export default function ToggleButtons<V extends string = string>({
  options,
  value,
  onChangeLabel,
  fullWidth,
}: Props<V>): JSX.Element {
  const handleChangeOption = (
    _: MouseEvent<HTMLElement>,
    option: SelectOption<V> | null
  ) => {
    if (option === null) return;
    onChangeLabel(option);
  };

  return (
    <ToggleButtonGroup
      fullWidth={fullWidth}
      size="small"
      value={value}
      exclusive
      onChange={handleChangeOption}
    >
      {options.map(option => (
        <StyledToggleButton
          key={option.value}
          value={option}
          disabled={option.disabled}
        >
          {option.label}
        </StyledToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}

const StyledToggleButton = styled(ToggleButton)`
  flex: 1;
  white-space: nowrap;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
`;
