import { styled } from '@mui/material/styles';

const PageWrapper = styled('div')`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const ContentsWrapper = styled('div')(
  ({ theme }) => `
  padding: ${theme.spacing(4)};
  width: auto;
  box-sizing: border-box;
`
);

type ContentsProp = {
  children: JSX.Element;
};

export default function Contents({ children }: ContentsProp): JSX.Element {
  return (
    <PageWrapper>
      <ContentsWrapper>{children}</ContentsWrapper>
    </PageWrapper>
  );
}
