import { useNavigate } from 'react-router-dom';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { createAssetTemplate } from 'src/api/assetTemplates';
import { Vertical } from 'src/components/Alignments';
import AssetTemplateForm from 'src/components/AssetTemplateForm';
import { SectionTitleWithBreadcrumbs } from 'src/components/Section';
import useAlertSnackbar from 'src/hooks/useAlertSnackbar';
import useCheckedParams from 'src/hooks/useCheckedParams';
import { useRefreshAssetTemplatesList } from 'src/states/assetTemplates';
import { Modality } from 'src/types/api/data/image';
import { AssetTemplateFormSchema } from 'src/types/client/asset';
import { PathNames } from 'src/types/client/url';
import { ASSET_TEMPLATES_PAGE_TITLE } from 'src/utils/constants';
import { UrlUtil } from 'src/utils/url';

export default function NewAssetTemplate(): JSX.Element {
  const navigate = useNavigate();
  const { openAlertSnackbar } = useAlertSnackbar();
  const refreshAssetTemplateList = useRefreshAssetTemplatesList();
  const { modalityLabel } = useCheckedParams<{
    modalityLabel: Modality;
  }>(['modalityLabel']);

  const goToAssetTemplatesPage = () => {
    navigate(
      `${UrlUtil.getUrl(PathNames.ASSET_TEMPLATES, {
        modalityLabel,
      })}`
    );
  };

  const handleConfirm = async (newAsset: AssetTemplateFormSchema) => {
    try {
      await createAssetTemplate(newAsset, modalityLabel);
      refreshAssetTemplateList();
      openAlertSnackbar({
        severity: 'success',
        description: `"${newAsset.name}" is created successfully.`,
      });
    } catch (error) {
      openAlertSnackbar({
        severity: 'error',
        description: 'Failed to create asset template.',
      });
      throw error;
    }
    goToAssetTemplatesPage();
  };

  return (
    <Vertical>
      <SectionTitleWithBreadcrumbs>
        <Link
          underline="hover"
          color="inherit"
          variant="h5"
          onClick={goToAssetTemplatesPage}
          sx={{ cursor: 'pointer' }}
        >
          {ASSET_TEMPLATES_PAGE_TITLE}
        </Link>
        <Typography color="text.primary" variant="h5">
          New {modalityLabel} Asset Template
        </Typography>
      </SectionTitleWithBreadcrumbs>
      <AssetTemplateForm onConfirm={handleConfirm} />
    </Vertical>
  );
}
