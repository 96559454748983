import { UserSchema } from 'src/types/api/auth';
import { ModalitySelectOption } from 'src/types/client/ui';
import { User } from 'src/types/client/user';

export const getMappedUser = (user: UserSchema): User => {
  return {
    id: user.id,
    username: user.username,
    name: user.name,
    isAdmin: user.is_admin,
    permittedModalities: user.permittedModalities,
    defaultModality: user.defaultModality,
  };
};

export const getModalityOptions = (user: User): ModalitySelectOption[] => {
  return user.permittedModalities.map(modality => ({
    label: modality,
    value: modality,
  }));
};
