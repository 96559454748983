import { ClaimAssetIdentifierSchema } from '@lunit-io/ctl-api-interface';

import ValidationButton from './ValidationButton';

interface Props {
  item: ClaimAssetIdentifierSchema[];
  onClick?: () => void;
}

const ValidationOneOf = ({ item, onClick }: Props): JSX.Element => {
  return (
    <div>
      One of{' '}
      <ValidationButton
        size="small"
        variant="outlined"
        disabled={!onClick}
        onClick={onClick}
      >
        {item.length === 0 ? 'assets' : item.map(item => item.name).join(', ')}
      </ValidationButton>{' '}
      need to be selected
    </div>
  );
};

export default ValidationOneOf;
