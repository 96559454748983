import { ChangeEvent } from 'react';

import { useRecoilState, useRecoilValue } from 'recoil';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';

import FormBox from 'src/components/FormBox';
import AlertPanel from 'src/pages/NewProject/AlertPanel';
import { newProjectState } from 'src/states/newProject';

import CorroborationForm from './CorroborationForm';
import InvisibleComponents from './InvisibleComponents';

const BasicInformation = (): JSX.Element => {
  const [name, setName] = useRecoilState(newProjectState.name);
  const [claim, setClaim] = useRecoilState(newProjectState.claim);
  const isCPC = claim.type === 'CurrentPriorComparison';
  const isValidBasicInformation = useRecoilValue(
    newProjectState.isValidBasicInformation
  );

  const handleChangeProjectName = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  const handleChangeProjectType = () => {
    setClaim(prev => ({
      ...prev,
      type: prev.type === 'Standard' ? 'CurrentPriorComparison' : 'Standard',
    }));
  };

  return (
    <>
      <AlertPanel isValid={isValidBasicInformation}>
        "Project Name" is a required field.
      </AlertPanel>

      <FormBox title="Project Name *">
        <FormControl>
          <TextField
            size="small"
            variant="outlined"
            placeholder="Enter the name of project"
            value={name}
            onChange={handleChangeProjectName}
            error={name.trim().length === 0}
            fullWidth
            data-test-id="newProjectInput-name"
          />
        </FormControl>
      </FormBox>
      <FormBox>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={isCPC}
                onChange={handleChangeProjectType}
                inputProps={{ 'aria-label': 'controlled' }}
                data-test-id="newProjectInput-toggleProjectType"
              />
            }
            label="Is this CPC (CurrentPriorComparison) project?"
          />
        </FormGroup>
      </FormBox>
      <InvisibleComponents />
      <CorroborationForm />
    </>
  );
};

export default BasicInformation;
