import { SyntheticEvent, useState } from 'react';

import { useRecoilValue } from 'recoil';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';

import { addAnnotator } from 'src/api';
import MultiDropdownMenu from 'src/components/Input/MultiDropDownMenu';
import useCheckedParams from 'src/hooks/useCheckedParams';
import { userState } from 'src/states/user';
import { Modality } from 'src/types/api/data/image';
import { SelectOption } from 'src/types/client/ui';

import CommonDialog, { CommonDialogProps } from './CommonDialog';

const DropdownContent = styled('div')`
  margin: 16px 0;
`;

interface Props extends CommonDialogProps {
  projectId: string;
  onUpdate: () => void;
}

export default function AddAnnotatorDialog({
  open,
  onClose,
  onUpdate,
  projectId,
}: Props): JSX.Element {
  const { modalityLabel } = useCheckedParams<{
    modalityLabel: Modality;
  }>(['modalityLabel']);

  const options = useRecoilValue(userState.userOptionList(modalityLabel));
  const [selectedCandidates, setSelectedCandidates] = useState<SelectOption[]>(
    []
  );

  const handleSelectAnnotator = (
    event: SyntheticEvent,
    value: SelectOption[] | null
  ) => {
    setSelectedCandidates(value || []);
  };

  const handleClickAddAnnotators = async () => {
    await addAnnotator(
      projectId,
      selectedCandidates.map(user => user.value),
      modalityLabel
    );
    onUpdate();
  };

  return (
    <CommonDialog
      onClose={onClose}
      title="Add New Annotators"
      open={open}
      fullWidth
    >
      <DialogContent>
        <DropdownContent>
          <MultiDropdownMenu
            label="Select users"
            options={options}
            onChange={handleSelectAnnotator}
          />
        </DropdownContent>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleClickAddAnnotators}>Add</Button>
      </DialogActions>
    </CommonDialog>
  );
}
