import { Context, createContext, ReactNode, useState, useRef } from 'react';

import ConfirmModal from 'src/components/Modal/ConfirmModal';
import { ConfirmModalState, TRANSITION_DURATION } from 'src/types/client/ui';

const DEFAULT: ConfirmModalState = {
  title: '',
  description: '',
  visibility: false,
};

interface Props {
  children: ReactNode;
}

type CallbackFunc = (confirmation: boolean) => void;

interface ContextState {
  open: (state: ConfirmModalState, callback: CallbackFunc) => void;
}

export const ConfirmModalContext: Context<ContextState> =
  createContext<ContextState>({
    open: () => {},
  });

export function ConfirmModalProvider({ children }: Props): JSX.Element {
  const callback = useRef<CallbackFunc>(() => {});
  const [state, setState] = useState<ConfirmModalState>(DEFAULT);

  const reset = () => {
    setState(DEFAULT);
    callback.current = () => {};
  };

  const open = (
    state: ConfirmModalState,
    callbackFunc: (confirmation: boolean) => void
  ) => {
    setState({
      ...DEFAULT,
      ...state,
      visibility: true,
    });
    callback.current = callbackFunc;
  };

  const close = () => {
    setState(prev => ({
      ...prev,
      visibility: false,
    }));
    setTimeout(reset, TRANSITION_DURATION);
  };

  const handleConfirm = () => {
    close();
    callback.current(true);
  };

  const handleCancel = () => {
    close();
    callback.current(false);
  };

  return (
    <ConfirmModalContext.Provider value={{ open }}>
      <ConfirmModal
        visibility={state.visibility}
        title={state.title}
        description={state.description}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        children={state.children}
        confirmBtnTestId={state.confirmBtnTestId}
      />
      {children}
    </ConfirmModalContext.Provider>
  );
}
