import { useMemo, ChangeEvent, useState, SyntheticEvent, useRef } from 'react';

import { debounce } from 'lodash';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';

import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { addCPCCasesWithCsv } from 'src/api/projects';
import { CenterAlign } from 'src/components/Alignments';
import DropdownMenu from 'src/components/Input/DropdownMenu';
import Spinner from 'src/components/Spinner';
import useAlertSnackbar from 'src/hooks/useAlertSnackbar';
import { Modality } from 'src/types/api/data/image';
import { JobType } from 'src/types/api/data/job';
import { jobTypes } from 'src/types/client/job';
import { SelectOption } from 'src/types/client/ui';
import { COPY_COUNTS_OF_CROSS_JOB } from 'src/utils/constants';

import CommonDialog, { CommonDialogProps } from './CommonDialog';

interface AddCPCCasesDialogProps extends CommonDialogProps {
  projectId: string;
  modality: Modality;
  onUpdate: () => void;
}

type CsvRow = { index: string; prior: string };

const AddCPCCasesDialog = ({
  open,
  onClose,
  onUpdate,
  projectId,
  modality,
}: AddCPCCasesDialogProps): JSX.Element => {
  const { openAlertSnackbar } = useAlertSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [cases, setCases] = useState<CsvRow[]>([]);

  const [jobType, setJobType] = useState<SelectOption<JobType>>(jobTypes[0]);
  const [copyCounts, setCopyCounts] = useState<string>('2');
  const isCrossJob = jobType.value === 'cross';
  const virtuoso = useRef<VirtuosoHandle>(null);

  const handleClickJobType = (
    event: SyntheticEvent,
    option: SelectOption<JobType> | null
  ) => {
    setJobType(option || jobTypes[0]);
  };

  const formValidation = useMemo(() => {
    const isValidCopyCounts = (() => {
      if (!isCrossJob) {
        return true;
      }
      const number = Number(copyCounts);
      if (Number.isNaN(number)) {
        return false;
      }
      return (
        number >= COPY_COUNTS_OF_CROSS_JOB.MIN &&
        number <= COPY_COUNTS_OF_CROSS_JOB.MAX
      );
    })();

    const isValid = cases.length !== 0 && isValidCopyCounts;

    return {
      isValid,
      isValidCopyCounts,
    };
  }, [cases.length, copyCounts, isCrossJob]);

  const handleCloseAddCaseDialog = () => {
    if (isLoading) return;
    setCases([]);
    onClose?.();
  };

  const handleChangeCopyCounts = (event: ChangeEvent<HTMLInputElement>) => {
    setCopyCounts(event.target.value);
  };

  const handleCsvFile = (e: ChangeEvent<HTMLInputElement>) => {
    const [csv] = e.target.files || [];
    if (csv) {
      const fileReader = new FileReader();
      fileReader.readAsText(csv);
      setIsProcessing(true);
      fileReader.onload = event => {
        const csvData = event.target?.result as string;
        if (csvData) {
          const [csvHeader, ...rowData] = csvData.split('\n');
          if (!csvHeader?.includes('prior_case')) {
            openAlertSnackbar({
              severity: 'error',
              description: 'Wrong format. Failed to upload file.',
            });
            setCases([]);
            setIsProcessing(false);
            return;
          }
          const parsedCases = rowData.map(row => {
            const [index, prior] = row.split(',');
            return { index: index?.trim() || '', prior: prior?.trim() || '' };
          });
          setCases(parsedCases);
          setIsProcessing(false);
        }
      };
    }
  };

  const handleClickAddCase = debounce(async () => {
    setIsLoading(true);
    try {
      await addCPCCasesWithCsv({
        projectId: projectId,
        type: jobType.value,
        numOfCrossJobs: isCrossJob ? Number(copyCounts) : undefined,
        caseIds: cases.map(c => c.index),
        pairCaseIds: cases.map(c => c.prior),
        modality,
      });
      onUpdate();
    } catch (err) {
      openAlertSnackbar({
        severity: 'error',
        description: `Failed to add cases. ${err}`,
      });
    }
    setIsLoading(false);
  }, 500);

  return (
    <CommonDialog
      fullWidth
      open={open}
      onClose={handleCloseAddCaseDialog}
      title="Add CPC cases into the project"
    >
      <Divider />
      <DialogContent>
        <Button color="primary" component="label" variant="contained" fullWidth>
          Upload CSV file
          <Box
            component="input"
            sx={{ display: 'none' }}
            type="file"
            accept=".csv"
            onChange={handleCsvFile}
          />
        </Button>

        <ParsedCSVBody style={{ marginTop: '1rem' }}>
          {!isProcessing && cases.length > 0 ? (
            <CasesWrapper>
              <Cases>
                <CenterAlign style={{ padding: 2 }}>
                  <Typography variant="subtitle1">Index Cases</Typography>
                </CenterAlign>
                <Virtuoso
                  ref={virtuoso}
                  style={{ height: '93%' }}
                  totalCount={cases.length}
                  data={cases}
                  itemContent={(idx, c) => (
                    <Case key={c.index} isFirst={idx === 0}>
                      {c.index}
                    </Case>
                  )}
                />
              </Cases>
              <Cases>
                <CenterAlign style={{ padding: 2 }}>
                  <Typography variant="subtitle1">Prior Cases</Typography>
                </CenterAlign>
                <Virtuoso
                  ref={virtuoso}
                  style={{ height: '93%' }}
                  totalCount={cases.length}
                  data={cases}
                  itemContent={(idx, c) => (
                    <Case key={c.prior} isFirst={idx === 0}>
                      {c.prior}
                    </Case>
                  )}
                />
              </Cases>
            </CasesWrapper>
          ) : (
            <EmptyContent>
              {isProcessing ? (
                <CenterAlign style={{ width: '100%', height: '100%' }}>
                  <Spinner size={40} />
                </CenterAlign>
              ) : (
                <CenterAlign style={{ width: '100%', height: '100%' }}>
                  No cases
                </CenterAlign>
              )}
            </EmptyContent>
          )}
        </ParsedCSVBody>
      </DialogContent>
      <Divider />
      <DialogContent sx={{ display: 'flex', gap: 1.5 }}>
        <DropdownMenu
          options={jobTypes}
          label="Select a job type"
          onChange={handleClickJobType}
          value={jobType}
          disabled={isProcessing}
          loading={isProcessing}
          sx={{ flex: 3 }}
        />
        <Tooltip
          title={isCrossJob ? '' : 'Required only for the cross job'}
          followCursor={true}
        >
          <TextField
            size="small"
            sx={{ flex: 1 }}
            label="Number of copies"
            type="number"
            onFocus={event => event.target.select()}
            onChange={handleChangeCopyCounts}
            value={copyCounts}
            disabled={!isCrossJob}
            error={!formValidation.isValidCopyCounts}
            helperText={
              <>
                formValidation.isValidCopyCounts || `It must be between $
                {COPY_COUNTS_OF_CROSS_JOB.MIN} and $
                {COPY_COUNTS_OF_CROSS_JOB.MAX}`
              </>
            }
          />
        </Tooltip>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleCloseAddCaseDialog}>Cancel</Button>
        <LoadingButton
          disabled={!formValidation.isValid || isLoading}
          loading={isLoading}
          loadingPosition="center"
          onClick={handleClickAddCase}
        >
          Add
        </LoadingButton>
      </DialogActions>
    </CommonDialog>
  );
};

const CasesWrapper = styled('div')`
  overflow: hidden;
  display: flex;
  gap: 5px;
`;

const Cases = styled('div')`
  width: 50%;
  height: 400px;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`;

const EmptyContent = styled('div')`
  height: 400px;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`;

const Case = styled('div')<{ isFirst: boolean }>(
  ({ isFirst }) => `
  padding: 4px 8px;
  border-top-style: solid;
  border-top-color: rgba(255, 255, 255, 0.1);
  border-top-width: ${isFirst ? 0 : `1px`};
`
);

const ParsedCSVBody = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export default AddCPCCasesDialog;
