import PaginationItem from '@mui/material/PaginationItem';
import { styled } from '@mui/material/styles';

export default styled(PaginationItem)(({ theme }) => ({
  color: theme.custom.textActive,
  '&.Mui-selected': {
    fontWeight: 'bold',
  },
  '&.MuiPaginationItem-page&.Mui-selected': {
    backgroundColor: `${theme.custom.primary}`,
  },
}));
