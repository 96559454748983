import { styled } from '@mui/material/styles';

import { ReducedCaseReadSchema } from 'src/types/api/data/case';

import { MemoizedQueryResultViewItem } from './QueryResultViewItem';

const QueryResultListViewPaper = styled('div')(({ theme }) => ({
  backgroundColor: theme.custom.bgDark,
}));
const EmptyResultItem = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
`;

const NoResult = styled('div')`
  font-size: 24pt;
`;

interface QueryResultListViewProps {
  items: ReducedCaseReadSchema[];
}

export default function QueryResultListView({
  items,
}: QueryResultListViewProps): JSX.Element {
  return (
    <QueryResultListViewPaper>
      {(items.length !== 0 &&
        items.map((item, idx) => (
          <MemoizedQueryResultViewItem key={idx} item={item} />
        ))) || (
        <EmptyResultItem>
          <NoResult>No Result</NoResult>
        </EmptyResultItem>
      )}
    </QueryResultListViewPaper>
  );
}
