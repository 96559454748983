import { useState, useMemo, ChangeEvent } from 'react';

import { useRecoilValueLoadable } from 'recoil';

import Add from '@mui/icons-material/Add';
import Button from '@mui/material/Button';

import { SectionTitle } from 'src/components';
import { Vertical } from 'src/components/Alignments';
import FilterPanel from 'src/components/FilterPanel';
import CreateAnnotatorDialog from 'src/components/Modal/CreateAnnotatorDialog';
import Table from 'src/components/Table';
import { useRefreshUserList, userState } from 'src/states/user';
import { User } from 'src/types/client/user';
import { DEFAULT_PAGE_SIZE } from 'src/utils/constants';

const UserDashboard = (): JSX.Element => {
  const userListLoadable = useRecoilValueLoadable(userState.userList);
  const refreshUserList = useRefreshUserList();
  const [isAnnotatorDialogOpen, setIsAnnotatorDialogOpen] = useState(false);

  const userList = useMemo(
    () =>
      userListLoadable.state === 'hasValue' ? userListLoadable.contents : [],
    [userListLoadable.contents, userListLoadable.state]
  );

  const isLoading = userListLoadable.state === 'loading';
  const isError = userListLoadable.state === 'hasError';

  // Pagination
  const [page, setPage] = useState<number>(1);
  const totalPages = Math.ceil(userList.length / DEFAULT_PAGE_SIZE);

  const closeCreateAnnotatorDialog = async () => {
    setIsAnnotatorDialogOpen(false);
    refreshUserList();
  };

  // Table elements
  const usersColumnHeader = useMemo(() => {
    return [
      {
        Header: 'User List',
        columns: [
          {
            Header: 'User Name',
            accessor: 'username',
            style: { width: '30%' },
          },
          {
            Header: 'Name',
            accessor: 'name',
            style: { width: '30%' },
          },
          {
            Header: 'Modality Permissions',
            accessor: 'permittedModalities',
            Cell: function renderPermittedModalitiesList(cell: {
              value: string[];
            }) {
              const modalityList = cell.value.map(modality => modality);
              return modalityList.sort().join(', ');
            },
            style: { width: '20%' },
          },
          {
            Header: 'Admin',
            id: 'isAdmin',
            accessor: ({ isAdmin }: User) => (isAdmin ? 'Yes' : 'No'),
          },
        ],
      },
    ];
  }, []);

  const handleChangePage = (event: ChangeEvent<unknown>, page: number) =>
    setPage(page);

  const paginatedUserList = useMemo<User[]>(
    () =>
      userList.slice((page - 1) * DEFAULT_PAGE_SIZE, page * DEFAULT_PAGE_SIZE),
    [page, userList]
  );

  // TODO: refresh after create user
  return (
    <>
      <Vertical>
        <SectionTitle>User List</SectionTitle>
        <FilterPanel>
          <Button
            color="primary"
            variant="contained"
            startIcon={<Add />}
            onClick={() => setIsAnnotatorDialogOpen(true)}
            style={{ minWidth: 255 }}
          >
            Create Annotator Account
          </Button>
        </FilterPanel>
        <Table<User>
          columns={usersColumnHeader}
          data={paginatedUserList}
          isLoading={isLoading}
          isError={isError}
          paginationProps={{
            page,
            count: totalPages,
            onChange: handleChangePage,
          }}
        />
      </Vertical>

      <CreateAnnotatorDialog
        onClose={closeCreateAnnotatorDialog}
        open={isAnnotatorDialogOpen}
      />
    </>
  );
};

export default UserDashboard;
