import { useState, useCallback } from 'react';

import { QueryBuilderMaterial } from '@react-querybuilder/material';
import {
  QueryBuilder,
  Field,
  RuleGroupType,
  formatQuery,
  Option,
} from 'react-querybuilder';

import 'react-querybuilder/dist/query-builder.css';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

type Props = {
  filterName: string;
  fields: Field[];
  operators: Option[];
  combinators: Option[];
  rules?: RuleGroupType[];
  onChange: (queryString: string) => void;
};

const QueryBuilderInput = ({
  filterName,
  fields,
  operators,
  combinators,
  onChange,
}: Props): JSX.Element => {
  const [query, setQuery] = useState<RuleGroupType>();

  const handleQueryChange = useCallback(
    (q: RuleGroupType) => {
      setQuery(q);
      onChange(formatQuery(q, 'mongodb'));
    },
    [onChange]
  );

  const queryDisabled = fields.length === 0;

  return (
    <Container>
      <Typography>{filterName} filter</Typography>
      <QueryBuilderMaterial>
        <QueryBuilder
          controlClassnames={{ queryBuilder: 'queryBuilder-branches' }}
          disabled={queryDisabled}
          fields={fields}
          query={query}
          operators={operators}
          combinators={combinators}
          onQueryChange={handleQueryChange}
        />
      </QueryBuilderMaterial>
    </Container>
  );
};

export default QueryBuilderInput;

const Container = styled('div', { name: 'QueryBuilderInput' })(() => ({
  width: '100%',
}));
