import { Dispatch, SetStateAction, SyntheticEvent } from 'react';

import DropdownMenu from 'src/components/Input/DropdownMenu';
import { JobType, JOB_TYPES } from 'src/types/api/data/job';
import { SelectOption } from 'src/types/client/ui';

export type JobTypeForOption = 'all' | JobType;

export type JobTypeOption = SelectOption<JobTypeForOption>;

export const DEFAULT_JOB_TYPE_OPTION: JobTypeOption = {
  value: 'all',
  label: 'All job types',
};

const JOB_TYPE_OPTIONS: JobTypeOption[] = [
  DEFAULT_JOB_TYPE_OPTION,
  ...JOB_TYPES.map(type => ({ value: type, label: type })),
];

interface Props {
  state: JobTypeOption;
  setState: Dispatch<SetStateAction<JobTypeOption>>;
  onChange: () => void;
}

const JobTypeFilter = ({ state, setState, onChange }: Props): JSX.Element => {
  const handleChange = (event: SyntheticEvent, opt: JobTypeOption | null) => {
    if (opt) {
      setState(opt);
    } else {
      setState(DEFAULT_JOB_TYPE_OPTION);
    }
    onChange();
  };

  return (
    <DropdownMenu<JobTypeForOption>
      label="Select a job type"
      options={JOB_TYPE_OPTIONS}
      onChange={handleChange}
      value={state}
    />
  );
};

export default JobTypeFilter;
