import { Dayjs } from 'dayjs';

import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { DATE_FORMAT } from 'src/utils/constants';
import { isValidDate, today } from 'src/utils/date';

import { Flexbox } from './Alignments';

interface Props {
  dateFrom?: Dayjs | null;
  dateTo?: Dayjs | null;
  onStartDateChange: (date: Dayjs | null) => void;
  onEndDateChange: (date: Dayjs | null) => void;
  format?: string;
  disabled?: boolean;
}

const DateRangePicker = ({
  dateFrom = null,
  dateTo = null,
  onStartDateChange,
  onEndDateChange,
  format = DATE_FORMAT,
  disabled = false,
}: Props): JSX.Element => {
  const handleStartDateChange = (date: Dayjs | null) => {
    if (date) {
      onStartDateChange(isValidDate(date) ? date : null);
    }
  };
  const handleEndDateChange = (date: Dayjs | null) => {
    if (date) {
      onEndDateChange(isValidDate(date) ? date : null);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePickerBox $justify="space-around" $align="center">
        <DesktopDatePicker
          label="Select start date"
          format={format}
          value={dateFrom}
          onChange={handleStartDateChange}
          disabled={disabled}
          slots={{
            textField: textFieldProps => (
              <TextField {...textFieldProps} size="small" />
            ),
          }}
        />
        <span style={{ margin: 5 }}>~</span>
        <DesktopDatePicker
          label="Select end date"
          format={format}
          value={dateTo}
          maxDate={today}
          onChange={handleEndDateChange}
          disabled={disabled}
          slots={{
            textField: textFieldProps => (
              <TextField {...textFieldProps} size="small" />
            ),
          }}
        />
      </DatePickerBox>
    </LocalizationProvider>
  );
};

const DatePickerBox = styled(Flexbox)``;

export default DateRangePicker;
