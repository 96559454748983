import { SyntheticEvent } from 'react';

import { useRecoilState, useResetRecoilState } from 'recoil';

import DropdownMenu from 'src/components/Input/DropdownMenu';
import { jobState } from 'src/states/job';
import { SelectOption } from 'src/types/client/ui';

interface Props {
  options: SelectOption[];
  onChange: () => void;
}

const AnnotatorFilter = ({ options, onChange }: Props): JSX.Element => {
  const resetUserOption = useResetRecoilState(jobState.jobUserOption);
  const [userOption, setUserOption] = useRecoilState(jobState.jobUserOption);

  const handleChange = (event: SyntheticEvent, opt: SelectOption | null) => {
    if (opt) {
      setUserOption(opt);
    } else {
      resetUserOption();
    }
    onChange();
  };

  return (
    <DropdownMenu
      label="Select a user"
      options={options}
      onChange={handleChange}
      value={userOption}
    />
  );
};

export default AnnotatorFilter;
