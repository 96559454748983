import { INITIAL_CLAIM } from 'src/configs/claims';
import {
  CLAIM_CONTROLS,
  CLAIM_CONTROL_POLYGON,
} from 'src/configs/claims/controls';
import { ClaimCreateSchema } from 'src/types/api/data/project';

/* eslint-disable no-template-curly-in-string */
const MMGClaim: ClaimCreateSchema = {
  ...INITIAL_CLAIM,
  controls: [CLAIM_CONTROL_POLYGON, ...CLAIM_CONTROLS],
  viewer: {
    type: 'mammography',
    images: [
      {
        name: 'RCC',
        id: 'RCC._id',
      },
      {
        name: 'LCC',
        id: 'LCC._id',
      },
      {
        name: 'RMLO',
        id: 'RMLO._id',
      },
      {
        name: 'LMLO',
        id: 'LMLO._id',
      },
    ],
  },
};

export default MMGClaim;
