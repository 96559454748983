import { useContext } from 'react';

import { AlertSnackbarContext } from 'src/components/Context/AlertSnackbarProvider';
import { AlertSnackbarState } from 'src/types/client/ui';

export default function useAlertSnackbar(): {
  openAlertSnackbar: (state: AlertSnackbarState) => void;
} {
  const { open } = useContext(AlertSnackbarContext);

  const openAlertSnackbar = (state: AlertSnackbarState) => open(state);

  return { openAlertSnackbar };
}
