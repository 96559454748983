import { SyntheticEvent, useEffect, useMemo, useState } from 'react';

import { ClaimAssetValidationSchema } from '@lunit-io/ctl-api-interface';
import uniq from 'lodash-es/uniq';
import { useRecoilValue } from 'recoil';

import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import DropdownMenu from 'src/components/Input/DropdownMenu';
import MultiDropdownMenu from 'src/components/Input/MultiDropDownMenu';
import CommonDialog, {
  CommonDialogProps,
} from 'src/components/Modal/CommonDialog';
import AlertPanel from 'src/pages/NewProject/AlertPanel';
import useSelectedValueOptions from 'src/pages/NewProject/useSelectedValueOptions';
import { newProjectState } from 'src/states/newProject';
import { SelectOption } from 'src/types/client/ui';
import AssetValidationUtils from 'src/utils/assetValidation';
import { INITIAL_ASSET_VALIDATION } from 'src/utils/assetValidationTemplates';

type Props = Omit<CommonDialogProps, 'open'> & {
  item?: ClaimAssetValidationSchema;
  onConfirm: (newItem: ClaimAssetValidationSchema) => void;
};

const UpdateAssetValidationDialog = ({
  item,
  onConfirm,
  onClose,
}: Props): JSX.Element => {
  const [state, setState] = useState<ClaimAssetValidationSchema>(
    INITIAL_ASSET_VALIDATION
  );

  useEffect(() => {
    const initializeStateOnItemChanged = () => {
      setState(item || INITIAL_ASSET_VALIDATION);
    };

    initializeStateOnItemChanged();
  }, [item]);

  const claim = useRecoilValue(newProjectState.claim);

  const assetOptionsForIfSet = useMemo(() => {
    return claim.assets.map<SelectOption>(asset => ({
      value: asset.name,
      label: asset.name,
      // Make texter form asset disabled.
      disabled: asset.form === 'texter',
    }));
  }, [claim.assets]);

  const assetOptionsForOneOf = useMemo(() => {
    // Make selected option for ifSet disabled.
    return assetOptionsForIfSet.map(option => {
      if (option.value === state.ifSet.name) {
        return { ...option, disabled: true };
      }
      return option;
    });
  }, [assetOptionsForIfSet, state.ifSet.name]);

  const { options: selectedValueOptions, allowMultiSelect } =
    useSelectedValueOptions(
      claim.assets.find(({ name }) => name === state.ifSet.name)
    );

  const handleChangeIfSetAsset = (
    event: SyntheticEvent,
    option: SelectOption | null
  ) => {
    // Initialize other selected options when this is changed.
    setState(prev => {
      const asset = claim.assets.find(({ name }) => name === option?.value);

      if (!asset) {
        return {
          ifSet: INITIAL_ASSET_VALIDATION.ifSet,
          oneOf: INITIAL_ASSET_VALIDATION.oneOf,
        };
      }

      return {
        ifSet: {
          ...prev.ifSet,
          name: asset.name,
          group: asset.group,
          text: asset.text,
          selectedValues: INITIAL_ASSET_VALIDATION.ifSet.selectedValues,
        },
        oneOf: INITIAL_ASSET_VALIDATION.oneOf,
      };
    });
  };

  const handleChangeIfSetSelectedValues = (
    event: SyntheticEvent,
    value: SelectOption[] | SelectOption | null
  ) => {
    setState(prev => {
      const selectedValues = (() => {
        if (!value) {
          return [];
        }
        if (Array.isArray(value)) {
          return uniq(value.map(({ value }) => value));
        }
        if (value.value === 'true') {
          return [true];
        }
        if (value.value === 'false') {
          return [false];
        }
        return [value.value];
      })();

      return {
        ...prev,
        ifSet: {
          ...prev.ifSet,
          selectedValues,
        },
      };
    });
  };

  const handleChangeOneOf = (
    event: SyntheticEvent,
    options: SelectOption[] | null
  ) => {
    setState(prev => ({
      ...prev,
      oneOf: claim.assets
        .filter(asset => options?.find(({ value }) => value === asset.name))
        .map(asset => ({
          name: asset.name,
          group: asset.group,
          text: asset.text,
        })),
    }));
  };

  const handleConfirm = () => {
    if (!state) {
      return;
    }
    onConfirm(state);
    onClose?.();
    setState(INITIAL_ASSET_VALIDATION);
  };

  const isValid = useMemo(() => {
    return (
      !!state.ifSet.name &&
      !!state.ifSet.selectedValues &&
      state.ifSet.selectedValues.length >= 1 &&
      state.oneOf.length >= 2 &&
      AssetValidationUtils.checkValidity(claim.assets, state)
    );
  }, [claim.assets, state]);

  return (
    <CommonDialog
      onClose={onClose}
      title={`Set the details for the validation`}
      open={!!item}
      maxWidth="md"
      fullWidth
    >
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <AlertPanel isValid={isValid}>
          <ul>
            <li>No input can be empty.</li>
            <li>
              At least two assets must be selected for the "oneOf &gt; assets"
              field.
            </li>
            <li>Selected assets and values must be valid in a project.</li>
          </ul>
        </AlertPanel>

        <AlertPanel>
          <AlertTitle>Rules for setting ifSet/oneOf validation</AlertTitle>
          <ul>
            <li>
              "texter" form assets cannot be used for this validation rule.
            </li>
            <li>The project must have at least 3 non-"texter" assets.</li>
          </ul>
        </AlertPanel>

        <ButtonGroup
          sx={{ gap: 1, width: '100%', alignItems: 'center', marginTop: 2 }}
        >
          <span>If the </span>
          <DropdownMenu
            label="ifSet > asset"
            options={assetOptionsForIfSet}
            onChange={handleChangeIfSetAsset}
            value={{
              value: state.ifSet.name,
              label: state.ifSet.name,
            }}
          />
          <span>'s</span>
          {allowMultiSelect ? (
            <MultiDropdownMenu
              label="ifSet > values of an asset"
              options={selectedValueOptions}
              onChange={handleChangeIfSetSelectedValues}
              value={(state.ifSet.selectedValues || []).map(value => ({
                value: `${value}`,
                label: `${value}`,
              }))}
              disabled={!state.ifSet.name}
              openOptionsOnMounted
              sx={{ flex: 1 }}
            />
          ) : (
            <DropdownMenu
              label="ifSet > values of an asset"
              options={selectedValueOptions}
              onChange={handleChangeIfSetSelectedValues}
              value={
                state.ifSet.selectedValues?.[0] === undefined
                  ? null
                  : {
                      value: `${state.ifSet.selectedValues[0]}`,
                      label: `${state.ifSet.selectedValues[0]}`,
                    }
              }
              disabled={!state.ifSet.name}
              openOptionsOnMounted
              sx={{ flex: 1 }}
            />
          )}
          <span>
            {state.ifSet.selectedValues && state.ifSet.selectedValues.length > 1
              ? 'are'
              : 'is'}{' '}
            selected,
          </span>
        </ButtonGroup>
        <ButtonGroup sx={{ gap: 1, width: '100%', alignItems: 'center' }}>
          <span>one of </span>
          <MultiDropdownMenu
            sx={{ flex: 1 }}
            label="oneOf > assets"
            options={assetOptionsForOneOf}
            onChange={handleChangeOneOf}
            value={state.oneOf.map(({ name }) => ({
              value: name,
              label: name,
            }))}
          />
          <span>need to be selected.</span>
        </ButtonGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleConfirm} disabled={!isValid}>
          Confirm
        </Button>
      </DialogActions>
    </CommonDialog>
  );
};

export default UpdateAssetValidationDialog;
