import { Dispatch, SetStateAction } from 'react';

import { useRecoilValue } from 'recoil';

import { styled } from '@mui/material/styles';

import QueryBuilderInput from 'src/components/Input/QueryBuilderInput';
import { caseState } from 'src/states/case';
import { SelectOption } from 'src/types/client/ui';

interface Props {
  selectedModality?: SelectOption;
  setCaseFilter: Dispatch<SetStateAction<string | undefined>>;
  setReadingFilter: Dispatch<SetStateAction<string | undefined>>;
}

const QueryBuilders = ({
  selectedModality,
  setCaseFilter,
  setReadingFilter,
}: Props): JSX.Element => {
  const options = useRecoilValue(
    caseState.queryBuilderOptions(selectedModality?.label)
  );

  return (
    <Container>
      {options && (
        <QueryBuilderInput
          filterName="Case"
          key={selectedModality?.value}
          fields={options.caseQueryFields}
          operators={options.operators}
          combinators={options.combinators}
          onChange={q => setCaseFilter(q)}
        />
      )}
      {options && (
        <QueryBuilderInput
          filterName="Reading"
          key={selectedModality?.label}
          fields={options.readingQueryFields}
          operators={options.operators}
          combinators={options.combinators}
          onChange={q => setReadingFilter(q)}
        />
      )}
    </Container>
  );
};

export default QueryBuilders;

const Container = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 0.75rem;

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
  }
`;
