import Cookie from 'universal-cookie';

type Token = {
  exp: number;
  iat: number;
  is_admin: boolean;
  name: string;
  permittedModalities: string[];
  defaultModality: string;
  username: string;
  _id: string;
};

export const parseJwt = (token: string): Token | undefined => {
  const [, base64Url] = token.split('.');

  if (!base64Url) {
    return undefined;
  }

  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join('')
  );
  return JSON.parse(jsonPayload);
};

export const getTimeDifference = (expirationTime: number): string => {
  const now = Date.now();
  const diff = expirationTime - now;
  const secDiff = Math.round(diff / 1000);
  const minDiff = Math.round(secDiff / 60);
  const diffStr = `${minDiff}:${secDiff - minDiff * 60}`;

  return diffStr;
};

const ACCESS_TOKEN_KEY = 'LUNIT_CTL_OFFICE_ACCESS_TOKEN';
const cookie = new Cookie();

const cookieHelper = {
  setCookie(key: string, value: string): void {
    cookie.set(key, value);
  },
  getCookie(key: string): string {
    return cookie.get(key);
  },
  removeCookie(key: string): void {
    cookie.remove(key);
  },
  setAccessToken(value?: string): void {
    if (value) {
      cookieHelper.setCookie(ACCESS_TOKEN_KEY, value);
    }
  },
  hasAccessToken(): boolean {
    return Boolean(cookie.get(ACCESS_TOKEN_KEY));
  },
  getAccessToken(): string | undefined {
    return cookie.get(ACCESS_TOKEN_KEY);
  },
  removeAccessToken(): void {
    cookieHelper.removeCookie(ACCESS_TOKEN_KEY);
  },
  isAccessTokenExpired(): boolean {
    const tokenString = cookieHelper.getAccessToken();

    if (!tokenString) {
      return true;
    }

    const token = parseJwt(tokenString);

    if (!token) {
      return true;
    }

    const currentTime = parseInt((new Date().getTime() / 1000).toFixed(0), 10);
    const tokenExp = token.exp;

    if (currentTime >= tokenExp) {
      console.error('[Token expired] time diff: ', getTimeDifference(tokenExp));
      return true;
    }

    return false;
  },
};

export default cookieHelper;
