import { useState, ChangeEvent, useMemo } from 'react';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { cloneProject } from 'src/api';
import { ProjectClonePayload } from 'src/api/projects';
import { Flexbox } from 'src/components/Alignments';
import useAlertSnackbar from 'src/hooks/useAlertSnackbar';
import useCheckedParams from 'src/hooks/useCheckedParams';
import { useRefreshProjectList } from 'src/states/project';
import { Modality } from 'src/types/api/data/image';

import CommonDialog, { CommonDialogProps } from './CommonDialog';

interface Props extends CommonDialogProps {
  projectName: string;
  projectId: string;
}

const CloneProjectDialog = ({
  open,
  projectName,
  projectId,
  onClose,
}: Props): JSX.Element => {
  const [clonedProjectName, setClonedProjectName] = useState('');
  const [jobsOption, setJobsOption] = useState(false);
  const [annotatorReviewerOption, setAnnotatorReviewerOption] = useState(false);
  const [readingsOption, setReadingsOption] = useState(false);
  const refreshProjectList = useRefreshProjectList();
  const { openAlertSnackbar } = useAlertSnackbar();
  const { modality } = useCheckedParams<{
    modality: Modality;
  }>(['modality']);

  const handleCloneProject = async () => {
    const payload: ProjectClonePayload = {
      name: clonedProjectName,
      claim: true, // always true
      annotator: annotatorReviewerOption,
      job: jobsOption,
      reading: readingsOption,
    };

    try {
      await cloneProject(projectId, modality, payload);
      refreshProjectList();
    } catch {
      openAlertSnackbar({
        severity: 'error',
        description: 'Failed to clone project.',
      });
    }
    onClose?.();
  };

  const handleChangeProjectName = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => setClonedProjectName(e.target.value);

  const isCloneButtonDisabled = useMemo<boolean>((): boolean => {
    const isProjectNameEmpty = clonedProjectName.trim().length === 0;
    return isProjectNameEmpty;
  }, [clonedProjectName]);

  return (
    <>
      <CommonDialog
        onClose={onClose}
        open={open}
        title={`Cloning : ${projectName}`}
      >
        <DialogContent>
          <TextField
            size="small"
            variant="outlined"
            label="New project name"
            value={clonedProjectName}
            onChange={handleChangeProjectName}
            fullWidth
          />

          <Typography style={{ margin: '16px 0 0' }} gutterBottom>
            Option Selection
          </Typography>

          <Flexbox $justify="space-between" $align="center">
            <FormControlLabel
              disabled={true}
              control={
                <Checkbox
                  name="claim"
                  checked={true}
                  inputProps={{ 'aria-label': 'claim checkbox' }}
                />
              }
              label="Claim"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="jobs"
                  checked={jobsOption}
                  onChange={() =>
                    setJobsOption(prev => {
                      if (prev) setReadingsOption(false);
                      return !prev;
                    })
                  }
                  inputProps={{ 'aria-label': 'jobs checkbox' }}
                />
              }
              label="Jobs"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="annotator/reviewers"
                  checked={annotatorReviewerOption}
                  onChange={() => setAnnotatorReviewerOption(prev => !prev)}
                  inputProps={{ 'aria-label': 'annotator/reviewers checkbox' }}
                />
              }
              label="Annotator/Reviewers"
            />
            <FormControlLabel
              disabled={!jobsOption}
              control={
                <Checkbox
                  name="readings"
                  checked={readingsOption}
                  onChange={() => setReadingsOption(prev => !prev)}
                  inputProps={{ 'aria-label': 'readings checkbox' }}
                />
              }
              label="Readings"
            />
          </Flexbox>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleCloneProject} disabled={isCloneButtonDisabled}>
            Clone
          </Button>
        </DialogActions>
      </CommonDialog>
    </>
  );
};

export default CloneProjectDialog;
