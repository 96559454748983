import { PropsWithChildren, useRef } from 'react';

import { Identifier, XYCoord } from 'dnd-core';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';

import Close from '@mui/icons-material/Close';
import Info from '@mui/icons-material/Info';
import SubdirectoryArrowRight from '@mui/icons-material/SubdirectoryArrowRight';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import FormListItemBase from 'src/pages/NewProject/FormListItemBase';
import {
  AssetDropResult,
  ASSET_DROP_TYPE,
  ASSET_ITEM_TYPE,
  ClientClaimAssetCreateSchema,
} from 'src/types/client/asset';

import ListItemContent from './ListItemContent';
import { DraggingTemplateListItem } from './TemplateListItem';

/**
 * @see https://react-dnd.github.io/react-dnd/docs/overview#items-and-types
 * DragItem interface should be always minimum.
 * Because it is always cloning on every event of dragging.
 * So used Pick utility rather than using actual data.
 *
 * These picked data can include fields which will be used on the result of dragging.
 * You can see the use cases of data on `collect` or `hover` field of useDrop hook,
 * and you can see when that data will be generated on `item` field of useDrag hook.
 */
interface DragItem {
  id: string;
  index: number;
}

interface OwnProps {
  index: number;
  data: ClientClaimAssetCreateSchema;
  isValid: boolean;
  onMove?: (dragIndex: number, hoverIndex: number) => void;
  onInspect: (clickedItem: ClientClaimAssetCreateSchema) => void;
  onDelete: (clickedItem: ClientClaimAssetCreateSchema) => void;
}

const ConfiguredListItem = ({
  data,
  index,
  isValid,
  onMove,
  onInspect,
  onDelete,
  children,
}: PropsWithChildren<OwnProps>): JSX.Element => {
  const isNested = !!data.parent;

  const ref = useRef<HTMLDivElement>(null);

  const [dropContext, dropForNesting] = useDrop(
    () => ({
      accept: ASSET_ITEM_TYPE.TEMPLATE,
      /**
       * - Child assets cannot have their own child assets. (No grandchild assets allowed).
       * - "decision" group assets cannot have child assets.
       * - "texter" form assets cannot have child assets.
       * - Only assets of the same group can be related.
       * @returns boolean
       */
      canDrop: (item: DraggingTemplateListItem) => {
        const targetAsset = data;
        const draggingAsset = item;

        return (
          !isNested &&
          targetAsset.form !== 'texter' &&
          targetAsset.group !== 'decision' &&
          targetAsset.group === draggingAsset.group
        );
      },
      drop: (): AssetDropResult => {
        return {
          type: ASSET_DROP_TYPE.NESTING,
          asset: data,
        };
      },
      collect: monitor => ({
        isOver: monitor.isOver(),
        isDragging: monitor.getItemType() === ASSET_ITEM_TYPE.TEMPLATE,
        canDrop: monitor.canDrop(),
      }),
    }),
    /**
     * To update the context of useDrop by the changes of the prop,
     * need to set the dependency array for that.
     *
     * @see https://github.com/react-dnd/react-dnd/issues/3000#issuecomment-1080561326
     */
    [data]
  );

  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ASSET_ITEM_TYPE.CONFIGURED,
    canDrop: () => !isNested,
    collect(monitor: DropTargetMonitor<DragItem, void>) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor: DropTargetMonitor<DragItem, void>) {
      if (!ref.current || !monitor.canDrop()) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Determine drop target offset
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      const isDraggingDownwards =
        dragIndex < hoverIndex && hoverClientY < hoverMiddleY;
      // When dragging upwards, only move when the cursor is above 50%
      const isDraggingUpwards =
        dragIndex > hoverIndex && hoverClientY > hoverMiddleY;

      if (isDraggingDownwards || isDraggingUpwards) {
        return;
      }

      onMove?.(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ASSET_ITEM_TYPE.CONFIGURED,
    item: (): DragItem => ({ id: data.id, index }),
    canDrag: () => !isNested,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      canDrag: monitor.canDrag(),
    }),
  });

  const handleInspect = () => {
    onInspect(data);
  };

  const handleDelete = () => {
    onDelete(data);
  };

  const selectedValues = data.parent?.selectedValues?.join(', ') || '';

  drag(drop(ref));

  return (
    <Container ref={dropForNesting} $isNested={isNested}>
      {isNested && (
        <NestingInformation>
          <SubdirectoryArrowRight fontSize="inherit" />
          <Tooltip title={selectedValues} placement="left">
            <SelectedValues>{selectedValues}</SelectedValues>
          </Tooltip>
        </NestingInformation>
      )}
      <FormListItemBase
        ref={ref}
        data-handler-id={handlerId}
        $isValid={isValid}
        $isDragging={isDragging}
        $isDraggable
        $isDisabled={dropContext.isDragging && !dropContext.canDrop}
      >
        <ListItemContent
          text={data.name}
          $isActivated={dropContext.canDrop && dropContext.isOver}
        >
          <IconButton onClick={handleInspect}>
            <Info fontSize="small" />
          </IconButton>
          <IconButton onClick={handleDelete}>
            <Close fontSize="small" />
          </IconButton>
        </ListItemContent>
        {children && <ChildrenWrap>{children}</ChildrenWrap>}
      </FormListItemBase>
    </Container>
  );
};

export default ConfiguredListItem;

const Container = styled('div')<{ $isNested: boolean }>(
  ({ theme, $isNested }) => `
  display: flex;
  align-items: center;
  border-width: ${$isNested ? '1px 0 0 0' : 0};
  border-style: solid;
  border-color: ${theme.custom.bgLight};
`
);

export const Content = styled('div')<{ $isActivated?: boolean }>(
  ({ theme, $isActivated }) => `
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  background-color: ${$isActivated ? theme.custom.primary : 'transparent'};
`
);

const NestingInformation = styled('div')(
  ({ theme }) => `
  max-width: 50%;
  display: flex;
  align-items: center;
  gap: ${theme.spacing(0.5)};
  padding-left: ${theme.spacing(1.5)};
  min-width: 0;
  font-size: 0.8rem;
  color: ${theme.custom.text};
`
);

const SelectedValues = styled('span')`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ChildrenWrap = styled('div')(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
`
);
