import axios, { AxiosError } from 'axios';
import queryString from 'query-string';

import { HOSTS } from 'src/configs/hosts';
import { ClientError, ClientErrorCode } from 'src/utils/clientError';
import cookieHelper from 'src/utils/cookieHelper';

export const getSignedImageURL = async (
  path: string,
  width: number,
  height: number
): Promise<string> => {
  const token = cookieHelper.getAccessToken();
  const url = queryString.stringifyUrl(
    {
      url: `${HOSTS.CTL_IMAGE_SERVER}/img`,
      query: {
        path,
        width,
        height,
        token,
      },
    },
    {
      skipNull: true,
      skipEmptyString: true,
    }
  );

  if (path.startsWith('gs')) {
    try {
      const { data } = await axios.get<{ signed_url: string }>(url, {
        headers: {
          'Content-Type': 'image/png',
          accept: '*/*',
          Authorization: `Bearer ${token}`,
        },
      });

      return data.signed_url;
    } catch (err) {
      const error = err as AxiosError;
      const message = error.message || '';

      throw new ClientError({
        code: ClientErrorCode.UNEXPECTED,
        message,
        originalError: err,
      });
    }
  }

  if (path.endsWith('dcm')) {
    return `wadouri:${url}`;
  }

  return url;
};
