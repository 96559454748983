import { useRecoilValue } from 'recoil';

import { userState } from 'src/states/user';

interface Props {
  userId: string;
}

const UserNameColumn = ({ userId }: Props): JSX.Element => {
  const userName = useRecoilValue(userState.userName(userId));
  return <span>{userName}</span>;
};

export default UserNameColumn;
