import { generatePath, matchPath } from 'react-router-dom';

import { PathNames } from 'src/types/client/url';
import { ClientError, ClientErrorCode } from 'src/utils/clientError';

export const UrlUtil = (() => {
  const BASE_PATH = '/';

  const URLS: Record<PathNames, string> = {
    [PathNames.HOME]: BASE_PATH,
    [PathNames.LOGIN]: `${BASE_PATH}login`,
    [PathNames.USERS]: `${BASE_PATH}users`,
    [PathNames.PROJECTS]: `${BASE_PATH}modalities/:modalityLabel/projects`,
    [PathNames.PROJECT]: `${BASE_PATH}modalities/:modalityLabel/projects/:projectId`,
    [PathNames.NEW_PROJECT]: `${BASE_PATH}modalities/:modalityLabel/new-project`,
    [PathNames.PROJECT_TAB]: `${BASE_PATH}modalities/:modalityLabel/projects/:projectId/:tabName`,
    [PathNames.CASE_SEARCH]: `${BASE_PATH}modalities/:modalityLabel/case-search`,
    [PathNames.AI_OPERATIONAL]: `${BASE_PATH}modalities/:modalityLabel/ai`,
    [PathNames.REPORTS]: `${BASE_PATH}modalities/:modalityLabel/reports`,
    [PathNames.ASSET_TEMPLATES]: `${BASE_PATH}modalities/:modalityLabel/asset-templates`,
    [PathNames.NEW_ASSET_TEMPLATE]: `${BASE_PATH}modalities/:modalityLabel/new-asset-template`,
    [PathNames.MANAGE_ASSET_TEMPLATE]: `${BASE_PATH}modalities/:modalityLabel/asset-templates/:assetTemplateId`,
    // Refactor with Redux or React hooks and change detail modals to pages
  };

  /**
   * Get actual URL string with PathName
   *
   * @param pathName PathName
   * @param payload object
   * @returns string
   */
  const getUrl = (
    pathName: PathNames,
    payload?: { [key: string]: string | number }
  ): string => {
    const url = URLS[pathName];
    if (!url) {
      throw new ClientError({
        code: ClientErrorCode.BAD_REQUEST,
        message: 'There is no url',
      });
    }
    if (payload) {
      return generatePath(url, payload);
    }
    return url;
  };

  /**
   * Get PathName with location.pathname
   *
   * @param pathname string
   * @returns PathName
   */
  const getMatchedPathName = (pathname: string): PathNames => {
    const pathNames = Object.keys(URLS) as Array<keyof typeof URLS>;
    const currentPathName = pathNames.find(pathName =>
      matchPath(URLS[pathName], pathname)
    );
    return currentPathName as PathNames;
  };

  return Object.freeze({
    getUrl,
    getMatchedPathName,
  });
})();
