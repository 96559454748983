import {
  atom,
  atomFamily,
  selector,
  selectorFamily,
  useSetRecoilState,
} from 'recoil';

import {
  getAnnotatorList,
  getReviewerList,
  getUsersInfo,
  getAssociateList,
} from 'src/api';
import { myInfoState } from 'src/states/myInfo';
import { Modality } from 'src/types/api/data/image';
import { SelectOption } from 'src/types/client/ui';
import { User } from 'src/types/client/user';

const refreshUserList = atom({
  key: 'user/refreshUserList',
  default: 0,
});

export function useRefreshUserList(): () => void {
  const setRefreshState = useSetRecoilState(refreshUserList);
  return () => {
    setRefreshState(v => v + 1);
  };
}

const userList = selector<User[]>({
  key: 'user/userList',
  get: ({ get }) => {
    get(refreshUserList);
    return getUsersInfo();
  },
});

const singleUser = selectorFamily<User | undefined, string>({
  key: 'user/singleUser',
  get:
    userId =>
    ({ get }) => {
      if (!userId) {
        return undefined;
      }
      const users = get(userList);
      return users.find(({ id }) => id === userId);
    },
});

const userName = selectorFamily<string, string>({
  key: 'user/userName',
  get:
    userId =>
    ({ get }) => {
      const user = get(singleUser(userId));
      return user?.username || '-';
    },
});

/**
 * TODO: should be renamed as it is generic, but actually it is used in context of reviewer/annotator
 * and filtered by a specific modality
 * not necessarily user
 */
const userOptionList = selectorFamily({
  key: 'user/userOptionList',
  get:
    (modality: Modality) =>
    ({ get }) => {
      const users = get(userList);
      return users
        .filter(
          user => user.permittedModalities.includes(modality) !== undefined
        )
        .map(
          (user): SelectOption => ({
            value: user.id,
            label: user.username,
          })
        );
    },
});

const defaultOption = {
  value: 'all',
  label: 'All users',
};

const modalityFilteredUserList = selector<User[]>({
  key: 'user/modalityFilteredUserList',
  get: ({ get }) => {
    return get(userList).filter(user => {
      return user.permittedModalities.every(modality => {
        return get(myInfoState).permittedModalities.some(
          myModality => myModality === modality
        );
      });
    });
  },
});

const allUserOptionList = selector<SelectOption[]>({
  key: 'user/allUserOptionList',
  get: ({ get }) => {
    const users: User[] = get(modalityFilteredUserList);
    return [
      defaultOption,
      ...users.map(user => ({
        value: user.id,
        label: user.username,
      })),
    ];
  },
});

type AnnotatorListProps = {
  projectId: string;
  modality: Modality;
};

const refreshAnnotatorList = atomFamily<number, AnnotatorListProps>({
  key: 'user/refreshAnnotatorList',
  default: 0,
});

export const useRefreshAnnotatorList = (
  props: AnnotatorListProps
): (() => void) => {
  const setRefreshState = useSetRecoilState(refreshAnnotatorList(props));
  return () => {
    setRefreshState(v => v + 1);
  };
};

const annotatorList = selectorFamily({
  key: 'user/annotatorList',
  get:
    ({ projectId, modality }: { projectId: string; modality: Modality }) =>
    ({ get }) => {
      get(refreshAnnotatorList({ projectId, modality }));
      // TODO: use get annotator list api when it is ready
      return getAnnotatorList(projectId, modality);
    },
});

const annotatorOptionList = selectorFamily({
  key: 'user/annotatorOptionList',
  get:
    (props: { projectId: string; modality: Modality }) =>
    ({ get }) => {
      const annotators = get(annotatorList(props));
      return annotators.map(
        (annotator): SelectOption => ({
          value: annotator.id,
          label: annotator.username,
        })
      );
    },
});

const refreshReviewerList = atom({
  key: 'user/refreshReviewerList',
  default: 0,
});

export function useRefreshReviewerList(): () => void {
  const setRefreshState = useSetRecoilState(refreshReviewerList);
  return () => {
    setRefreshState(v => v + 1);
  };
}

const reviewerList = selectorFamily({
  key: 'user/reviewerList',
  get:
    ({ projectId, modality }: { projectId: string; modality: Modality }) =>
    ({ get }) => {
      get(refreshReviewerList);
      return getReviewerList(projectId, modality);
    },
});

const associateList = selectorFamily({
  key: 'user/associateList',
  get:
    ({ idList }: { idList: string[] }) =>
    () => {
      return getAssociateList(idList);
    },
});

export const userState = Object.freeze({
  userName,
  singleUser,
  userList,
  userOptionList,
  allUserOptionList,
  annotatorList,
  annotatorOptionList,
  reviewerList,
  associateList,
});
