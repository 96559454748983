import { Dispatch, SetStateAction } from 'react';

import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/material/styles';

interface Props<T> {
  items: Readonly<T[]>;
  currentItem: T;
  setCurrentItem: Dispatch<SetStateAction<T>>;
  resetButtonElement: JSX.Element;
  submitButtonElement: JSX.Element;
}

const FormHeader = <T extends string>({
  items,
  currentItem,
  setCurrentItem,
  resetButtonElement,
  submitButtonElement,
}: Props<T>): JSX.Element => {
  const handleChange = (event: SelectChangeEvent<T>) => {
    setCurrentItem(event.target.value as T);
  };

  const getHandleClick = (item: T) => (): void => {
    setCurrentItem(item);
  };

  const currentIndex = items.findIndex(item => currentItem === item);
  const prevItem = items[currentIndex - 1];
  const nextItem = items[currentIndex + 1];

  return (
    <Container>
      <ButtonWrap>
        {!!prevItem ? (
          <Button
            startIcon={<ChevronLeft fontSize="inherit" />}
            color="inherit"
            variant="text"
            size="large"
            onClick={getHandleClick(prevItem)}
          >
            {prevItem}
          </Button>
        ) : (
          resetButtonElement
        )}
      </ButtonWrap>
      <FormControl sx={{ flex: 1.5, textAlign: 'center' }}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          data-test-id="newProject-stepSelectDropdown"
          value={currentItem}
          onChange={handleChange}
        >
          {items.map((item, index) => (
            <MenuItem value={item} key={item}>
              {index + 1} / {items.length}&nbsp;&nbsp;&nbsp;
              <strong>{item.toUpperCase()}</strong>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <ButtonWrap sx={{ justifyContent: 'flex-end' }}>
        {!!nextItem ? (
          <Button
            endIcon={<ChevronRight fontSize="inherit" />}
            color="inherit"
            variant="text"
            size="large"
            onClick={getHandleClick(nextItem)}
          >
            {nextItem}
          </Button>
        ) : (
          submitButtonElement
        )}
      </ButtonWrap>
    </Container>
  );
};

export default FormHeader;

const Container = styled('div')(
  ({ theme }) => `
  background-color: ${theme.custom.bgDark};
  border-radius: .5rem;
  padding: 1rem 1.5rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
`
);

const ButtonWrap = styled('div')`
  flex: 1;
  display: flex;
`;
