import {
  ClaimControlGroupDrawSchema,
  ClaimSchema as Claim,
} from '@lunit-io/ctl-api-interface';

export const CLAIM_CONTROL_POLYGON: ClaimControlGroupDrawSchema = {
  name: 'polygon',
  group: 'draw',
};

export const CLAIM_CONTROL_LINE: ClaimControlGroupDrawSchema = {
  name: 'line',
  group: 'draw',
};

export const CLAIM_CONTROL_POINT: ClaimControlGroupDrawSchema = {
  name: 'point',
  group: 'draw',
};

export const CLAIM_CONTROL_MULTI_FRAME_POLYGON: ClaimControlGroupDrawSchema = {
  name: 'multiFramePolygon',
  group: 'draw',
};

export const CLAIM_CONTROLS: Claim['controls'] = [
  {
    name: 'pan',
    group: 'traverse',
  },
  {
    name: 'brightness',
    group: 'manipulate',
  },
  {
    name: 'flip',
    group: 'manipulate',
  },
  {
    name: 'invert',
    group: 'manipulate',
  },
  {
    name: 'reset',
    group: 'etc',
  },
];

export const CLAIM_CONTROLS_DBT: Claim['controls'] = [
  {
    name: 'zoom',
    group: 'traverse',
  },
  ...CLAIM_CONTROLS,
];
