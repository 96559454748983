import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';

const PrettoSlider = styled(Slider)(({ theme }) => ({
  root: {
    color: theme.palette.primary.light,
    height: 10,
  },
  thumb: {
    height: 74,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
}));

interface RangeSliderProps {
  disabled?: boolean;
  step?: number;
  min?: number;
  max: number;
  value: number;
  onChange: (n: number) => void;
}

export default function StyledRangeSlider(
  props: RangeSliderProps
): JSX.Element {
  const { disabled = false, step = 1, min = 0, max, onChange, value } = props;

  // strange: material-ui used ChangeEvent<{}> type for ChangeEvent,
  // for now just just used unknown until better way found
  // official usage even worse in terms of typing: `(e: any)`
  const handleChangeValues = (_: Event, newValue: number | number[]) => {
    const [value] = Array.isArray(newValue) ? newValue : ([newValue] as const);
    onChange(value);
  };

  return (
    <PrettoSlider
      valueLabelDisplay="auto"
      value={value}
      step={step}
      disabled={disabled}
      onChange={handleChangeValues}
      min={min}
      max={max}
      role="slider"
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={value}
      aria-label="RangeSlider"
    />
  );
}
