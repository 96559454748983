import { useState } from 'react';

import dayjs from 'dayjs';
import { useRecoilState, useRecoilValue } from 'recoil';

import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';

import { patchReport } from 'src/api/reports';
import { SectionTitle } from 'src/components';
import ModalityViewer from 'src/components/Viewer/ModalityViewer';
import useAlertSnackbar from 'src/hooks/useAlertSnackbar';
import { caseState } from 'src/states/case';
import {
  isDetailDrawerOpen,
  reportListQueryParamState,
  selectedReport,
  useRefreshReportList,
} from 'src/states/reportList';
import { DATE_FORMAT } from 'src/utils/constants';

import UserNameColumn from './UserNameColumn';

const PREFIX = 'ReportDetailDrawer';

const classes = {
  paper: `${PREFIX}-paper`,
  root: `${PREFIX}-root`,
  divider: `${PREFIX}-divider`,
  primary: `${PREFIX}-primary`,
  secondary: `${PREFIX}-secondary`,
};

const StyledDrawer = styled(Drawer)({
  [`& .${classes.root}`]: {
    padding: '.25rem 0',
  },

  [`& .${classes.divider}`]: {
    borderColor: '#2e394f',
  },

  [`& .${classes.primary}`]: {
    color: '#fff',
  },

  [`& .${classes.secondary}`]: {
    fontSize: '1rem',
    color: '#8694b1',
    whiteSpace: 'pre-wrap',
  },
});

const ButtonWrapper = styled('div')`
  margin: 8px 0;
`;

const ReportDetailDrawer = (): JSX.Element => {
  const [isDetailOpen, setIsDetailOpen] = useRecoilState(isDetailDrawerOpen);
  const { openAlertSnackbar } = useAlertSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [report, setReport] = useRecoilState(selectedReport);
  const refreshReportList = useRefreshReportList();

  const params = useRecoilValue(reportListQueryParamState);
  const caseSchema = useRecoilValue(
    caseState.caseSchema({
      caseId: report.case,
      modality: params.selectedModality?.label,
    })
  );

  const handleClickResolve = async () => {
    if (!report) {
      return;
    }
    setIsLoading(true);
    try {
      await patchReport({
        reportId: report.id,
        resolved: !report.resolved,
      });
      refreshReportList();
      openAlertSnackbar({
        severity: 'success',
        description: `Successfully ${
          report.resolved ? 'unresolved' : 'resolved'
        } the report.`,
      });
      setReport(prev => ({ ...prev, resolved: !report.resolved }));
    } catch {
      openAlertSnackbar({
        severity: 'error',
        description: `Failed to ${
          report.resolved ? 'unresolve' : 'resolve'
        } the report.`,
      });
    }
    setIsLoading(false);
  };

  return (
    <StyledDrawer
      anchor="right"
      open={isDetailOpen}
      onClose={() => {
        setIsDetailOpen(false);
      }}
    >
      <>
        <SectionTitle>Report Detail</SectionTitle>
        <ButtonWrapper>
          <Button
            onClick={handleClickResolve}
            variant="contained"
            color="primary"
            fullWidth
            disabled={isLoading}
          >
            {report.resolved ? 'Unresolve' : 'Resolve'}
          </Button>
        </ButtonWrapper>
        <List>
          <ListItem
            divider={true}
            classes={{
              root: classes.root,
              divider: classes.divider,
            }}
          >
            <ListItemText
              primary="Created"
              secondary={dayjs(new Date(report.createdAt)).format(DATE_FORMAT)}
              classes={{
                primary: classes.primary,
                secondary: classes.secondary,
              }}
            />
          </ListItem>
          <ListItem
            divider={true}
            classes={{
              root: classes.root,
              divider: classes.divider,
            }}
          >
            <ListItemText
              primary="User Name"
              secondary={<UserNameColumn userId={report.user} />}
              classes={{
                primary: classes.primary,
                secondary: classes.secondary,
              }}
            />
          </ListItem>
          <ListItem
            divider={true}
            classes={{
              root: classes.root,
              divider: classes.divider,
            }}
          >
            <ListItemText
              primary="Description"
              secondary={report.text}
              classes={{
                primary: classes.primary,
                secondary: classes.secondary,
              }}
            />
          </ListItem>
          <ListItem
            divider={true}
            classes={{
              root: classes.root,
              divider: classes.divider,
            }}
          >
            <ListItemText
              primary="Job ID"
              secondary={report.job}
              classes={{
                primary: classes.primary,
                secondary: classes.secondary,
              }}
            />
          </ListItem>
          <ListItem
            classes={{
              root: classes.root,
              divider: classes.divider,
            }}
            disableGutters
          >
            <ListItemText
              primary="Case ID"
              secondary={
                report.case || 'There is no included case on this report.'
              }
              classes={{
                primary: classes.primary,
                secondary: classes.secondary,
              }}
            />
          </ListItem>
        </List>
        <ModalityViewer
          images={caseSchema?.images}
          modalityLabel={params.selectedModality?.label}
        />
      </>
    </StyledDrawer>
  );
};

export default ReportDetailDrawer;
