import { useContext } from 'react';

import { ConfirmModalContext } from 'src/components/Context/ConfirmModalProvider';
import { ConfirmModalState } from 'src/types/client/ui';

/**
 * Asynchronous confirmation hook
 *
 * @see https://dev.to/metamodal/control-a-dialog-box-asynchronously-using-react-hooks-4ik7
 * @returns getConfirmation
 */
const useConfirmModal = (): {
  getConfirmation: (state: ConfirmModalState) => Promise<boolean>;
} => {
  const { open } = useContext(ConfirmModalContext);

  const getConfirmation = (state: ConfirmModalState) =>
    new Promise<boolean>(callback => {
      open(state, callback);
    });
  return { getConfirmation };
};

export default useConfirmModal;
