import { memo } from 'react';

import isEqual from 'react-fast-compare';
import {
  ObjectInspector,
  chromeDark,
  InspectorThemeDefinition,
} from 'react-inspector';

import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

import ModalityViewer from 'src/components/Viewer/ModalityViewer';
import { ReducedCaseReadSchema } from 'src/types/api/data/case';
import { Modality } from 'src/types/api/data/image';

const InspectorOpt: Partial<InspectorThemeDefinition> = {
  BASE_FONT_SIZE: '0.95rem',
  TREENODE_FONT_SIZE: '0.95rem',
  TREENODE_PADDING_LEFT: '1rem',
  BASE_BACKGROUND_COLOR: 'transparent',
};

interface OwnProps {
  item: ReducedCaseReadSchema;
}

export function QueryResultViewItem({ item }: OwnProps): JSX.Element {
  if (!item.images) return <div>No images</div>;

  const hasViewer =
    item.modality === Modality.CXR || item.modality === Modality.MMG;

  return (
    <QueryResultViewItemPaper>
      <div>
        <Chip label="Reading" size="small" variant="filled" />
        <ObjectInspector
          theme={{ ...chromeDark, ...InspectorOpt }}
          data={item.reading}
        />
        <Chip label="Case" size="small" variant="filled" />
        <ObjectInspector
          theme={{ ...chromeDark, ...InspectorOpt }}
          data={item}
        />
      </div>
      {hasViewer && (
        <div>
          <ModalityViewer modalityLabel={item.modality} images={item.images} />
        </div>
      )}
    </QueryResultViewItemPaper>
  );
}

export const MemoizedQueryResultViewItem = memo(QueryResultViewItem, isEqual);

export default QueryResultViewItem;

const QueryResultViewItemPaper = styled('div')(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  padding: 1rem;
  gap: 1rem;
  background-color: ${theme.custom.bgDark};
  border-bottom: 1px solid ${theme.custom.bg};

  > div:nth-child(1) {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: auto;
    gap: 0.5rem;
  }

  > div:nth-child(2) {
    max-width: 20rem;
    width: 35%;
  }
`
);
