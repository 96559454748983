import {
  DatasetListSchema,
  DatasetReadSchema,
} from 'src/types/api/data/dataset';
import { Modality } from 'src/types/api/data/image';
import { dataApi } from 'src/utils/http';

export const getDataset = async (
  datasetId: string,
  modality: Modality
): Promise<DatasetReadSchema> => {
  const { data } = await dataApi.get<DatasetReadSchema>(
    `/datasets/${datasetId}/`,
    {
      params: {
        modality: modality.toLowerCase(),
      },
    }
  );

  return data;
};

export const getDatasets = async (
  modality: Modality,
  page?: number,
  pageSize?: number
): Promise<DatasetListSchema> => {
  const { data } = await dataApi.get<DatasetListSchema>(`/datasets/`, {
    params: {
      page: page || 1,
      page_size: pageSize,
      fields: ['id', 'name', 'createdAt'],
      modality: modality.toLowerCase(),
    },
  });

  return data;
};
