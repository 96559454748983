import CircularProgress from '@mui/material/CircularProgress';

interface SpinnerProps {
  size: number;
  className?: string;
}

const Spinner = ({ className, size = 20 }: SpinnerProps): JSX.Element => (
  <CircularProgress size={size} color="inherit" className={className} />
);

export default Spinner;
