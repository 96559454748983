import {
  CornerstoneImage,
  CornerstoneSingleImage,
} from '@lunit/insight-viewer';
import { selectorFamily } from 'recoil';

import { getSignedImageURL } from 'src/api/image';
import { ClientError, ClientErrorCode } from 'src/utils/clientError';

type ImageProps = {
  imagePath?: string;
  width: number;
  height: number;
};

const single = selectorFamily<CornerstoneImage | undefined, ImageProps>({
  key: 'imageState/single',
  get:
    ({ imagePath, width, height }: ImageProps) =>
    async () => {
      if (imagePath) {
        const signedImageURL = await getSignedImageURL(
          imagePath,
          width,
          height
        );
        const image = new CornerstoneSingleImage(signedImageURL);

        const getLoadedImage = (): Promise<boolean> =>
          new Promise(resolve => {
            if (!image) resolve(false);
            else
              image?.progress.subscribe(loaded => {
                if (loaded === 1) {
                  resolve(true);
                }
              });
          });

        const loaded = await getLoadedImage();

        if (!loaded) {
          throw new ClientError({
            code: ClientErrorCode.INVALID_IMAGE,
            message: `Failed to load an image with given image path : ${imagePath}`,
          });
        }

        return image;
      }
    },
  dangerouslyAllowMutability: true,
});

export const imageState = Object.freeze({
  single,
});
