import { ChangeEvent, useCallback, useMemo, useState } from 'react';

import groupBy from 'lodash-es/groupBy';
import { useDrop } from 'react-dnd';
import { useRecoilState, useRecoilValue } from 'recoil';

import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import TextFilterInput from 'src/components/Input/TextFilterInput';
import Spinner from 'src/components/Spinner';
import useCheckedParams from 'src/hooks/useCheckedParams';
import useFilteredAssetTemplates from 'src/hooks/useFilteredAssetTemplates';
import AlertPanel from 'src/pages/NewProject/AlertPanel';
import {
  DndLayout,
  DndLayoutContent,
  DndLayoutContentBody,
  DndLayoutContentHeader,
} from 'src/pages/NewProject/DndLayout';
import DropPanel from 'src/pages/NewProject/DropPanel';
import { newProjectState } from 'src/states/newProject';
import { Modality } from 'src/types/api/data/image';
import { ClaimAssetCreateSchema } from 'src/types/api/data/project';
import {
  ASSET_DROP_TYPE,
  ASSET_ITEM_TYPE,
  AssetDropResult,
  ClientClaimAssetCreateSchema,
  HandleChangeClaimAssets,
} from 'src/types/client/asset';

import ConfiguredList from './ConfiguredList';
import TemplateList from './TemplateList';

const Assets = (): JSX.Element => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ASSET_ITEM_TYPE.TEMPLATE,
    drop: (): AssetDropResult => ({ type: ASSET_DROP_TYPE.ADDING }),
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
  }));

  const isValidAssets = useRecoilValue(newProjectState.isValidAssets);
  const [{ assets }, setClaim] = useRecoilState(newProjectState.claim);
  const { modalityLabel } = useCheckedParams<{
    modalityLabel: Modality;
  }>(['modalityLabel']);

  const [filterBy, setFilterBy] = useState('');
  const { filteredAssetTemplates, isLoading } = useFilteredAssetTemplates(
    filterBy,
    modalityLabel
  );

  const clientAssets = useMemo<ClientClaimAssetCreateSchema[]>(() => {
    const mapToClientClaimAssetCreateSchema = (
      assets: ClaimAssetCreateSchema[]
    ): ClientClaimAssetCreateSchema[] => {
      const groupedAssets = groupBy(assets, asset => asset.parent?.id);
      const parentAssets = groupedAssets['undefined'];

      return (parentAssets || []).map(asset => {
        return {
          ...asset,
          children: groupedAssets[asset.id] || [],
        };
      });
    };

    return mapToClientClaimAssetCreateSchema(assets);
  }, [assets]);

  const handleChangeClaimAssets = useCallback<HandleChangeClaimAssets>(
    (callback): void => {
      const mapToClaimAssetCreateSchema = (
        assets: ClientClaimAssetCreateSchema[]
      ): ClaimAssetCreateSchema[] => {
        const childrenAssets = assets
          .flatMap(({ children }) => children)
          .filter((asset): asset is ClaimAssetCreateSchema => !!asset);

        // Remove children field which is only defined for the ClientClaimAssetCreateSchema
        const normalAssets = assets.map(({ children, ...rest }) => rest);

        return [...normalAssets, ...childrenAssets];
      };

      setClaim(prev => ({
        ...prev,
        assets: mapToClaimAssetCreateSchema(callback(clientAssets)),
      }));
    },
    [clientAssets, setClaim]
  );

  const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFilterBy(event.target.value);
  };

  return (
    <>
      <AlertPanel isValid={isValidAssets.isValid}>
        <ul>
          <li>Project requires at least one asset.</li>
          <li>Configured assets must have unique names.</li>
        </ul>
      </AlertPanel>
      <AlertPanel>
        <AlertTitle>Rules for setting child assets.</AlertTitle>
        <ul>
          <li>
            Child assets cannot have their own child assets. (No grandchild
            assets allowed).
          </li>
          <li>"decision" group assets cannot have child assets.</li>
          <li>"texter" form assets cannot have child assets.</li>
          <li>Only assets of the same group can be related.</li>
        </ul>
      </AlertPanel>

      <Container>
        <DndLayout>
          <Typography variant="subtitle1" gutterBottom>
            Asset Templates
          </Typography>
          <DndLayoutContent>
            <DndLayoutContentHeader>
              <TextFilterInput
                fullWidth
                filterBy={filterBy}
                onChange={handleFilterChange}
                error={!isLoading && filteredAssetTemplates.length === 0}
              />
            </DndLayoutContentHeader>
            <DndLayoutContentBody>
              {isLoading ? (
                <Container style={{ alignSelf: 'center' }}>
                  <Spinner size={60} />
                </Container>
              ) : (
                <TemplateList
                  setItems={handleChangeClaimAssets}
                  filteredAssetTemplates={filteredAssetTemplates}
                />
              )}
            </DndLayoutContentBody>
          </DndLayoutContent>
        </DndLayout>
        <DndLayout>
          <Typography variant="subtitle1" gutterBottom>
            Configured Assets
          </Typography>
          <DndLayoutContent>
            <DndLayoutContentHeader>
              <DropPanel ref={drop} $isHover={isOver}>
                DROP HERE TO APPEND
              </DropPanel>
            </DndLayoutContentHeader>
            <DndLayoutContentBody>
              <ConfiguredList
                items={clientAssets}
                setItems={handleChangeClaimAssets}
              />
            </DndLayoutContentBody>
          </DndLayoutContent>
        </DndLayout>
      </Container>
    </>
  );
};

export default Assets;

const Container = styled('div')`
  display: flex;
  gap: 1rem;
`;
