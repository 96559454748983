import { AxiosResponse } from 'axios';

import { Modality } from 'src/types/api/data/image';
import { JobReadSchema, JobType } from 'src/types/api/data/job';
import {
  ProjectCreateSchema,
  ProjectListSchema,
  ProjectReadSchema,
} from 'src/types/api/data/project';
import { ClientProject, ClientProjectList } from 'src/types/client/project';
import { Annotator, Reviewer, User } from 'src/types/client/user';
import { ClientErrorCode, ClientError } from 'src/utils/clientError';
import { dataApi } from 'src/utils/http';

import { getUserInfo } from './account';
import { getJobStats, getProjectJobStats } from './stats';

export const DEFAULT_STAT = {
  total: 0,
  createdStage: 0,
  assignedStage: 0,
  queuedStage: 0,
  completedStage: 0,
  reportedStage: 0,
};

export const getProject = async (
  projectId: string,
  modality: Modality
): Promise<ClientProject> => {
  try {
    const { data: project } = await dataApi.get<ProjectReadSchema>(
      `/projects/${projectId}/`,
      {
        params: {
          all: true,
          modality: modality.toLowerCase(),
        },
      }
    );

    const jobStats = await getProjectJobStats({
      projectId: project.id,
      modality,
    });

    return {
      ...project,
      jobStats,
    };
  } catch (error) {
    throw new ClientError({
      code: ClientErrorCode.UNEXPECTED,
      message: (error as ClientError).message,
    });
  }
};

export type ProjectListProps = {
  modality: Modality;
  page: number;
  pageSize: number;
  isAdmin: boolean;
  sort: string;
};

export const getProjectList = async ({
  modality,
  page,
  pageSize,
  isAdmin,
  sort,
}: ProjectListProps): Promise<ClientProjectList> => {
  // Only the admin user is allowed
  // For non-admin user, list the project that he is included
  const { data: projectListInfo } = await dataApi.get<ProjectListSchema>(
    `/projects/`,
    {
      params: {
        all: isAdmin,
        page,
        page_size: pageSize,
        sort,
        modality: modality.toLowerCase(),
      },
    }
  );
  const { projects, ...listInfo } = projectListInfo;
  const statResponses = await Promise.all(
    projects.map(async project => {
      const jobStats = await getProjectJobStats({
        projectId: project.id,
        modality,
      });
      return {
        ...project,
        jobStats,
      };
    })
  );
  return { ...listInfo, projects: statResponses };
};

export const createProject = async (
  payload: ProjectCreateSchema,
  modality: Modality
): Promise<ProjectReadSchema> => {
  const { data } = await dataApi.post<
    ProjectReadSchema,
    AxiosResponse<ProjectReadSchema>,
    ProjectCreateSchema
  >(`/projects/`, payload, {
    params: {
      modality: modality.toLowerCase(),
    },
  });

  return data;
};

export const addAnnotator = async (
  projectId: string,
  annotatorIdList: string[],
  modality: Modality
): Promise<JobReadSchema> => {
  const { data } = await dataApi.patch<JobReadSchema>(
    `/projects/${projectId}/users/`,
    annotatorIdList,
    {
      params: {
        modality: modality.toLowerCase(),
      },
    }
  );
  return data;
};

export const getAnnotatorList = async (
  projectId: string,
  modality: Modality
): Promise<Annotator[]> => {
  const { annotators } = await getProject(projectId, modality);
  const annotatorList = await Promise.all(
    annotators.map(async stat => {
      const [annotatorInfo, annotationStat, corroborationStat] =
        await Promise.all([
          getUserInfo(stat.id),
          getJobStats({
            projectId,
            annotatorId: stat.id,
            type: 'annotation',
            modality,
          }),
          getJobStats({
            projectId,
            annotatorId: stat.id,
            type: 'corroboration',
            modality,
          }),
        ]);

      return {
        ...annotatorInfo,
        jobStat: annotationStat.user ?? DEFAULT_STAT,
        corroborationStat: corroborationStat.user ?? DEFAULT_STAT,
      };
      //
    })
  );

  return annotatorList;
};

type AddCaseWithQueryProps = {
  projectId: string;
  modality: string;
  type: JobType;
  numOfCrossJobs?: number;
  caseIds: string[];
};

export const addCasesWithQuery = async ({
  projectId,
  type,
  caseIds,
  modality,
  numOfCrossJobs,
}: AddCaseWithQueryProps): Promise<JobReadSchema[]> => {
  const payload = {
    operation: 'CreateJobs',
    data: {
      caseIds,
      jobType: type,
      numOfCrossJobs,
    },
  };

  const { data } = await dataApi.post(
    `v2/bulk/projects/${projectId}/jobs/`,
    payload,
    {
      params: {
        modality: modality.toLowerCase(),
      },
    }
  );

  return data;
};

type AddCaseWithCsvProps = {
  projectId: string;
  modality: Modality;
  type: JobType;
  numOfCrossJobs?: number;
  caseIds: string[];
  pairCaseIds: string[];
};

export const addCPCCasesWithCsv = async ({
  projectId,
  type,
  modality,
  numOfCrossJobs,
  caseIds,
  pairCaseIds,
}: AddCaseWithCsvProps): Promise<JobReadSchema[]> => {
  const payload = {
    operation: 'CreatePairJobs',
    data: {
      caseIds,
      pairCaseIds,
      jobType: type,
      numOfCrossJobs: numOfCrossJobs,
    },
  };

  const { data } = await dataApi.post(
    `v2/bulk/projects/${projectId}/jobs/`,
    payload,
    {
      params: {
        modality: modality.toLowerCase(),
      },
    }
  );

  return data;
};

export const confirmProject = async (
  projectId: string,
  modality: Modality,
  allowSkipped?: boolean
): Promise<boolean> => {
  try {
    await dataApi.patch(
      `/projects/${projectId}/confirm/`,
      {},
      {
        params: {
          allow_skipped: allowSkipped,
          modality: modality.toLowerCase(),
        },
      }
    );
    return true;
  } catch (error) {
    return false;
  }
};

export const getReviewerList = async (
  projectId: string,
  modality: Modality
): Promise<Reviewer[]> => {
  const { reviewers } = await getProject(projectId, modality);
  const reviewerList = await Promise.all(
    reviewers.map(async ({ id, associates }) => {
      const reviewerInfo = await getUserInfo(id);
      return {
        ...reviewerInfo,
        associates,
      };
    })
  );

  return reviewerList;
};

export const getAssociateList = async (idList: string[]): Promise<User[]> => {
  return Promise.all(idList.map(async id => getUserInfo(id)));
};

type ReviewerInfo = {
  id: string;
  associates: string[];
};

export const updateReviewers = async (
  projectId: string,
  modality: Modality,
  reviewerList: ReviewerInfo[]
): Promise<ProjectReadSchema> => {
  return dataApi.put(`/projects/${projectId}/reviewers/`, reviewerList, {
    params: {
      modality: modality.toLowerCase(),
    },
  });
};

export const deleteReviewer = async (
  projectId: string,
  modality: Modality,
  reviewerId: string
): Promise<ProjectReadSchema> => {
  return dataApi.delete(`/projects/${projectId}/reviewers/`, {
    data: [reviewerId],
    params: {
      modality: modality.toLowerCase(),
    },
  });
};

export const purgeProject = async (
  projectId: string,
  modality: Modality,
  annotatorId: string
): Promise<boolean> => {
  return dataApi.delete(
    `/projects/${projectId}/user/${annotatorId}/annotations/`,
    { params: { modality: modality.toLowerCase() } }
  );
};

export type ProjectClonePayload = {
  name: string;
  claim: boolean;
  annotator: boolean;
  job: boolean;
  reading: boolean;
  default_claim?: boolean;
};

export const cloneProject = async (
  projectId: string,
  modality: Modality,
  payload: ProjectClonePayload
): Promise<boolean> => {
  const { data } = await dataApi.post(`/projects/${projectId}/clone`, payload, {
    params: {
      modality: modality.toLowerCase(),
    },
  });
  return data;
};
