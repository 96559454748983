import { ComponentType, PropsWithChildren } from 'react';

import { FallbackProps } from 'react-error-boundary';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import { logout } from 'src/api';
import { ClientErrorCode, ClientError } from 'src/utils/clientError';

import ErrorPanel from './ErrorPanel';

export const GlobalErrorFallback: ComponentType<
  PropsWithChildren<FallbackProps>
> = ({ error }) => {
  // TODO: Make an error log

  const clientError = error as ClientError;

  return (
    <Container>
      <ErrorPanel title={error.name} subTitle2={error.message}>
        {[
          ClientErrorCode.UNAUTHORIZED,
          ClientErrorCode.UNAUTHENTICATED,
        ].includes(clientError?.code) ? (
          <Button
            variant="contained"
            color="primary"
            aria-label="logout"
            onClick={logout}
          >
            Go back to login page
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            aria-label="home"
            href="/"
          >
            Go back to home
          </Button>
        )}
      </ErrorPanel>
    </Container>
  );
};

const Container = styled('div')`
  padding: 3rem;
  margin: auto;
`;
