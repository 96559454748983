import { Dispatch, SetStateAction, useDeferredValue, useState } from 'react';

function useDeferredState<T>(
  initialValue: T
): [deferredValue: T, setValue: Dispatch<SetStateAction<T>>, value: T] {
  const [value, setValue] = useState(initialValue);
  const deferredValue = useDeferredValue(value);
  return [deferredValue, setValue, value];
}

export default useDeferredState;
