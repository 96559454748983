import { ErrorPageContents } from 'src/components';

export default function NotFound(): JSX.Element {
  return (
    <ErrorPageContents
      title="404 Not Found"
      description={`This page doesn't exist.`}
    />
  );
}
