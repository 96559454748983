export enum PathNames {
  HOME = 'HOME',
  LOGIN = 'LOGIN',
  USERS = 'USERS',
  PROJECTS = 'PROJECTS',
  PROJECT = 'PROJECT',
  PROJECT_TAB = 'PROJECT TAB',
  NEW_PROJECT = 'NEW PROJECT',
  CASE_SEARCH = 'CASE SEARCH',
  AI_OPERATIONAL = 'AI OPERATIONAL',
  REPORTS = 'REPORTS',
  ASSET_TEMPLATES = 'ASSET TEMPLATES',
  NEW_ASSET_TEMPLATE = 'NEW ASSET TEMPLATE',
  MANAGE_ASSET_TEMPLATE = 'MANAGE ASSET TEMPLATE',
}
