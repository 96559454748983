import { MouseEventHandler } from 'react';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const ErrorContentsWrapper = styled('div')`
  color: white;
`;

const ErrorTitle = styled('h2')`
  color: white;
`;

const ErrorDescription = styled('p')`
  color: white;
`;

interface ErrorPageContentsProps {
  title: string;
  description: string;
  buttonText?: string;
  disabled?: boolean;
  onButtonClick?: MouseEventHandler<
    HTMLDivElement | HTMLAnchorElement | HTMLButtonElement | HTMLSpanElement
  >;
}

export default function ErrorPageContents({
  title,
  description,
  buttonText,
  onButtonClick,
  disabled = false,
}: ErrorPageContentsProps): JSX.Element {
  return (
    <ErrorContentsWrapper>
      <ErrorTitle>{title}</ErrorTitle>
      <ErrorDescription>{description}</ErrorDescription>
      {buttonText && (
        <Button onClick={onButtonClick} disabled={disabled}>
          {buttonText}
        </Button>
      )}
    </ErrorContentsWrapper>
  );
}
