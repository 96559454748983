/* eslint-disable @typescript-eslint/no-var-requires */

export enum Host {
  CTL_AUTH_SERVER = 'CTL_AUTH_SERVER',
  CTL_DATA_SERVER = 'CTL_DATA_SERVER',
  CTL_IMAGE_SERVER = 'CTL_IMAGE_SERVER',
}

export type Hosts = Record<Host, string>;

export const HOSTS: Hosts = require(
  `./${process.env.REACT_APP_DEPLOYMENT_PHASE}.ts`
).default;
