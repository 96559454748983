import {
  ClaimAssetIdentifierSchema,
  ClaimAssetValidationSchema,
} from '@lunit-io/ctl-api-interface';

const INITIAL_ASSET_VALIDATION_VALUE: ClaimAssetIdentifierSchema = {
  name: '',
  group: 'decision',
  text: '',
  selectedValues: undefined,
};

function getInitialAssetValidation(key: 'ifSet'): ClaimAssetIdentifierSchema;
function getInitialAssetValidation(key: 'oneOf'): ClaimAssetIdentifierSchema[];
function getInitialAssetValidation(
  key: keyof ClaimAssetValidationSchema
): ClaimAssetIdentifierSchema | ClaimAssetIdentifierSchema[] | undefined {
  switch (key) {
    case 'ifSet': {
      return INITIAL_ASSET_VALIDATION_VALUE;
    }
    case 'oneOf': {
      return [];
    }
    default:
      return undefined;
  }
}

export const INITIAL_ASSET_VALIDATION: ClaimAssetValidationSchema = {
  ifSet: getInitialAssetValidation('ifSet'),
  oneOf: getInitialAssetValidation('oneOf'),
};
