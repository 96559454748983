/**
 * This logic is copied and pasted from CTL's one.
 * This is the one to be reused after applying monorepo system between CTL and CTL Office.
 *
 * @see https://github.com/lunit-io/radiology-annotation-frontend/blob/e7fa2043cd47712fc679304731387e84ec159b6b/src/pages/workspace/task/ControlPanel/useControlButton.tsx#L34-L67
 * @param name string
 * @returns string
 */
const getControlLabel = (name: string): string => {
  switch (name) {
    case 'point':
      return 'Draw Point';
    case 'line':
      return 'Draw Line';
    case 'polygon':
      return 'Draw Polygon';
    case 'boundingBox':
      return 'Draw Bounding Box';
    case 'multiFramePolygon':
      return 'Draw Multi Frame Polygon';
    case 'navigateFrames':
      return 'Navigate Frames';
    case 'pan':
      return 'Pan';
    case 'brightness':
      return 'Adjust';
    case 'zoom':
      return 'Zoom';
    case 'toggleMonitor':
      return 'Monitor';
    case 'toggleAxis':
      return 'Axis';
    case 'flip':
      return 'Flip';
    case 'invert':
      return 'Invert';
    case 'fitToWindow':
      return 'Fit to Window';
    case 'reset':
      return 'Reset';
    default:
      return 'Error';
  }
};

export default getControlLabel;
