import { ReactNode } from 'react';

import { AlertColor } from '@mui/material/Alert';

import { Modality } from 'src/types/api/data/image';

export const TRANSITION_DURATION = 200;

export interface AlertSnackbarState {
  visibility?: boolean;
  severity: AlertColor;
  description: string;
}

export interface ConfirmModalState {
  title: string;
  description: string;
  visibility?: boolean;
  children?: ReactNode;
  confirmBtnTestId?: string;
}

export interface SelectOption<V extends string = string> {
  value: V;
  label: string;
  disabled?: boolean;
}
export interface ModalitySelectOption {
  value: Modality;
  label: Modality;
  disabled?: boolean;
}

export interface Size {
  width: number;
  height: number;
}
