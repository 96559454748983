import { PropsWithChildren } from 'react';

import { ImageStoreProvider, ProgressCollector } from '@lunit/insight-viewer';
import { MutableSnapshot, RecoilRoot } from 'recoil';

import { AlertSnackbarProvider } from './components/Context/AlertSnackbarProvider';
import { ConfirmModalProvider } from './components/Context/ConfirmModalProvider';
import { ThemeProvider } from './components/Context/ThemeProvider';

type CombinedProviderProps = {
  initializeRecoilState?: (mutableSnapshot: MutableSnapshot) => void;
};

export function CombinedProviders({
  children,
  initializeRecoilState,
}: PropsWithChildren<CombinedProviderProps>): JSX.Element {
  return (
    <RecoilRoot initializeState={initializeRecoilState}>
      <ThemeProvider>
        <ImageStoreProvider>
          <ProgressCollector>
            <ConfirmModalProvider>
              <AlertSnackbarProvider>{children}</AlertSnackbarProvider>
            </ConfirmModalProvider>
          </ProgressCollector>
        </ImageStoreProvider>
      </ThemeProvider>
    </RecoilRoot>
  );
}
