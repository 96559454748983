import { MouseEventHandler, useEffect, useMemo, useState } from 'react';

import useResizeObserver from 'use-resize-observer';

import Done from '@mui/icons-material/Done';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

import LoadableViewer from 'src/components/ListView/LoadableViewer';
import { ContoursByShape } from 'src/states/annotation';
import { ReducedCaseReadSchema } from 'src/types/api/data/case';
import { Modality } from 'src/types/api/data/image';

interface Props {
  modalityLabel?: string;
  images?: ReducedCaseReadSchema['images'];
  contoursByShape?: ContoursByShape;
}

const ModalityViewer = ({
  modalityLabel,
  images,
  contoursByShape,
}: Props): JSX.Element => {
  const { ref, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>();

  const viewTypes = useMemo(() => {
    if (!images) {
      return [];
    }
    return Object.keys(images).filter(viewType => !!images[viewType]);
  }, [images]);

  const [currentViewType, setCurrentViewType] = useState('');

  useEffect(() => {
    if (!!viewTypes[0]) {
      setCurrentViewType(viewTypes[0]);
    } else {
      setCurrentViewType('');
    }
  }, [viewTypes]);

  const handleChangeCurrentViewType: MouseEventHandler<
    HTMLDivElement
  > = event => {
    const value = event.currentTarget.getAttribute('data-value');

    if (!value) {
      return;
    }

    setCurrentViewType(value);
  };

  const viewer = useMemo(() => {
    const imageSchema = images?.[currentViewType];

    switch (modalityLabel) {
      case Modality.CXR:
      case Modality.MMG:
        return (
          <LoadableViewer
            key={currentViewType}
            view={currentViewType}
            imagePath={
              imageSchema?.type === 'single' ? imageSchema.path : undefined
            }
            contoursByShape={contoursByShape}
            width={width}
            height={height}
          />
        );
      case Modality.CCT:
      case Modality.DBT:
      default:
        return <div>Not supported</div>;
    }
  }, [contoursByShape, currentViewType, height, images, modalityLabel, width]);

  return (
    <Container>
      <ChipsContainer>
        {viewTypes.length > 0 &&
          viewTypes.map(viewType => (
            <Chip
              key={viewType}
              label={viewType}
              data-value={viewType}
              size="small"
              variant={currentViewType === viewType ? 'filled' : 'outlined'}
              clickable={viewType.length >= 2}
              icon={
                currentViewType === viewType ? (
                  <Done fontSize="small" />
                ) : undefined
              }
              onClick={handleChangeCurrentViewType}
            />
          ))}
      </ChipsContainer>
      <ViewerContainer>
        <Square ref={ref}>{viewer}</Square>
      </ViewerContainer>
    </Container>
  );
};

export default ModalityViewer;

const Container = styled('div')``;

const ChipsContainer = styled('div')`
  display: flex;
  gap: 0.25rem;
  margin-bottom: 0.5rem;
`;

const ViewerContainer = styled('div')`
  position: relative;
  padding-top: 100%;
`;

const Square = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
