import { AxiosResponse } from 'axios';

import {
  AssetTemplateCreateSchema,
  AssetTemplateReadSchema,
  AssetTemplateUpdateSchema,
} from 'src/types/api/data/assetTemplate';
import { Modality } from 'src/types/api/data/image';
import { dataApi } from 'src/utils/http';

export const getAssetTemplates = async (
  modality: Modality
): Promise<AssetTemplateReadSchema[]> => {
  const { data: assetTemplates } = await dataApi.get<AssetTemplateReadSchema[]>(
    `/asset-templates/`,
    {
      params: {
        modality: modality.toLowerCase(),
      },
    }
  );

  return assetTemplates;
};

export const getAssetTemplate = async (
  assetTemplateId: string,
  modality: Modality
): Promise<AssetTemplateReadSchema> => {
  const { data: assetTemplate } = await dataApi.get<AssetTemplateReadSchema>(
    `/asset-templates/${assetTemplateId}`,
    {
      params: {
        assetTemplateId,
        modality: modality.toLowerCase(),
      },
    }
  );

  return assetTemplate;
};

export const createAssetTemplate = async (
  payload: AssetTemplateCreateSchema,
  modality: Modality
): Promise<AssetTemplateReadSchema> => {
  const { data } = await dataApi.post<
    AssetTemplateReadSchema,
    AxiosResponse<AssetTemplateReadSchema>,
    AssetTemplateCreateSchema
  >(`/asset-templates/`, payload, {
    params: {
      modality: modality.toLowerCase(),
    },
  });
  return data;
};

export const updateAssetTemplate = async (
  id: string,
  payload: AssetTemplateUpdateSchema,
  modality: Modality
): Promise<AssetTemplateReadSchema> => {
  const { data } = await dataApi.put<
    AssetTemplateReadSchema,
    AxiosResponse<AssetTemplateReadSchema>,
    AssetTemplateUpdateSchema
  >(`/asset-templates/${id}`, payload, {
    params: {
      modality: modality.toLowerCase(),
    },
  });
  return data;
};
