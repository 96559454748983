import { useEffect, useState } from 'react';

import debounce from 'lodash-es/debounce';
import AceEditor from 'react-ace';
import { useRecoilState } from 'recoil';

import { styled } from '@mui/material/styles';

import AlertPanel from 'src/pages/NewProject/AlertPanel';
import { newProjectState } from 'src/states/newProject';

import 'brace';
import 'brace/mode/json';

const Overrides = (): JSX.Element => {
  const [claim, setClaim] = useRecoilState(newProjectState.claim);

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(JSON.stringify(claim.overrides, null, 2));
  }, [claim.overrides]);

  const handleChange = debounce((newValue: string) => {
    setValue(newValue);

    try {
      setClaim(prev => ({
        ...prev,
        overrides: JSON.parse(newValue),
      }));
      setErrorMessage('');
    } catch (error) {
      setErrorMessage((error as Error)?.message || '');
    }
  }, 500);

  return (
    <>
      <AlertPanel isValid={!errorMessage}>
        <ul>
          <li>The entered value must be a valid JSON object.</li>
          <li>Invalid values will not be saved until resolved.</li>
        </ul>
      </AlertPanel>

      <EditorWrap $hasError={!!errorMessage}>
        <AceEditor
          width="100%"
          height="400px"
          mode="json"
          value={value}
          setOptions={{
            printMargin: false,
            showGutter: true,
            useWorker: false,
          }}
          onChange={handleChange}
        />
        <MessageWrap>
          {errorMessage ? (
            <>
              <ErrorMessage>{errorMessage}</ErrorMessage>
            </>
          ) : (
            'Changes are saved successfully.'
          )}
        </MessageWrap>
      </EditorWrap>
    </>
  );
};

export default Overrides;

const EditorWrap = styled('div')<{ $hasError: boolean }>(
  ({ $hasError, theme }) => `
  border-width: ${theme.spacing(0.5)} ${theme.spacing(0.5)} 0;
  border-style: solid;
  border-color: ${
    $hasError ? theme.palette.error.dark : theme.palette.success.dark
  };
  background-color: ${
    $hasError ? theme.palette.error.dark : theme.palette.success.dark
  };
  border-radius: ${theme.spacing(0.5)};
`
);

const ErrorMessage = styled('div')(
  ({ theme }) => `
  display: block;
`
);

const MessageWrap = styled('div')(
  ({ theme }) => `
  padding: ${theme.spacing(1.5)};
  color: ${theme.custom.textActive};
`
);
