import { CSSProperties, PropsWithChildren, useEffect } from 'react';

import {
  Row,
  TableOptions,
  useTable,
  TableCellProps,
  useRowSelect,
  CellProps,
} from 'react-table';

import { PaginationProps } from '@mui/material/Pagination';
import Paper from '@mui/material/Paper';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import StyledPagination from 'src/components/StyledPagination';
import IndeterminateCheckbox from 'src/components/Table/IndeterminateCheckbox';

import TransitionSpinner from './TransitionSpinner';

interface TransitionTableProps<T extends Record<string, unknown>>
  extends TableOptions<T> {
  onClickRow?: (row: Row<T>) => void;
  sortBy?: string | null;
  isPending?: boolean;
  paginationProps?: PaginationProps;
  onRowsSelected?: (selectedRows: T[]) => void;
}

export default function TransitionTable<T extends Record<string, unknown>>({
  columns,
  data,
  onClickRow,
  sortBy = '',
  isPending,
  paginationProps,
  onRowsSelected,
  style,
}: PropsWithChildren<TransitionTableProps<T>>): JSX.Element {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
  } = useTable<T>(
    {
      columns,
      data: data || [],
      autoResetSelectedRows: false,
    },
    useRowSelect,
    hooks => {
      onRowsSelected &&
        hooks.visibleColumns.push(columns => [
          // make a column for selection and add it into the initial position of columns
          {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }: CellProps<T>) => (
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns,
        ]);
    }
  );

  useEffect(() => {
    if (onRowsSelected) {
      onRowsSelected(selectedFlatRows.map(d => d.original));
    }
  }, [onRowsSelected, selectedFlatRows]);

  return (
    <TableViewStyles $isRowClickable={!!onClickRow} style={style}>
      <table {...getTableProps} data-test-id="transitionTable">
        <thead>
          {headerGroups.map(headerGroup => {
            return (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => {
                  const sorted = Boolean(
                    sortBy && column.id === sortBy?.slice(1)
                  );
                  const canSort = !!column.onClickHeader;
                  return (
                    <TableHeadCell
                      {...column.getHeaderProps([{ style: column.style }])}
                    >
                      {canSort ? (
                        <TableSortLabel
                          active={sorted}
                          direction={
                            sorted && sortBy?.charAt(0) === '-' ? 'desc' : 'asc'
                          }
                          onClick={column.onClickHeader}
                        >
                          {column.render('Header')}
                        </TableSortLabel>
                      ) : (
                        <div>{column.render('Header')}</div>
                      )}
                    </TableHeadCell>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} onClick={() => onClickRow?.(row)}>
                {row.cells.map(cell => {
                  return (
                    <td
                      {...cell.getCellProps([
                        {
                          style: cell.column.style,
                        },
                      ])}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {data.length === 0 && (
        <StyledPaper>
          <Typography variant="h5">There are no data</Typography>
        </StyledPaper>
      )}
      {data.length !== 0 && paginationProps && (
        <FooterDiv>
          <StyledPagination {...paginationProps} />
        </FooterDiv>
      )}
      {isPending && <TransitionSpinner />}
    </TableViewStyles>
  );
}

const TableViewStyles = styled('div')<{ $isRowClickable: boolean }>(
  ({ theme, $isRowClickable }) => ({
    position: 'relative',
    marginTop: '2rem',
    table: {
      borderSpacing: 0,
      width: '100%',
      color: theme.custom.textActive,
    },
    tbody: {
      tr: {
        cursor: $isRowClickable ? 'pointer' : 'default',
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, .1)',
        },
      },
    },
    th: {
      textAlign: 'left',
      padding: 8,
      borderBottom: `1px solid ${theme.custom.text}`,
    },
    td: {
      margin: 0,
      padding: 8,
      borderBottom: `1px solid ${theme.custom.text}`,
    },
  })
);

const StyledPaper = styled(Paper)`
  height: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const FooterDiv = styled('div', { name: 'TableFooter' })(({ theme }) => ({
  display: 'flex',
  marginTop: '1rem',
  alignItems: 'center',
  justifyContent: 'center',
}));

type CN = { className?: string; style?: CSSProperties };

const TableHeadCell: React.FC<
  PropsWithChildren<Partial<TableCellProps> & CN>
> = ({ children, className, ...rest }) => {
  return <TableCell {...rest}>{children}</TableCell>;
};
