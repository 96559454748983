import { useMemo } from 'react';

import { SelectorAttributes } from '@lunit-io/ctl-api-interface';

import { ClaimAssetCreateSchema } from 'src/types/api/data/project';
import { SelectOption } from 'src/types/client/ui';

interface Return {
  options: SelectOption[];
  allowMultiSelect: boolean;
}

const useSelectedValueOptions = (asset?: ClaimAssetCreateSchema): Return => {
  const options = useMemo<SelectOption[]>(() => {
    if (!asset) {
      return [];
    }

    switch (asset.form) {
      case 'toggler': {
        return [
          { label: 'true', value: 'true' },
          { label: 'false', value: 'false' },
        ];
      }
      case 'selector': {
        const selectorFormAttributes =
          asset.formAttributes as SelectorAttributes;
        return Object.values(selectorFormAttributes.categories).map(
          ({ name }) => ({
            label: name,
            value: name,
          })
        );
      }
      // It doesn't support texter form asset.
      // Because the value of texter form asset is not expectable.
      case 'texter':
      default: {
        return [];
      }
    }
  }, [asset]);

  const allowMultiSelect = useMemo<boolean>(() => {
    if (!asset || !asset.formAttributes || asset.form !== 'selector') {
      return false;
    }
    return (asset.formAttributes as SelectorAttributes).allowMultiSelect;
  }, [asset]);

  return {
    options,
    allowMultiSelect,
  };
};

export default useSelectedValueOptions;
