import { Context, createContext, ReactNode, useState } from 'react';

import AlertSnackbar from 'src/components/Modal/AlertSnackbar';
import { AlertSnackbarState, TRANSITION_DURATION } from 'src/types/client/ui';

const DEFAULT: AlertSnackbarState = {
  severity: 'success',
  description: '',
  visibility: false,
};

interface Props {
  children: ReactNode;
}

interface ContextState {
  open: (state: AlertSnackbarState) => void;
}

export const AlertSnackbarContext: Context<ContextState> =
  createContext<ContextState>({
    open: () => {},
  });

export function AlertSnackbarProvider({ children }: Props): JSX.Element {
  const [state, setState] = useState<AlertSnackbarState>(DEFAULT);

  const reset = () => {
    setState(DEFAULT);
  };

  const open = (state: AlertSnackbarState) => {
    setState({
      ...DEFAULT,
      ...state,
      visibility: true,
    });
  };

  const close = () => {
    setState(prev => ({
      ...prev,
      visibility: false,
    }));
    setTimeout(reset, TRANSITION_DURATION);
  };

  const handleDismiss = () => {
    close();
  };

  return (
    <AlertSnackbarContext.Provider value={{ open }}>
      <AlertSnackbar
        visibility={state.visibility}
        severity={state.severity}
        description={state.description}
        onDismiss={handleDismiss}
      />
      {children}
    </AlertSnackbarContext.Provider>
  );
}
