import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';

export default styled(MenuItem)({
  root: {
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
});
