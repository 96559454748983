/* eslint-disable no-template-curly-in-string */

import { INITIAL_CLAIM } from 'src/configs/claims';
import {
  CLAIM_CONTROLS,
  CLAIM_CONTROL_POLYGON,
} from 'src/configs/claims/controls';
import { ClaimCreateSchema } from 'src/types/api/data/project';

const CXRClaim: ClaimCreateSchema = {
  ...INITIAL_CLAIM,
  controls: [CLAIM_CONTROL_POLYGON, ...CLAIM_CONTROLS],
  viewer: {
    type: 'xray',
    images: [
      {
        name: 'frontal',
        id: 'frontal._id',
      },
      {
        name: 'lateral',
        id: 'lateral._id',
      },
    ],
  },
};

export default CXRClaim;
