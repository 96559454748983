import { Modality } from 'src/types/api/data/image';
import { ClaimAnnotationResponse } from 'src/types/client/asset';
import { dataApi } from 'src/utils/http';

export type GetAnnotationProps = {
  jobId: string;
  modality: Modality;
};

// Get jobs of project
export const getAnnotation = async ({
  jobId,
  modality,
}: GetAnnotationProps): Promise<ClaimAnnotationResponse> => {
  const { data } = await dataApi.get<ClaimAnnotationResponse>(
    `/jobs/${jobId}/annotation/`,
    {
      params: {
        modality: modality.toLowerCase(),
      },
    }
  );

  return data;
};
