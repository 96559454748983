import { StrictMode, Suspense } from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { styled } from '@mui/material/styles';

import useImageLoaderScripts from 'src/hooks/useImageLoaderScripts';

import { CombinedProviders } from './CombinedProvider';
import PrivateRoute from './PrivateRoute';
import { GlobalErrorFallback } from './components/ErrorFallback/GlobalErrorFallback';
import RouteErrorFallback from './components/ErrorFallback/RouteErrorFallback';
import {
  AI,
  AssetTemplates,
  CaseSearch,
  Login,
  Main,
  ManageProject,
  NewAssetTemplate,
  NotFound,
  Projects,
  Reports,
  Users,
} from './pages';
import ManageAssetTemplate from './pages/ManageAssetTemplate';
import NewProject from './pages/NewProject';
import { useRefreshReportList } from './states/reportList';
import { useRefreshUserList } from './states/user';
import { PathNames } from './types/client/url';
import {
  ASSET_TEMPLATES_PAGE_TITLE,
  MANAGE_ASSET_TEMPLATE_TITLE,
  PROJECTS_PAGE_TITLE,
} from './utils/constants';
import { UrlUtil } from './utils/url';

const FlexWrapper = styled('div')`
  display: flex;
  height: 100%;
`;

function App(): JSX.Element {
  const refreshUserList = useRefreshUserList();
  const refreshReportList = useRefreshReportList();

  useImageLoaderScripts();

  const router = createBrowserRouter([
    { path: UrlUtil.getUrl(PathNames.LOGIN), element: <Login /> },
    {
      path: '/',
      element: <PrivateRoute />,
      children: [
        { path: '/', element: <Main /> },
        {
          path: `${UrlUtil.getUrl(PathNames.USERS)}`,
          element: (
            <ErrorBoundary
              key="UserList"
              fallbackRender={props => (
                <RouteErrorFallback pageName="User List" {...props} />
              )}
              onReset={() => {
                refreshUserList();
              }}
            >
              <Users />
            </ErrorBoundary>
          ),
        },
        {
          path: UrlUtil.getUrl(PathNames.PROJECTS),
          element: (
            <ErrorBoundary
              key="ProjectList"
              fallbackRender={props => (
                <RouteErrorFallback pageName={PROJECTS_PAGE_TITLE} {...props} />
              )}
            >
              <Projects />
            </ErrorBoundary>
          ),
        },
        {
          path: UrlUtil.getUrl(PathNames.PROJECT),
          element: (
            <ErrorBoundary
              key="ManageProject"
              fallbackRender={props => (
                <RouteErrorFallback
                  pageName="Manage Project"
                  {...props}
                  onReset={() => window.history.back()}
                />
              )}
            >
              <ManageProject />
            </ErrorBoundary>
          ),
          children: [
            {
              path: `${UrlUtil.getUrl(PathNames.PROJECT)}/annotators`,
            },
            {
              path: `${UrlUtil.getUrl(PathNames.PROJECT)}/reviewers`,
            },
            {
              path: `${UrlUtil.getUrl(PathNames.PROJECT)}/jobs`,
            },
            {
              path: `${UrlUtil.getUrl(PathNames.PROJECT)}/stats`,
            },
          ],
        },
        {
          path: UrlUtil.getUrl(PathNames.NEW_PROJECT),
          element: (
            <ErrorBoundary
              key="NewProject"
              fallbackRender={props => (
                <RouteErrorFallback pageName="New Project" {...props} />
              )}
            >
              <NewProject />
            </ErrorBoundary>
          ),
        },
        {
          path: UrlUtil.getUrl(PathNames.CASE_SEARCH),
          element: (
            <ErrorBoundary
              key="CaseSearch"
              fallbackRender={props => (
                <RouteErrorFallback pageName="Search Case" {...props} />
              )}
            >
              <CaseSearch />
            </ErrorBoundary>
          ),
        },
        { path: UrlUtil.getUrl(PathNames.AI_OPERATIONAL), element: <AI /> },
        {
          path: UrlUtil.getUrl(PathNames.REPORTS),
          element: (
            <ErrorBoundary
              key="ReportList"
              fallbackRender={props => (
                <RouteErrorFallback pageName="Report List" {...props} />
              )}
              onReset={() => {
                refreshReportList();
              }}
            >
              <Reports />
            </ErrorBoundary>
          ),
        },

        {
          path: UrlUtil.getUrl(PathNames.ASSET_TEMPLATES),
          element: (
            <ErrorBoundary
              key="AssetTemplatesList"
              fallbackRender={props => (
                <RouteErrorFallback
                  pageName={ASSET_TEMPLATES_PAGE_TITLE}
                  {...props}
                />
              )}
            >
              <AssetTemplates />
            </ErrorBoundary>
          ),
        },
        {
          path: UrlUtil.getUrl(PathNames.NEW_ASSET_TEMPLATE),
          element: (
            <ErrorBoundary
              key="NewAssetTemplate"
              fallbackRender={props => (
                <RouteErrorFallback pageName="New Asset Template" {...props} />
              )}
            >
              <NewAssetTemplate />
            </ErrorBoundary>
          ),
        },

        {
          path: UrlUtil.getUrl(PathNames.MANAGE_ASSET_TEMPLATE),
          element: (
            <ErrorBoundary
              key="ManageAssetTemplate"
              fallbackRender={props => (
                <RouteErrorFallback
                  pageName={MANAGE_ASSET_TEMPLATE_TITLE}
                  {...props}
                />
              )}
            >
              <ManageAssetTemplate />
            </ErrorBoundary>
          ),
        },
        { path: '*', element: <NotFound /> },
      ],
    },
  ]);

  return (
    <StrictMode>
      <CombinedProviders>
        <ErrorBoundary key="Global" FallbackComponent={GlobalErrorFallback}>
          <Suspense fallback={null}>
            <FlexWrapper>
              <RouterProvider router={router} />
            </FlexWrapper>
          </Suspense>
        </ErrorBoundary>
      </CombinedProviders>
    </StrictMode>
  );
}

export default App;
