import { useCallback, useState } from 'react';

import Alert from '@mui/material/Alert';

import { AssetTemplateReadSchema } from 'src/types/api/data/assetTemplate';
import {
  ClientClaimAssetCreateSchema,
  HandleChangeClaimAssets,
} from 'src/types/client/asset';
import { getUniqueId } from 'src/utils/string';

import InspectClaimAssetDrawer from './InspectClaimAssetDrawer';
import SelectedValuesDialog from './SelectedValuesDialog';
import TemplateListItem from './TemplateListItem';

interface Props {
  filteredAssetTemplates: AssetTemplateReadSchema[];
  setItems: HandleChangeClaimAssets;
}

const TemplateList = ({
  filteredAssetTemplates,
  setItems,
}: Props): JSX.Element => {
  const [nesting, setNesting] = useState<{
    dragging: AssetTemplateReadSchema;
    target: ClientClaimAssetCreateSchema;
  }>();
  const [inspectingAsset, setInspectingAsset] =
    useState<ClientClaimAssetCreateSchema>();

  const mapToClientClaimAsset = (
    assetTemplate: AssetTemplateReadSchema
  ): ClientClaimAssetCreateSchema => {
    const { id, ...rest } = assetTemplate;
    return {
      ...rest,
      id: getUniqueId(), // Set a unique ID for the claim asset
      templateId: id, // Refer the template ID for the claim asset
      parent: undefined, // Template is assumed to not have parent asset
    };
  };

  const handleMove = useCallback(
    (dragIndex: number) => {
      const targetItem = filteredAssetTemplates[dragIndex];
      if (!targetItem) {
        return;
      }
      setItems(prev => [...prev, mapToClientClaimAsset(targetItem)]);
    },
    [filteredAssetTemplates, setItems]
  );

  const handleNesting = useCallback(
    (dragIndex: number, target: ClientClaimAssetCreateSchema) => {
      const dragging = filteredAssetTemplates[dragIndex];
      if (!dragging) {
        return;
      }
      setNesting({ dragging, target });
    },
    [filteredAssetTemplates]
  );

  const handleCloseSelectedValuesDialog = () => {
    setNesting(undefined);
  };

  const handleAddChildAsset = useCallback(
    (parentId: string, selectedValues: (string | boolean)[]) => {
      if (!nesting?.dragging) {
        handleCloseSelectedValuesDialog();
        return;
      }

      setItems(prev =>
        prev.map(item => {
          if (item.id === parentId) {
            return {
              ...item,
              children: [
                ...(item.children || []),
                {
                  ...mapToClientClaimAsset(nesting.dragging),
                  parent: {
                    id: parentId,
                    selectedValues,
                  },
                },
              ],
            };
          }
          return item;
        })
      );

      handleCloseSelectedValuesDialog();
    },
    [nesting?.dragging, setItems]
  );

  const handleSetInspectingAsset = useCallback(
    (clickedItem: ClientClaimAssetCreateSchema) => {
      setInspectingAsset(clickedItem);
    },
    []
  );

  const handleResetInspectingAsset = useCallback(() => {
    setInspectingAsset(undefined);
  }, []);

  return (
    <>
      {filteredAssetTemplates.length ? (
        filteredAssetTemplates.map(
          (assetTemplate: AssetTemplateReadSchema, index: number) => (
            <TemplateListItem
              key={assetTemplate.id}
              index={index}
              data={assetTemplate}
              onMove={handleMove}
              onNesting={handleNesting}
              onInspect={handleSetInspectingAsset}
            />
          )
        )
      ) : (
        <Alert severity="warning" variant="filled">
          No Asset Templates available
        </Alert>
      )}

      <InspectClaimAssetDrawer
        asset={inspectingAsset}
        onClose={handleResetInspectingAsset}
      />

      <SelectedValuesDialog
        open={!!nesting}
        asset={nesting?.target}
        onClose={handleCloseSelectedValuesDialog}
        onConfirm={handleAddChildAsset}
      />
    </>
  );
};

export default TemplateList;
