import { ChangeEvent, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import {
  object,
  string,
  number,
  boolean,
  ObjectSchema,
  ValidationError,
  StringSchema,
  NumberSchema,
} from 'yup';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { signUp, SignUpProps } from 'src/api';
import { Flexbox } from 'src/components/Alignments';
import BlockQuote from 'src/components/BlockQuote';
import useAlertSnackbar from 'src/hooks/useAlertSnackbar';
import useConfirmModal from 'src/hooks/useConfirmModal';
import { Modality } from 'src/types/api/data/image';

import CommonDialog, { CommonDialogProps } from './CommonDialog';

const PermissionChecks = styled('label')`
  padding: 10px 0;
`;

interface AccountForm {
  username: string;
  name?: string;
  password: string;
  passwordConfirm: string;
  checkCXR: boolean;
  checkMMG: boolean;
  checkDBT: boolean;
  count?: number;
}

const defaultFormValues = {
  username: '',
  name: '',
  password: '',
  passwordConfirm: '',
  checkCXR: false,
  checkMMG: false,
  checkDBT: false,
  count: 1,
};

export default function CreateAnnotatorAccountDialog({
  open,
  onClose,
}: CommonDialogProps): JSX.Element {
  const [isCreating, setIsCreating] = useState(false);
  const [bulkUserCreateMode, setBulkUserCreateMode] = useState(false);

  const { openAlertSnackbar } = useAlertSnackbar();
  const { getConfirmation } = useConfirmModal();

  const schema: ObjectSchema<AccountForm> = object()
    .shape({
      username: string()
        .required('Username is a required field')
        .min(4, 'Username must be at least 4 characters'),
      name: string().when('$other', (_, schema: StringSchema) => {
        return bulkUserCreateMode ? schema.optional() : schema.required();
      }),
      password: string()
        .required('Password is a required field')
        .test(
          'noWhiteSpace',
          'check that password does not include whitespace',
          value => {
            if (/[\s\t\n\r]/.test(value || '')) {
              return new ValidationError(
                'Password must not contain spaces',
                false,
                'password'
              );
            }
            return true;
          }
        )
        .test(
          'noIllegalCharacters',
          'check that password does not include illegal characters',
          value => {
            const illegalRegExp = /[^0-9a-zA-Z#?!@$^%&*-]/;
            const illegalCharacters = (value || '').match(illegalRegExp);
            if (illegalCharacters) {
              const [illegalCharacter] = illegalCharacters;
              return new ValidationError(
                `Password must not contain illegal character: ${illegalCharacter}`,
                false,
                'password'
              );
            }
            return true;
          }
        )
        .min(10, 'Password must be at least 10 characters')
        .max(100, 'Password must not be longer than 100 characters')
        .matches(/[A-Z]/, {
          message: 'Password must include at least 1 uppercase letter',
        })
        .matches(/[a-z]/, {
          message: 'Password must include at least 1 lowercase letter',
        })
        .matches(/[0-9]/, {
          message: 'Password must include at least 1 number',
        })
        .matches(/[#?!@$^%&*-]/, {
          message:
            'Password must include at least 1 special character:  #?!@$^%&*-',
        }),
      passwordConfirm: string().required(
        'Password confirm is a required field'
      ),
      checkCXR: boolean().required(),
      checkMMG: boolean().required(),
      checkDBT: boolean().required(),
      count: number().when('$other', (_, schema: NumberSchema) => {
        return bulkUserCreateMode
          ? schema
              .typeError('count must be a number')
              .required()
              .min(1)
              .max(100)
          : schema.typeError('count must be a number').optional();
      }),
    })
    .test(
      'dbCheckTest',
      'check at least one permission has been checked',
      data => {
        if (data.checkMMG || data.checkCXR || data.checkDBT) return true;
        return new ValidationError(
          'Please check at least one permission',
          false,
          'checkCXR'
        );
      }
    )
    .test('usernameTest', 'check validity of username', data => {
      if (/\W/.test(data.username || ''))
        return new ValidationError(
          'Username can only contain English letters, numbers, and underscores',
          null,
          'username'
        );
      return true;
    })
    .test('passwordConfirmTest', 'check password === passwordConfirm', data => {
      if (data.password !== data.passwordConfirm) {
        return new ValidationError(
          'Passwords do not match',
          null,
          'passwordConfirm'
        );
      }
      return true;
    });

  const {
    register,
    handleSubmit: handleFormSubmit,
    watch,
    setError,
    clearErrors,
    reset,
    formState: { errors, isValid, touchedFields },
  } = useForm<AccountForm>({
    resolver: yupResolver(schema),
    defaultValues: defaultFormValues,
    mode: 'all',
  });

  const watchPassword = watch('password');
  const watchPasswordConfirm = watch('passwordConfirm');

  useEffect(() => {
    if (touchedFields.passwordConfirm && watchPassword !== watchPasswordConfirm)
      setError('passwordConfirm', {
        type: 'validate',
        message: 'Passwords do not match',
      });
    else clearErrors('passwordConfirm');
  }, [
    clearErrors,
    setError,
    touchedFields.passwordConfirm,
    watchPassword,
    watchPasswordConfirm,
  ]);

  const handleSubmit = handleFormSubmit(async data => {
    if (bulkUserCreateMode) {
      const confirmation = await getConfirmation({
        title: 'Bulk User Create Confirmation',
        description: `You are going to create ${data.count} users in this sequence ${data.username}0, ${data.username}1, ${data.username}2...`,
      });

      if (!confirmation) {
        setIsCreating(false);
        onClose?.();
        return;
      }
    }

    setIsCreating(true);

    const permittedModalities = [
      data.checkCXR ? Modality.CXR : undefined,
      data.checkMMG ? Modality.MMG : undefined,
      data.checkDBT ? Modality.DBT : undefined,
    ].filter((value): value is Modality => value !== undefined);

    const [defaultModality] = permittedModalities;

    try {
      if (!defaultModality) {
        throw new Error('Target modality is not set.');
      }

      let payload: SignUpProps[] = [];
      if (bulkUserCreateMode) {
        payload = [...Array(data.count).keys()].map(index => ({
          username: `${data.username}${index}`,
          password1: data.password,
          password2: data.passwordConfirm,
          name: `${data.username}${index}`,
          isAdmin: false,
          permittedModalities,
          defaultModality,
          defaultDatabase: '',
          permittedDatabases: [],
        }));
      } else {
        payload = [
          {
            username: data.username,
            password1: data.password,
            password2: data.passwordConfirm,
            name: data.name || data.username,
            isAdmin: false,
            permittedModalities,
            defaultModality,
            defaultDatabase: '',
            permittedDatabases: [],
          },
        ];
      }

      await signUp(payload);
      if (bulkUserCreateMode) {
        openAlertSnackbar({
          severity: 'success',
          description: `${data.count} of new users have been created with the naming of ${data.username}'.`,
        });
      } else {
        openAlertSnackbar({
          severity: 'success',
          description: `A new user '${data.username}' has been created.`,
        });
      }
      reset();
      onClose?.();
    } catch (error) {
      openAlertSnackbar({
        severity: 'error',
        description: bulkUserCreateMode
          ? `Failed to create multiple new users : ${error}`
          : `Failed to create a new user : ${error}`,
      });
    }
    setIsCreating(false);
  });

  const handleUserCreateMode = (
    e: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setBulkUserCreateMode(checked);
  };

  return (
    <CommonDialog
      onClose={onClose}
      open={open}
      title={
        bulkUserCreateMode ? 'Create Multiple Annotators' : 'Create Annotator'
      }
      maxWidth="xs"
    >
      <form action="#" noValidate>
        <DialogContent style={{ paddingTop: 0 }}>
          <Flexbox
            $justify="space-between"
            $align="baseline"
            $direction="column"
          >
            <FormControlLabel
              control={
                <Switch
                  value={bulkUserCreateMode}
                  onChange={handleUserCreateMode}
                />
              }
              label="Enable bulk create"
            />
          </Flexbox>

          {bulkUserCreateMode && (
            <BlockQuote>
              <Typography variant="subtitle1" gutterBottom component="div">
                <strong>You can create multiple users with same name.</strong>
                <br />
                If you input name as <strong>"user"</strong> and count as{' '}
                <strong>"4"</strong>, you will create next four users at once.
              </Typography>
              <Divider />
              <Typography variant="subtitle1" component="div">
                user0, user1, user2, user3
              </Typography>
            </BlockQuote>
          )}

          {bulkUserCreateMode ? (
            <Grid
              item
              container
              xs={12}
              justifyContent="space-between"
              spacing={1}
            >
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  margin="normal"
                  type="text"
                  size="small"
                  variant="outlined"
                  label="Username"
                  {...register('username')}
                  required
                  helperText={errors.username?.message}
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  margin="normal"
                  type="number"
                  size="small"
                  variant="outlined"
                  {...register('count')}
                  label="Count"
                  helperText={errors.count?.message}
                />
              </Grid>
            </Grid>
          ) : (
            <>
              <TextField
                fullWidth
                type="text"
                size="small"
                variant="outlined"
                margin="normal"
                label="Username"
                required
                {...register('username')}
                helperText={errors.username?.message}
              />
              <TextField
                fullWidth
                size="small"
                type="text"
                variant="outlined"
                label="Name"
                required
                margin="normal"
                {...register('name')}
                helperText={errors.name?.message}
              />
            </>
          )}

          <TextField
            fullWidth
            type="password"
            size="small"
            variant="outlined"
            margin="normal"
            label="Password"
            required
            autoComplete="new-password"
            {...register('password')}
            helperText={errors.password?.message}
          />
          <Typography variant="caption">
            Password must be at least 10 characters including at least 1
            uppercase letter, 1 lowercase letter, 1 number and 1 special
            character: #?!@$^%&*-
          </Typography>
          <TextField
            fullWidth
            margin="normal"
            type="password"
            size="small"
            variant="outlined"
            label="Password Confirm"
            required
            {...register('passwordConfirm')}
            helperText={errors.passwordConfirm?.message}
          />

          <Typography marginTop={'2rem'}>Permission</Typography>
          <PermissionChecks>
            <FormControlLabel
              control={
                <Checkbox
                  {...register('checkMMG')}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label="MMG"
            />
            <FormControlLabel
              control={
                <Checkbox
                  {...register('checkCXR')}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label="CXR"
            />
            <FormControlLabel
              control={
                <Checkbox
                  {...register('checkDBT')}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label="DBT"
            />
            <div className="checkbox-description" style={{ fontSize: 11 }}>
              Please check at least one permission
            </div>
          </PermissionChecks>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit} disabled={!isValid || isCreating}>
            {isCreating ? <CircularProgress size={15} /> : 'Submit'}
          </Button>
        </DialogActions>
      </form>
    </CommonDialog>
  );
}
