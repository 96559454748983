import { PropsWithChildren } from 'react';

import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

interface Props {
  title?: string;
}

const FormBox = ({
  title,
  children,
}: PropsWithChildren<Props>): JSX.Element => {
  return (
    <Container>
      {title && (
        <Typography variant="subtitle1" gutterBottom>
          {title}
        </Typography>
      )}
      <Box>{children}</Box>
    </Container>
  );
};

const Container = styled('section')``;

const Box = styled('div')(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing(2.5)};
  gap: ${theme.spacing(1.5)};
  border-radius: .5rem;
  background-color: ${theme.custom.bgLight};
`
);

export default FormBox;
