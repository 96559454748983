import {
  ClaimCategorySchema,
  DecisionSelectorLabel,
  DecisionTexterLabel,
  DecisionTogglerLabel,
  Finding,
  SelectorAttributes,
  TogglerAttributes,
} from '@lunit-io/ctl-api-interface';

import {
  AssetTemplateUpdateSchema,
  AssetTemplateCreateSchema,
} from 'src/types/api/data/assetTemplate';
import { ClaimAssetCreateSchema } from 'src/types/api/data/project';

export type LocalDecisionSelectorLabel = DecisionSelectorLabel & {
  index: number;
};

type LocalDecisionTogglerLabel = DecisionTogglerLabel & {
  index: number;
};

type LocalDecisionTexterLabel = DecisionTexterLabel & {
  index: number;
};

export type ClaimAnnotationResponse = {
  findings: Array<Finding>;
  labels: Array<
    | LocalDecisionTogglerLabel
    | LocalDecisionTexterLabel
    | LocalDecisionSelectorLabel
  >;
};

export type AssetTemplateFormSelectorSchema = Omit<
  AssetTemplateCreateSchema,
  'formAttributes'
> & {
  formAttributes: SelectorAttributes;
};

export type AssetTemplateFormTogglerSchema = Omit<
  AssetTemplateCreateSchema,
  'formAttributes'
> & {
  formAttributes: TogglerAttributes;
};

export type AssetTemplateFormSchema =
  | AssetTemplateCreateSchema
  | AssetTemplateUpdateSchema;

export type ClientClaimAssetCreateSchema = ClaimAssetCreateSchema & {
  children?: ClaimAssetCreateSchema[];
};

export type HandleChangeClaimAssets = (
  callback: (
    prev: ClientClaimAssetCreateSchema[]
  ) => ClientClaimAssetCreateSchema[]
) => void;

export enum ASSET_VALIDATION_ITEM_TYPE {
  TEMPLATE = 'TEMPLATE',
  CONFIGURED = 'CONFIGURED',
}

export enum ASSET_VALIDATION_DROP_TYPE {
  ADDING = 'ADDING',
  DELETING = 'DELETING',
}

export type AssetValidationDropResult = {
  type: ASSET_VALIDATION_DROP_TYPE;
};

export const DEFAULT_ASSET_CATEGORY: ClaimCategorySchema = {
  name: '',
  text: '',
  alias: '',
};

export enum ASSET_ITEM_TYPE {
  TEMPLATE = 'TEMPLATE',
  CONFIGURED = 'CONFIGURED',
}

export enum ASSET_DROP_TYPE {
  ADDING = 'ADDING',
  NESTING = 'NESTING',
}

export type AssetDropResult =
  | {
      type: ASSET_DROP_TYPE.ADDING;
    }
  | {
      type: ASSET_DROP_TYPE.NESTING;
      asset: ClientClaimAssetCreateSchema;
    };
