import { createRoot } from 'react-dom/client';
import './index.css';

import App from './App.tsx';

if (
  process.env.NODE_ENV === 'development' &&
  process.env.REACT_APP_MSW === 'on'
) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('./mocks/browser');
  worker.start();
}

const root = createRoot(document.getElementById('root'));

root.render(<App />);
