import { AssetGroupEnum } from '@lunit-io/ctl-api-interface';
import { useDrag } from 'react-dnd';

import Info from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';

import FormListItemBase from 'src/pages/NewProject/FormListItemBase';
import { AssetTemplateReadSchema } from 'src/types/api/data/assetTemplate';
import {
  ClientClaimAssetCreateSchema,
  ASSET_ITEM_TYPE,
  AssetDropResult,
  ASSET_DROP_TYPE,
} from 'src/types/client/asset';

import ListItemContent from './ListItemContent';

interface OwnProps {
  data: AssetTemplateReadSchema;
  index: number;
  onMove: (dragIndex: number) => void;
  onNesting: (
    dragIndex: number,
    targetItem: ClientClaimAssetCreateSchema
  ) => void;
  onInspect: (clickedItem: ClientClaimAssetCreateSchema) => void;
}

export interface DraggingTemplateListItem {
  id?: string;
  index: number;
  group: AssetGroupEnum;
}

const TemplateListItem = ({
  data,
  index,
  onMove,
  onNesting,
  onInspect,
}: OwnProps): JSX.Element => {
  const [, drag] = useDrag({
    type: ASSET_ITEM_TYPE.TEMPLATE,
    item: (): DraggingTemplateListItem => {
      return { id: data.id, index, group: data.group };
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<AssetDropResult>();

      if (!item || !dropResult) {
        return;
      }

      if (dropResult.type === ASSET_DROP_TYPE.ADDING) {
        onMove(item.index);
      }

      if (dropResult.type === ASSET_DROP_TYPE.NESTING) {
        onNesting(item.index, dropResult.asset);
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  });

  const handleInspect = () => {
    if (!data?.id) {
      return;
    }
    onInspect({ ...data, id: data.id });
  };

  return (
    <div>
      <FormListItemBase ref={drag} $isDraggable>
        <ListItemContent text={data.name}>
          <IconButton onClick={handleInspect}>
            <Info fontSize="small" />
          </IconButton>
        </ListItemContent>
      </FormListItemBase>
    </div>
  );
};

export default TemplateListItem;
