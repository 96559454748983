import { Field, Option } from 'react-querybuilder';

import {
  CaseSearchQueryConfig,
  ReducedCaseListSchema,
  ReducedCaseReadSchema,
} from 'src/types/api/data/case';
import { Modality } from 'src/types/api/data/image';
import { DEFAULT_PAGE_SIZE } from 'src/utils/constants';
import { dataApi } from 'src/utils/http';

export type ReducedCasesProps = CaseSearchQueryConfig & {
  page?: number;
  pageSize?: number;
  fields?: string[];
};

const emptyResponse = {
  cases: [],
  countPerPage: 0,
  countTotalObjects: 0,
  countTotalPages: 1,
  currentPage: 0,
};

export const getReducedCases = async (
  props: ReducedCasesProps
): Promise<ReducedCaseListSchema> => {
  const { modality } = props;

  switch (modality) {
    case Modality.CXR:
    case Modality.MMG:
    case Modality.CCT:
    case Modality.DBT:
      return getReducedQuery(props);
    default:
      return emptyResponse;
  }
};

const getReducedQuery = async ({
  modality,
  datasetName,
  caseFilter,
  readingFilter,
  readingType,
  allowEmptyReading,
  page = 1,
  pageSize = DEFAULT_PAGE_SIZE,
  readyForJobOnly = true,
  fields,
}: ReducedCasesProps): Promise<ReducedCaseListSchema> => {
  try {
    const { data } = await dataApi.get<ReducedCaseListSchema>(
      `/cases/${modality.toLowerCase()}/reduced/`,
      {
        params: {
          page,
          page_size: pageSize,
          reading_type: readingType,
          dataset_name: datasetName,
          case_filter: caseFilter,
          reading_filter: readingFilter,
          ready_for_job_only: readyForJobOnly,
          allow_empty_reading: allowEmptyReading,
          fields,
          modality: modality.toLowerCase(),
        },
      }
    );

    return data;
  } catch {
    return emptyResponse;
  }
};

type CaseProps = {
  caseId: string;
  modality: string;
};

export const getCase = async ({
  caseId,
  modality,
}: CaseProps): Promise<ReducedCaseReadSchema | undefined> => {
  try {
    const { data } = await dataApi.get<ReducedCaseReadSchema>(
      `/cases/${modality.toLowerCase()}/${caseId}/reduced/`,
      {
        params: {
          modality: modality.toLowerCase(),
        },
      }
    );

    return data;
  } catch {
    return undefined;
  }
};

export type QueryBuilderOptions = {
  caseQueryFields: Field[];
  readingQueryFields: Field[];
  operators: Option[];
  combinators: Option[];
};

export const getQueryBuilderOptions = async (
  modality: string
): Promise<QueryBuilderOptions> => {
  const { data } = await dataApi.get<QueryBuilderOptions>(
    `/cases/${modality.toLowerCase()}/query-builder-options/`
  );

  return data;
};
