import { DataList } from 'src/types/api/common';

export type JobReadSchema = {
  id: string;
  project: string;
  case: string;
  reading: string;
  validatedAt?: string;
  inspectedBy?: string;
  oldReadings?: unknown[];
  assignedTo?: string;
  createdAt: string;
  assignedAt?: string;
  queuedAt?: string;
  type: string;
  completed: boolean;
  reported: boolean;
};

export interface JobListSchema extends DataList {
  jobs: JobReadSchema[];
}

export const JOB_TYPES = [
  'annotation',
  'corroboration',
  'cross',
  'intra',
] as const;

export type JobType = typeof JOB_TYPES[number];

export const JOB_STATES = [
  'created',
  'assigned',
  'queued',
  'completed',
  'reported',
] as const;

export type JobState = typeof JOB_STATES[number];

type JobId = string;

// TODO: This is incorrect with actual response type of the API for it.
// - Need to update the name of this schema to JobStageChangeSchema.
// - Need to update the value of each field to use actual JotReadSchema.
export interface BulkAssignJobsSchema {
  failure: JobId[];
  success: JobId[];
}
