import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const ValidationButton = styled(Button)(
  ({ theme }) => `
  display: inline-block;
  padding: 0 ${theme.spacing(0.75)};
  min-width: 0;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: none;
  white-space: nowrap;
`
);

export default ValidationButton;
