import Pagination, { PaginationProps } from '@mui/material/Pagination';

import StyledPaginationItem from './StyledPaginationItem';

const StyledPagination = (props: PaginationProps): JSX.Element => (
  <Pagination
    {...props}
    renderItem={item => <StyledPaginationItem {...item} />}
  />
);

export default StyledPagination;
