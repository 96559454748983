import { useState, ChangeEvent } from 'react';

import { AlertColor } from '@mui/material/Alert';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { assignJobsByProject } from 'src/api/jobs';
import { Flexbox } from 'src/components/Alignments';
import StyledRangeSlider from 'src/components/RangeSlider';
import useAlertSnackbar from 'src/hooks/useAlertSnackbar';
import useCheckedParams from 'src/hooks/useCheckedParams';
import { Modality } from 'src/types/api/data/image';

import CommonDialog, { CommonDialogProps } from './CommonDialog';

const JobAssignSliderContent = styled('div')`
  width: 80%;
`;

export interface JobAssignDialogProps extends CommonDialogProps {
  assignableJobCount: number;
  annotatorName?: string;
  annotatorId?: string;
  projectId: string;
  onUpdate: () => void;
}

export default function JobAssignDialog({
  assignableJobCount,
  annotatorName = '',
  annotatorId = '',
  projectId,
  onUpdate,
  onClose,
  open,
}: JobAssignDialogProps): JSX.Element {
  const [jobAssignCount, setJobAssignCount] = useState(0);
  const { openAlertSnackbar } = useAlertSnackbar();
  const { modalityLabel } = useCheckedParams<{
    modalityLabel: Modality;
  }>(['modalityLabel']);

  const handleChangeRangeSlider = (value: number) => {
    setJobAssignCount(value);
  };

  const handleChangeJobAssignCountInput = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let userValue = parseInt(e.target.value, 10);
    userValue =
      Number.isNaN(userValue) || !Number.isInteger(userValue) ? 0 : userValue;
    if (assignableJobCount < userValue) userValue = assignableJobCount;
    setJobAssignCount(userValue);
  };

  const requestAssignJobs = async () => {
    try {
      const resp = await assignJobsByProject({
        projectId,
        userId: annotatorId,
        count: jobAssignCount,
        modality: modalityLabel,
      });

      const successCount = resp.success?.length;
      const failCount = resp.failure?.length;
      let severity: AlertColor = 'success';
      let description = 'Successfully assigned new jobs';

      if (successCount === 0 && failCount <= jobAssignCount) {
        severity = 'error';
        description = 'Assigning jobs was unsuccessful. Please try again!';
      }
      if (successCount > 0 && successCount < jobAssignCount) {
        severity = 'warning';
        description = `Successfully assigned ${successCount} new jobs (but ${failCount} failed)`;
      }

      openAlertSnackbar({
        severity,
        description,
      });
    } catch (e) {
      openAlertSnackbar({
        severity: 'error',
        description: 'Failed to assign new jobs',
      });
    }
    onUpdate();
  };

  return (
    <CommonDialog onClose={onClose} title="Assign Jobs" open={open} fullWidth>
      <DialogContent>
        <Typography>
          <strong>Annotator:</strong> {annotatorName} [ID: {annotatorId}]
        </Typography>

        <Typography>
          <strong>Assignable Job Count:</strong> {assignableJobCount}
        </Typography>

        <Typography>
          <strong>Job Count To Assign:</strong>{' '}
        </Typography>

        <Flexbox $justify="space-between">
          <JobAssignSliderContent>
            <StyledRangeSlider
              disabled={assignableJobCount === 0}
              max={assignableJobCount === 0 ? 1 : assignableJobCount}
              onChange={handleChangeRangeSlider}
              value={jobAssignCount}
            />
          </JobAssignSliderContent>
          <TextField
            variant="outlined"
            size="small"
            color="primary"
            value={jobAssignCount}
            onChange={handleChangeJobAssignCountInput}
            style={{ width: 80 }}
          />
        </Flexbox>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={requestAssignJobs}>Assign</Button>
      </DialogActions>
    </CommonDialog>
  );
}
