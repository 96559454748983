import camelCase from 'camelcase';

import AutoFillButton from './AutoFillButton';

interface Props {
  text: string;
  onClick: (name: string) => void;
}

const generateAutoFillSuggestion = (text: string): string => {
  const split = text.trim().split(/[(){}[\]]/i);
  return camelCase(split);
};

const KeyNameAutoFillButton = ({ text, onClick }: Props): JSX.Element => {
  const handleClick = () => {
    onClick(generateAutoFillSuggestion(text));
  };
  const suggestAutoFill = generateAutoFillSuggestion(text);

  return <AutoFillButton suggestion={suggestAutoFill} onClick={handleClick} />;
};

export default KeyNameAutoFillButton;
