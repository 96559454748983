import { useMemo } from 'react';

import { useRecoilState } from 'recoil';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

import FormBox from 'src/components/FormBox';
import { MODES } from 'src/configs/claims';
import AlertPanel from 'src/pages/NewProject/AlertPanel';
import FormListItemBase from 'src/pages/NewProject/FormListItemBase';
import { newProjectState } from 'src/states/newProject';

const Modes = (): JSX.Element => {
  const [claim, setClaim] = useRecoilState(newProjectState.claim);

  const activatedModeNames = useMemo(
    () => claim.modes.map(({ name }) => name),
    [claim.modes]
  );

  const getHandleChange = (newModeName: string) => () => {
    setClaim(prev => {
      const modes = (() => {
        const isExisted = prev.modes.find(({ name }) => name === newModeName);

        if (isExisted) {
          // At least one mode is required
          if (prev.modes.length <= 1) {
            return prev.modes;
          }
          return prev.modes.filter(({ name }) => name !== newModeName);
        }

        const newMode = MODES.find(({ name }) => name === newModeName);

        if (newMode) {
          return [...prev.modes, newMode];
        }

        return prev.modes;
      })();

      return {
        ...prev,
        modes,
      };
    });
  };

  return (
    <>
      <AlertPanel isValid={true}>
        <ul>
          <li>Project requires at least one active mode.</li>
          <li>
            It's not possible to deactivate all modes because of the same
            reason.
          </li>
        </ul>
      </AlertPanel>

      <FormBox>
        {MODES.map(mode => (
          <FormListItemBase key={mode.name}>
            <FormGroup>
              <StyledFormControlLabel
                labelPlacement="start"
                control={
                  <Switch
                    checked={activatedModeNames.includes(mode.name)}
                    onChange={getHandleChange(mode.name)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={mode.text}
              />
            </FormGroup>
          </FormListItemBase>
        ))}
      </FormBox>
    </>
  );
};

export default Modes;

const StyledFormControlLabel = styled(FormControlLabel)(
  ({ theme }) => `
  padding: ${theme.spacing(0.5)} ${theme.spacing(1.5)} ${theme.spacing(0.5)} 0;
  justify-content: space-between;
`
);
