import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';

interface Props {
  suggestion: string;
  onClick: () => void;
}

const AutoFillButton = ({ suggestion, onClick }: Props): JSX.Element => {
  const handleClick = () => {
    onClick?.();
  };

  return (
    <InputAdornment position="end">
      <Tooltip title={`Suggestion: "${suggestion}"`} placement="top">
        <IconButton
          aria-label={`Suggestion: "${suggestion}"`}
          onClick={handleClick}
          edge="end"
          disabled={false}
        >
          <AutoFixHighIcon />
        </IconButton>
      </Tooltip>
    </InputAdornment>
  );
};

export default AutoFillButton;
