import { ComponentType, PropsWithChildren } from 'react';

import { FallbackProps } from 'react-error-boundary';

import Button from '@mui/material/Button';

import ErrorPanel from './ErrorPanel';

interface Props extends FallbackProps {
  pageName: string;
  onReset?: () => void;
}

const RouteErrorFallback: ComponentType<PropsWithChildren<Props>> = ({
  error,
  resetErrorBoundary,
  pageName,
  onReset,
}) => {
  return (
    <ErrorPanel
      title={`Caught an error.`}
      subTitle1={`There is a problem while preparing ${pageName} page.`}
      subTitle2={error.message}
    >
      <Button
        variant="contained"
        color="primary"
        aria-label="Retry"
        onClick={onReset || resetErrorBoundary}
      >
        Retry
      </Button>
    </ErrorPanel>
  );
};

export default RouteErrorFallback;
