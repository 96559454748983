import { useParams } from 'react-router-dom';

function useCheckedParams<T extends Record<string, string>>(
  requiredParams: (keyof T)[]
): T {
  const params = useParams<T>() as T;

  requiredParams.forEach(param => {
    if (!params[String(param)]) {
      throw new Error(`${String(param)} is required`);
    }
  });

  return params;
}

export default useCheckedParams;
