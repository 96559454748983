import { styled } from '@mui/material/styles';

const RowButtonGroup = styled('div')`
  display: flex;
  margin: 0 -4px;
  justify-content: space-between;

  > * {
    flex: 1;
    margin: 0 4px;
  }
`;

export default RowButtonGroup;
