import { ReactNode } from 'react';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import RowButtonGroup from 'src/components/Form/RowButtonGroup';

interface Props {
  title?: string;
  subTitle1?: string;
  subTitle2?: string;
  children?: ReactNode;
}

const ErrorPanel = ({
  title = 'Error',
  subTitle1 = 'Something went to wrong',
  subTitle2,
  children,
}: Props): JSX.Element => {
  return (
    <Container>
      <StyledPaper>
        <Typography variant="h5">{title}</Typography>
        <Typography variant="subtitle1" style={{ marginTop: '2rem' }}>
          {subTitle1}
        </Typography>
        {subTitle2 && <Pre style={{ marginTop: '.5rem' }}>{subTitle2}</Pre>}
        {children && (
          <RowButtonGroup style={{ margin: 0, marginTop: '2rem' }}>
            {children}
          </RowButtonGroup>
        )}
      </StyledPaper>
    </Container>
  );
};

export default ErrorPanel;

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  max-width: 60rem;
  min-width: 40rem;
`;

const Pre = styled('pre')`
  padding: 0.85rem 1rem;
  margin: 0;
  border: 1px dashed rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.05);
  font-size: 0.925rem;
  line-height: 1.3;
`;
