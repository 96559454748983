import dayjs, { Dayjs } from 'dayjs';

export const getFirstTimeOfDay = (date: Dayjs): Dayjs => date.startOf('day');

export const getLastTimeOfDay = (date: Dayjs): Dayjs => date.endOf('day');

export const today = dayjs();
export const oneMonthAgo = today.subtract(1, 'months');

export const isValidDate = (d: Dayjs | null | undefined): boolean => {
  return d ? dayjs(d).isValid() : false;
};
