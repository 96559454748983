import {
  ChangeEvent,
  useState,
  useEffect,
  useMemo,
  useCallback,
  SyntheticEvent,
} from 'react';

import dayjs, { Dayjs } from 'dayjs';
import { useErrorHandler } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import { Row } from 'react-table';
import {
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';

import Button from '@mui/material/Button';

import { SectionTitle } from 'src/components';
import { Vertical } from 'src/components/Alignments';
import ToggleButtons from 'src/components/Button/ToggleButton';
import DateRangePicker from 'src/components/DateRangePicker';
import FilterPanel from 'src/components/FilterPanel';
import DropdownMenu from 'src/components/Input/DropdownMenu';
import Table from 'src/components/Table';
import useCheckedParams from 'src/hooks/useCheckedParams';
import { myModalityOptionState } from 'src/states/myInfo';
import {
  initialFilters,
  isDetailDrawerOpen,
  reportListQuery,
  reportListQueryParamState,
  selectedReport,
} from 'src/states/reportList';
import { userState } from 'src/states/user';
import { Modality } from 'src/types/api/data/image';
import { ReportReadSchema } from 'src/types/api/data/report';
import { SelectOption } from 'src/types/client/ui';
import { PathNames } from 'src/types/client/url';
import { throwUnexpectedWrongModalityError } from 'src/utils/clientError';
import { DATE_FORMAT } from 'src/utils/constants';
import { getFirstTimeOfDay, getLastTimeOfDay, today } from 'src/utils/date';
import { getEllipsis } from 'src/utils/string';
import { UrlUtil } from 'src/utils/url';
import { getModalityOptions } from 'src/utils/user';

import ReportDetailDrawer from './ReportDetailDrawer';
import UserNameColumn from './UserNameColumn';

const COLUMNS = [
  {
    Header: 'Report List',
    columns: [
      {
        Header: 'Description',
        accessor: 'text',
        style: { width: '30%' },
        Cell: ({ value }: { value: string }) => getEllipsis(value).string,
      },
      {
        Header: 'Related Job ID',
        accessor: 'job',
        Cell: ({ value }: { value: string }) => value || '-',
      },
      {
        Header: 'Reported User',
        accessor: 'user',
        Cell: ({ value }: { value: string }) => (
          <UserNameColumn userId={value} />
        ),
      },
      {
        Header: 'Reported Date',
        accessor: 'createdAt',
        style: { width: '180px' },
        Cell: ({ value }: { value: string }) => {
          return dayjs(new Date(value)).format(`${DATE_FORMAT} HH:mm:ss`);
        },
      },
      {
        Header: 'Status',
        accessor: 'resolved',
        Cell: ({ value }: { value: boolean }) =>
          value ? 'Resolved' : 'Unresolved',
      },
    ],
  },
];

const reportStatusOpts: [SelectOption, SelectOption, SelectOption] = [
  { value: '', label: 'All' },
  { value: 'resolved', label: 'Resolved' },
  { value: 'unresolved', label: 'Unresolved' },
];

const Reports = (): JSX.Element => {
  const { modalityLabel } = useCheckedParams<{
    modalityLabel: Modality;
  }>(['modalityLabel']);

  const navigate = useNavigate();

  const [params, setParams] = useRecoilState(reportListQueryParamState);
  const userFilterList = useRecoilValue(userState.allUserOptionList);
  const [startDate, setStartDate] = useState<Dayjs | null>();
  const [endDate, setEndDate] = useState<Dayjs | null>(today);
  const modalityOptions = useRecoilValue(myModalityOptionState);
  const setIsDetailOpen = useSetRecoilState(isDetailDrawerOpen);

  const reportStatus = (): SelectOption => {
    return params.resolvedOnly === undefined
      ? reportStatusOpts[0]
      : params.resolvedOnly
      ? reportStatusOpts[1]
      : reportStatusOpts[2];
  };
  const handleError = useErrorHandler();
  const setSelectedReport = useSetRecoilState(selectedReport);
  const { contents, state } = useRecoilValueLoadable(reportListQuery);

  if (state === 'hasError') {
    handleError(contents);
  }
  const isLoading = state === 'loading';
  const reportsData = useMemo(
    () =>
      state === 'hasValue'
        ? contents
        : { reports: [], currentPage: 1, countTotalPages: 1 },
    [contents, state]
  );
  const { reports, currentPage, countTotalPages } = reportsData;

  /**
   * For set default database name of selected user to params for requesting report list.
   */
  const selectedUser = useRecoilValue(
    userState.singleUser(params.userOption?.value || '')
  );

  const modalityOpts = useMemo(() => {
    if (params.userOption?.value === 'all') return modalityOptions;
    else if (selectedUser) return getModalityOptions(selectedUser);
    return [];
  }, [modalityOptions, params.userOption?.value, selectedUser]);

  useEffect(() => {
    if (params.userOption?.value === 'all') {
      setParams(prev => ({
        ...prev,
        selectedModality: modalityOptions[0] || null,
      }));
    }
  }, [params.userOption?.value, setParams, modalityOptions]);

  useEffect(() => {
    if (selectedUser) {
      setParams(prev => ({
        ...prev,
        selectedModality: modalityOpts[0] || null,
      }));
    } else {
      setParams(prev => ({
        ...prev,
        userOption: initialFilters.userOption,
      }));
    }
  }, [modalityOpts, selectedUser, setParams]);

  const handleClickRow = useCallback(
    ({ original: report }: Row<ReportReadSchema>): void => {
      setIsDetailOpen(true);
      setSelectedReport(report);
    },
    [setIsDetailOpen, setSelectedReport]
  );

  const handleChangePage = (_: ChangeEvent<unknown>, page: number): void =>
    setParams(prev => ({
      ...prev,
      page,
    }));

  const handleStartDateChange = (date: Dayjs | null) => {
    if (date) {
      const parsed = getFirstTimeOfDay(date);
      setStartDate(parsed);
      setParams(prev => ({
        ...prev,
        dateFrom: parsed.toISOString(),
        page: 1,
      }));
    }
  };

  const handleEndDateChange = (date: Dayjs | null) => {
    if (date) {
      const parsed = getLastTimeOfDay(date);
      setEndDate(parsed);
      setParams(prev => ({
        ...prev,
        dateTo: parsed.toISOString(),
        page: 1,
      }));
    }
  };
  const handleUserFilterChange = (
    event: SyntheticEvent,
    opt: SelectOption | null
  ) => {
    setParams(prev => ({
      ...prev,
      page: 1,
      userOption: opt || initialFilters.userOption,
    }));
  };

  const handleFilterReset = () => {
    setParams(prev => ({
      ...prev,
      ...initialFilters,
      selectedModality: modalityOptions[0] || null,
    }));

    setStartDate(null);
    setEndDate(today);
  };

  const handleStatusFilterChange = (opt: SelectOption) => {
    setParams(prev => ({
      ...prev,
      includeResolved: !opt.value ? true : false,
      resolvedOnly: !opt.value
        ? undefined
        : opt.value === 'resolved'
        ? true
        : false,
      page: 1,
    }));
  };

  const handleChangeModality = (opt: SelectOption) => {
    navigate(
      UrlUtil.getUrl(PathNames.REPORTS, {
        modalityLabel: opt.label,
      })
    );

    setParams(prev => ({
      ...prev,
      selectedModality: opt,
    }));
  };

  useEffect(() => {
    if (!modalityOpts.length) {
      return;
    }
    const opt = modalityOpts.find(({ label }) => label === modalityLabel);
    if (!opt) {
      throw throwUnexpectedWrongModalityError(modalityLabel);
    }
    setParams(prev => ({
      ...prev,
      selectedModality: opt,
    }));
  }, [modalityLabel, modalityOpts, setParams]);

  return (
    <Vertical>
      <ReportDetailDrawer />

      <SectionTitle>Report List</SectionTitle>

      <FilterPanel>
        <DateRangePicker
          dateFrom={startDate}
          dateTo={endDate}
          onStartDateChange={handleStartDateChange}
          onEndDateChange={handleEndDateChange}
          disabled={isLoading}
        />
        <DropdownMenu
          label="Select a user"
          options={userFilterList}
          onChange={handleUserFilterChange}
          value={params.userOption}
          disabled={isLoading}
          loading={isLoading}
        />
        <ToggleButtons
          options={reportStatusOpts}
          value={reportStatus()}
          onChangeLabel={handleStatusFilterChange}
        />
        <ToggleButtons
          options={modalityOpts}
          value={params.selectedModality}
          onChangeLabel={handleChangeModality}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleFilterReset}
          disabled={isLoading}
        >
          Reset
        </Button>
      </FilterPanel>

      <Table<ReportReadSchema>
        columns={COLUMNS}
        data={reports}
        onClickRow={handleClickRow}
        isLoading={isLoading}
        paginationProps={{
          page: currentPage,
          count: countTotalPages,
          onChange: handleChangePage,
        }}
      />
    </Vertical>
  );
};

export default Reports;
