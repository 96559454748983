import { atom, selector, useResetRecoilState } from 'recoil';

import { INITIAL_CLAIM } from 'src/configs/claims';
import { Modality } from 'src/types/api/data/image';
import { ClaimCreateSchema } from 'src/types/api/data/project';
import AssetValidationUtils from 'src/utils/assetValidation';

const current = selector({
  key: 'newProject/current',
  get: ({ get }) => {
    const modality = get(modalityLabel);

    return {
      name: get(name).trim(),
      modality,
      claim: get(claim),
    };
  },
});

const modalityLabel = atom<Modality>({
  key: 'newProject/modalityLabel',
  default: Modality.GENERAL,
});

const name = atom<string>({
  key: 'newProject/name',
  default: '',
});

const claim = atom<ClaimCreateSchema>({
  key: 'newProject/claim',
  default: INITIAL_CLAIM,
});

const isValidBasicInformation = selector({
  key: 'newProject/isValidBasicInformation',
  get: ({ get }) => {
    const { name } = get(current);
    return name.length > 0;
  },
});

const isValidAssets = selector({
  key: 'newProject/isValidAssets',
  get: ({ get }) => {
    const { claim } = get(current);
    const hasConfiguredAssets = claim.assets.length > 0;

    const duplicatedNameAssetIds = claim.assets
      .filter(
        (item, index, array) =>
          array.filter(({ name }) => name === item.name).length >= 2
      )
      .map(({ id }) => id);

    const hasAssetNameCollision = duplicatedNameAssetIds.length > 0;

    return {
      isValid: hasConfiguredAssets && !hasAssetNameCollision,
      invalidAssetIds: duplicatedNameAssetIds,
    };
  },
});

const isValidAssetValidations = selector({
  key: 'newProject/isValidAssetValidations',
  get: ({ get }) => {
    const {
      claim: { assets, assetValidations },
    } = get(current);

    const invalidIndexes = (assetValidations || [])
      .map((validations, index) => {
        const isValid = AssetValidationUtils.checkValidity(assets, validations);
        return isValid ? undefined : index;
      })
      .filter(index => index !== undefined);

    return {
      isValid: invalidIndexes.length === 0,
      invalidIndexes: invalidIndexes,
    };
  },
});

const isValid = selector({
  key: 'newProject/isValid',
  get: ({ get }) => {
    return (
      get(isValidBasicInformation) &&
      get(isValidAssets).isValid &&
      get(isValidAssetValidations).isValid
    );
  },
});

export const newProjectState = Object.freeze({
  current,
  modalityLabel,
  name,
  claim,
  isValidBasicInformation,
  isValidAssets,
  isValidAssetValidations,
  isValid,
});

export const useResetNewProject = (): (() => void) => {
  const resetName = useResetRecoilState(name);
  const resetClaim = useResetRecoilState(claim);

  return () => {
    resetName();
    resetClaim();
  };
};
