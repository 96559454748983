import { useState } from 'react';

import { ObjectInspector, ObjectInspectorProps } from 'react-inspector';

import UnfoldLess from '@mui/icons-material/UnfoldLess';
import UnfoldMore from '@mui/icons-material/UnfoldMore';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

interface Props extends ObjectInspectorProps {
  collapsedLevel?: number;
  expandedLevel?: number;
}

const ExpandableObjectInspector = ({
  collapsedLevel = 2,
  expandedLevel = 30,
  ...objectInspectorProps
}: Props): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = () => {
    setIsExpanded(prev => !prev);
  };

  const expandLevel = isExpanded ? expandedLevel : collapsedLevel;

  return (
    <Container>
      <ButtonWrap>
        <Tooltip
          title={isExpanded ? 'Collapse All' : 'Expand All'}
          placement="top"
        >
          <IconButton onClick={handleClick}>
            {isExpanded ? <UnfoldLess /> : <UnfoldMore />}
          </IconButton>
        </Tooltip>
      </ButtonWrap>
      <ObjectInspector
        {...objectInspectorProps}
        key={expandLevel}
        expandLevel={expandLevel}
      />
    </Container>
  );
};

export default ExpandableObjectInspector;

const Container = styled('div')`
  position: relative;
`;

const ButtonWrap = styled('div')(
  ({ theme }) => `
  position: absolute;
  right: ${theme.spacing(-1)};
  top: ${theme.spacing(-1)};
`
);
