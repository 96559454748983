import { useMemo } from 'react';

import camelCase from 'camelcase';
import groupBy from 'lodash-es/groupBy';
import uniqBy from 'lodash-es/uniqBy';
import { useRecoilState } from 'recoil';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

import FormBox from 'src/components/FormBox';
import {
  CLAIM_CONTROL_LINE,
  CLAIM_CONTROL_MULTI_FRAME_POLYGON,
  CLAIM_CONTROL_POINT,
  CLAIM_CONTROL_POLYGON,
} from 'src/configs/claims/controls';
import useCheckedParams from 'src/hooks/useCheckedParams';
import AlertPanel from 'src/pages/NewProject/AlertPanel';
import FormListItemBase from 'src/pages/NewProject/FormListItemBase';
import { newProjectState } from 'src/states/newProject';
import { Modality } from 'src/types/api/data/image';

import getControlLabel from './getControlLabel';

const Controls = (): JSX.Element => {
  const { modalityLabel } = useCheckedParams<{
    modalityLabel: Modality;
  }>(['modalityLabel']);

  const [claim, setClaim] = useRecoilState(newProjectState.claim);

  const optionalControls = useMemo(() => {
    switch (modalityLabel) {
      case Modality.CXR: {
        return [CLAIM_CONTROL_POLYGON, CLAIM_CONTROL_LINE, CLAIM_CONTROL_POINT];
      }
      case Modality.MMG:
        return [CLAIM_CONTROL_POLYGON];
      case Modality.DBT:
        return [CLAIM_CONTROL_POLYGON, CLAIM_CONTROL_MULTI_FRAME_POLYGON];
      case Modality.CCT:
      default: {
        return [];
      }
    }
  }, [modalityLabel]);

  const getHandleChange = (newControlName: string) => () => {
    setClaim(prev => {
      const controls = (() => {
        const isExisted = prev.controls.find(
          control => control.name === newControlName
        );

        if (isExisted) {
          return prev.controls.filter(
            control => control.name !== newControlName
          );
        }

        // Only optional controls can be updated.
        const newControl = optionalControls.find(
          ({ name }) => name === newControlName
        );

        if (newControl) {
          return [...prev.controls, newControl];
        }

        return prev.controls;
      })();

      return {
        ...prev,
        controls,
      };
    });
  };

  const allControls = useMemo(() => {
    const combinedControls = uniqBy(
      [...optionalControls, ...claim.controls],
      'name'
    );
    return groupBy(combinedControls, 'group');
  }, [claim.controls, optionalControls]);

  return (
    <>
      <AlertPanel isValid={true}>
        <ul>
          <li>Project usually requires at least one draw control.</li>
          <li>
            Please activate at least one draw control unless you are sure this
            project doesn't need draw controls.
          </li>
        </ul>
      </AlertPanel>

      <FormBox>
        {Object.keys(allControls).map((groupName, index) => (
          <ControlGroup key={groupName}>
            <ControlGroupTitle $gutterTop={index > 0}>
              {camelCase(groupName, { pascalCase: true })}
            </ControlGroupTitle>
            {(allControls[groupName] || []).map(control => {
              const isIncludedInClaim = !!claim.controls.find(
                c => c.name === control.name
              );

              const isOptionalControl = !!optionalControls.find(
                ({ name }) => name === control.name
              );

              return (
                <FormListItemBase key={control.name}>
                  <FormGroup>
                    <StyledFormControlLabel
                      labelPlacement="start"
                      control={
                        <Switch
                          disabled={!isOptionalControl}
                          checked={isIncludedInClaim}
                          onChange={getHandleChange(control.name)}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label={getControlLabel(control.name)}
                    />
                  </FormGroup>
                </FormListItemBase>
              );
            })}
          </ControlGroup>
        ))}
      </FormBox>
    </>
  );
};

export default Controls;

const StyledFormControlLabel = styled(FormControlLabel)(
  ({ theme }) => `
  padding: ${theme.spacing(0.5)} ${theme.spacing(1.5)} ${theme.spacing(0.5)} 0;
  justify-content: space-between;
`
);

const ControlGroup = styled('div')(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1)};
`
);

const ControlGroupTitle = styled('div')<{ $gutterTop: boolean }>(
  ({ theme, $gutterTop }) => `
  gap: ${$gutterTop ? theme.spacing(1) : 0};
`
);
