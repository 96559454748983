import { ResponsivePie } from '@nivo/pie';

interface PieChartDataProps {
  id: string;
  label: string;
  value: number;
}

interface TotalProgressPieProps {
  data: PieChartDataProps[];
  pieChartFill: {
    match: {
      id: string;
    };
    id: string;
  }[];
}

export default function AIPieChart({
  data,
  pieChartFill,
}: TotalProgressPieProps): JSX.Element {
  return (
    <ResponsivePie
      data={data}
      margin={{ top: 20, bottom: 120 }}
      innerRadius={0.6}
      padAngle={0.7}
      cornerRadius={5}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor="black"
      colors={{ scheme: 'category10' }}
      arcLabelsTextColor="#fff"
      arcLabelsSkipAngle={10}
      enableArcLinkLabels={true}
      layers={['arcs', 'legends', 'arcLabels']}
      theme={{
        tooltip: {
          container: {
            background: '#333',
          },
        },
      }}
      defs={pieChartDefs}
      fill={pieChartFill}
      legends={[
        {
          anchor: 'bottom',
          direction: 'row',
          justify: false,
          translateX: 0,
          translateY: 60,
          itemsSpacing: 0,
          itemWidth: 100,
          itemHeight: 10,
          itemTextColor: '#fff',
          itemDirection: 'top-to-bottom',
          itemOpacity: 1,
          symbolSize: 12,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#ccc',
              },
            },
          ],
        },
      ]}
    />
  );
}

const pieChartDefs = [
  {
    id: 'dots',
    type: 'patternDots',
    background: 'inherit',
    color: 'rgba(255, 255, 255, 0.2)',
    size: 2,
    padding: 1,
  },
];
