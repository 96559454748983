import { styled } from '@mui/material/styles';

import Spinner from 'src/components/Spinner';

const AllScreenSpinner = (): JSX.Element => {
  return (
    <Container>
      <Spinner size={40} />
    </Container>
  );
};

export default AllScreenSpinner;

const Container = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.25);
`;
