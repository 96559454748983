import { useMemo } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';

import { Flexbox } from 'src/components/Alignments';
import AssetTemplateForm from 'src/components/AssetTemplateForm';
import { SectionTitle } from 'src/components/Section';
import {
  AssetTemplateFormSchema,
  ClientClaimAssetCreateSchema,
} from 'src/types/client/asset';

interface Props {
  asset?: ClientClaimAssetCreateSchema;
  onClose: () => void;
}

const InspectClaimAssetDrawer = ({ asset, onClose }: Props): JSX.Element => {
  const assetTemplateForm = useMemo<AssetTemplateFormSchema | undefined>(() => {
    const mapToAssetTemplateFormSchema = (
      asset?: ClientClaimAssetCreateSchema
    ): AssetTemplateFormSchema | undefined => {
      if (!asset) {
        return undefined;
      }
      const { id, children, templateId, ...assetTemplateForm } = asset;
      return assetTemplateForm;
    };

    return mapToAssetTemplateFormSchema(asset);
  }, [asset]);

  return (
    <Drawer anchor="right" open={!!asset} onClose={onClose}>
      <Flexbox $justify="space-between" $align="flex-start">
        <SectionTitle>Inspect Claim Asset</SectionTitle>
        <IconButton aria-label="close" onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </Flexbox>

      <AssetTemplateForm
        existingAssetId={asset?.id}
        existingAsset={assetTemplateForm}
        readonly
      />
    </Drawer>
  );
};

export default InspectClaimAssetDrawer;
