import { selectorFamily } from 'recoil';

import { getDataset, getDatasets } from 'src/api/dataset';
import { Modality } from 'src/types/api/data/image';

type DatasetProps = {
  id: string;
  modality: Modality;
};

const dataset = selectorFamily({
  key: 'dataset/dataset',
  get:
    ({ id, modality }: DatasetProps) =>
    async () => {
      if (modality && id) {
        return getDataset(id, modality);
      }
    },
});

type DatasetsProps = {
  page?: number;
  modality: Modality;
};

// there is no way to get all of datasets from the first api call.
// so, this is just a magic number
const datasetPageSize = 1000;

const allDataset = selectorFamily({
  key: 'dataset/allDataset',
  get:
    ({ modality }: DatasetsProps) =>
    async () => {
      const datasetsInfo = await getDatasets(modality, 1, datasetPageSize);

      const { datasets, countTotalObjects } = datasetsInfo;

      if (countTotalObjects > datasetPageSize) {
        const pages = Array(Math.floor(countTotalObjects / datasetPageSize))
          .fill(null)
          .map((_, i) => i + 2);
        const result = await Promise.all(
          pages.map(p => getDatasets(modality, p, datasetPageSize))
        );

        return [...datasets, ...result.flatMap(d => d.datasets)];
      }

      return datasets;
    },
});

const allDatasetOptionList = selectorFamily({
  key: 'dataset/allDatasetOptionList',
  get:
    ({ modality }: DatasetsProps) =>
    async ({ get }) => {
      const datasetList = get(allDataset({ modality }));
      if (datasetList)
        return datasetList.map(ds => {
          return {
            value: ds.id || '',
            label: ds.name,
          };
        });
      else return [];
    },
});

export const datasetState = Object.freeze({
  dataset,
  allDataset,
  allDatasetOptionList,
});
